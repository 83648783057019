import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs }  from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import Button from '@mui/material/Button';
import apiPath from 'src/constants/apiPath';
import helper from 'src/constants/helper';
import { localDate } from 'src/utilities';
import { Backdrop, CircularProgress, Typography } from '@mui/material';
import useSession from 'src/hooks/useSession';

const ReportGenerator = () => {
  const session = useSession();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [downLoadReportLink, setDownloadReportLink] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [errorInReport, setError] = useState('');

  const calculateDateDifference = (start, end) => {
    const timeDifference = Math.abs(end - start);
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  };

  const handleGetReport = async () => {
    try {
      setLoading(true);
      setDownloadReportLink('');
      setError('');
      let url = `${apiPath.get_app_status_report}`;

      // TODO: Not enabling particular user data creation
      // if (name) {
      //   url = `${url}&name=${name}`;
      // }
      // if (mobile) {
      //   url = `${url}&mobileNumber=${mobile}`;
      // }

      if (startDate && endDate) {
        if (calculateDateDifference(startDate, endDate) > 7) {
          setError('Date Difference should not be greater then 7');
          return;
        }
        url = `${url}?startDate=${localDate(startDate, 'date')}&endDate=${localDate(
          endDate,
          'date'
        )}`;

        const res = await helper.get(url, session);

        if (res.data.status === 200) {
          setDownloadReportLink(res?.data?.data?.excel_link);
        } else {
          setError('Error Generating in report');
        }
      }
    } catch (e) {
      console.error(e);
      setError('Error Generating in report');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (startDate && endDate)
      if (calculateDateDifference(startDate, endDate) > 7)
        setError('Date Difference should not be greater then 7');
      else setError('');
  }, [startDate, endDate]);

  return (
    <>
      <Backdrop
        sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={isLoading}
      >
        {' '}
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: '40px' }}>
        <div style={{ display: 'flex', gap: '25px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div>
              <DesktopDatePicker
                label="Start Date"
                value={startDate}
                onChange={(newStartDate) => setStartDate(newStartDate)}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <div>
              <DesktopDatePicker
                label="End Date"
                value={endDate}
                onChange={(newEndDate) => setEndDate(newEndDate)}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </LocalizationProvider>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="primary" onClick={handleGetReport}>
            Generate Report
          </Button>
          {downLoadReportLink && <a href={downLoadReportLink}>Download Report</a>}
          {errorInReport && <Typography style={{ color: 'red' }}>{errorInReport}</Typography>}
        </div>
      </div>
    </>
  );
};

export default ReportGenerator;
