import React from 'react';
import { useState, useEffect, useRef } from 'react';
import {
  Card,
  FormControl,
  InputLabel,
  Grid,
  Stack,
  Box,
  TextField,
  Select,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';

export default function AddCategory(props) {
  let { addorEdit, recordForEdit } = props;
  let el = useRef();
  const [categoryId, setCategoryId] = useState('');
  const [name, setName] = useState('');
  const [categoryFormError, setCategoryFormError] = useState({});
  const [loadingDisable, setLoadingDisable] = useState(false);

  const handleSubmit = async (e) => {
    setLoadingDisable(true);
    e.preventDefault();
    const response = await addorEdit({ name: name }, categoryId);
    if (response !== undefined && response.status === 200) {
      setLoadingDisable(false);
      if (response.data.status === 422) {
        setCategoryFormError(response.data.errors);
      }
    }
  };

  useEffect(() => {
    if (recordForEdit) {
      setCategoryId(recordForEdit.id);
      setName(recordForEdit.name);
    }
  }, ['recordForEdit']);

  return (
    <Form onSubmit={handleSubmit} title="Add Category | Dr. B. Lal">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Controls.Input
            name="name"
            fullWidth
            label="Category Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={categoryFormError?.name}
            required
            helperTex
            t={categoryFormError?.name}
          />

          <Box className="btn-Margin">
            <LoadingButton
              size="medium"
              type="submit"
              variant="contained"
              endIcon
              disabled={loadingDisable}
            >
              {categoryId ? 'Update' : 'Add'}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
}
