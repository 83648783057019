import React from 'react'
import PropTypes from 'prop-types';
import Page from '../../components/Page';
import {
  Stack,
  Box,
  Grid,
  Breadcrumbs,
  Link,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { Link as RouterLink , useNavigate } from 'react-router-dom';
import Controls from '../../components/control/Controls';
import { useState, useEffect } from 'react';
import { Search } from '@mui/icons-material';
import useTable from 'src/components/useTable';
import { Card, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Backdrop } from '@mui/material';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

List.propTypes = {
  sx: PropTypes.object
};

export default function List({ sx }) {
  const navigate = useNavigate();
  
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const [citiesData, setCitiesData] = useState([]);
  const [membershipCardList, setMembershipCardList] = useState([]);
  const [cityId, setCityId] = useState(1);
  const [SearchKeyword, setSearchKeyword] = useState("");
  const [loading, setLoading] = useState(false);

  const getCityList = async () => {
    try {
      setLoading(true)
      const res = await helper.get(
          `${apiPath.get_all_cities}`,
      );
      
      if (res.data.status === 200) {
        setLoading(false)
        setCitiesData(res.data.data);
      }
  } catch (e) {
      console.log(e);
  }
  }

  const getMembershipCards =  async () => {
    try {
      setLoading(true)
      await helper.postb(`/getMembershipCardList?CityId=${cityId}`)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setMembershipCardList(res.data.data || []);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(`error is ${error.message}`);
      });
    } catch (error) {
      console.log(`error in finaly catch is ${error.message}`);
    }
  }


  useEffect(() => {
    getCityList();
  }, []);

  useEffect(() => {
    getMembershipCards()
  }, [cityId])



  const headeCells = [
    { id: "Id", label: "ID", disableSorting: true },
    { id: "Image", label: "Image" , disableSorting: true},
    { id: "NAME", label: "NAME", disableSorting: true },
    { id: "Discount (%)", label: "DiscountInPercentage" , disableSorting: true},
    { id: "Amount", label: "Amount", disableSorting: true },
    { id: "WebType", label: "Payment Mode", disableSorting: true },
    { id: "action", label: "Action", disableSorting: true },
  ]

  const {
    TblContainer,
    TblHeader,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(membershipCardList, headeCells, filterFn);

  const style = {
    margin: 10,
  };

  const handleBookingsView = async (item) => {

    navigate(`/membership_cards/${item.Id}`, { state: { item: item } });

  };
  
  return (
    <Page title={'Packages List | Dr. B Lal'}>
      <Backdrop
        sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography variant="h4" gutterBottom>
              Membership Card
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/">
                Dashboard
              </Link>
              <Typography color="text.primary">All Membership Card </Typography>
            </Breadcrumbs>
          </Grid>
        </Stack>

        <Toolbar>
          {/* <Controls.Input
            label="Search Packages"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (<InputAdornment position="start">
                <Search />
              </InputAdornment>)
            }}
            onChange={(e) => {
              setSearchKeyword(e.target.value)
            }}
          /> */}

          <FormControl>
            <InputLabel id="demo-simple-select-label">Select City</InputLabel>
            <Select
              style={style}
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select City"
              value={cityId}
              error={''}
              required
              helperText={''}
              onClick={(e) => {
                setCityId(e.target.dataset.city_id)
              }}
            >
              {citiesData.length > 0 && citiesData.map((e, key) => {
                return <MenuItem  key={key} value={e.CityId} data-city_id={e.CityId} data-panel_id={e.Panel_ID} >{e.CityName}</MenuItem>;
              })}

            </Select>
          </FormControl>
      
        </Toolbar>
        <TblContainer>
          <TblHeader />
          <TableBody>
            {
              recordsAfterPagingAndSorting().map(item => (
                <TableRow key={item.Id}>
                  <TableCell>{item.Id}</TableCell>
                  <TableCell> <Box
                      component="img"
                      src={`${item.Image}`}
                      sx={{ width: 100, height: 50, ...sx }}
                    /></TableCell>
                  <TableCell>{item.NAME}</TableCell>
                  <TableCell>{item.DiscountInPercentage}</TableCell>
                  <TableCell>{item.Amount}</TableCell>
                  <TableCell>{item.WebType}</TableCell>
                  <TableCell>
                      <Controls.ActionButton color="primary">
                        <RemoveRedEyeIcon
                          fontSize="small"
                          onClick={() => {
                            handleBookingsView(item);
                          }}
                        />
                      </Controls.ActionButton>
                    </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Container>
    </Page>
  )
}
