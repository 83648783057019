import React, { useState }  from 'react';
import {
  Card,
  Paper,
  Grid,
  Stack,
  Container,
  Typography,
  Breadcrumbs,
  Link,
} from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Page from '../../components/Page';
import 'date-fns';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

export default function Details(props) {
  const location = useLocation();
  const card = location.state.item;
  const { id } = useParams();

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });

  return (
    <Page title="Booking Details | Dr. B. Lal">
      <Container maxWidth={true}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography variant="h4" gutterBottom>
              Membership Cards
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/">
                Dashboard
              </Link>
              <Link underline="hover" color="inherit" component={RouterLink} to="/membership_cards">
                Card List
              </Link>
              {<Typography color="text.primary">Card Details</Typography>}
            </Breadcrumbs>
          </Grid>
        </Stack>

        {card !== null ? (
          <>
            <Card>
              <CardHeader title="Booking Details" />
              <CardContent>
                <TableContainer component={Paper}>
                  <Table className={'table'} aria-label="simple table">
                    <TableBody>
                      <TableRow key="Card ID">
                        <TableCell component="th" scope="row">
                          Card ID
                        </TableCell>
                        <TableCell align="left">{card.Id}</TableCell>
                      </TableRow>
                      <TableRow key="Card NAME">
                        <TableCell component="th" scope="row">
                          Card Name
                        </TableCell>
                        <TableCell align="left">{card.NAME}</TableCell>
                      </TableRow>
                      <TableRow key="Card Description">
                        <TableCell component="th" scope="row">
                          Card Description
                        </TableCell>
                        <TableCell align="left">{card.Description}</TableCell>
                      </TableRow>
                      <TableRow key="Card Item ID">
                        <TableCell component="th" scope="row">
                          Card Item ID
                        </TableCell>
                        <TableCell align="left">{card.ItemID}</TableCell>
                      </TableRow>
                      <TableRow key="No_of_dependant">
                        <TableCell component="th" scope="row">
                          No of dependant
                        </TableCell>
                        <TableCell align="left">{card.No_of_dependant}</TableCell>
                      </TableRow>
                      <TableRow key="CardValidity">
                        <TableCell component="th" scope="row">
                          Card Validity
                        </TableCell>
                        <TableCell align="left">{card.CardValidity}</TableCell>
                      </TableRow>
                      <TableRow key="DiscountInPercentage">
                        <TableCell component="th" scope="row">
                          Discount In (%)
                        </TableCell>
                        <TableCell align="left">{card.DiscountInPercentage} %</TableCell>
                      </TableRow>
                      <TableRow key="Amount">
                        <TableCell component="th" scope="row">
                          Card Amount
                        </TableCell>
                        <TableCell align="left">Rs. {card.Amount}</TableCell>
                      </TableRow>
                      <TableRow key="WebType">
                        <TableCell component="th" scope="row">
                          Card Web Type
                        </TableCell>
                        <TableCell align="left">{card.WebType}</TableCell>
                      </TableRow>
                      <TableRow key="Termsandconditions">
                        <TableCell component="th" scope="row">
                          Free Test
                        </TableCell>
                        {card.membershipCardInvestigationModel.length > 0 && (
                          <CardContent>
                            <div>
                              <table style={{ width: '80%' }} className="table">
                                <tbody>
                                  <tr align="left">
                                    <th>Test Id</th>
                                    <th>Test Name</th>
                                    <th>Total Uses</th>
                                  </tr>
                                  {card.membershipCardInvestigationModel.map((item) => (
                                    <tr>
                                      <td>{item.InvestigationId}</td>
                                      <td>{item.InvestigationName}</td>
                                      <td>{item.TotalCount}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </CardContent>
                        )}
                      </TableRow>

                      <TableRow key="Termsandconditions">
                        <TableCell component="th" scope="row">
                          Terms & Conditions
                        </TableCell>
                        {card.Termsandconditions.length > 0 && (
                          <CardContent>
                            <div>
                              <table style={{ width: '80%' }} className="table">
                                <tbody>
                                  {card.Termsandconditions.map((item) => (
                                    <tr>
                                      <td>{item.Description}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </CardContent>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </>
        ) : null}
        <br />

        <br />
      </Container>
    </Page>
  );
}
