import React from 'react';
import { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Stack,
  Box,
  FormHelperText,
  TextField,
  Select as MuiSelect,
  MenuItem,
  Container,
  Typography,
  Link
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import useSession from 'src/hooks/useSession';
import dayjs from 'dayjs'

export default function Add(props) {
  let { addorEdit, recordForEdit, addUser, userDetailsView, addFamilyMember = false, phoneNumber, userId } = props;
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [age, setAge] = useState(0);
  const [phone_number, setPhoneNumber] = useState('');
  const [relation, setRelation] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('Male');
  const [date, setDate] = useState(new Date());
  const session = useSession();
  const [loadingDisable, setLoadingDisable] = useState(false);
  const [userAddFormError, setUserAddFormError] = useState({});
  const [id, setId] = useState('');
  const [checkUser, setCheckUser] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingDisable(true);
    let data = {};
    if (!checkUser) {
      data = {
        phone_number: phone_number.toString(),
      }
    } else {
      data = {
        fullname: fullname,
        email: email,
        age: age.toString(),
        phone_number: phone_number.toString(),
        relation: relation,
        dob: moment(date).format('YYYY-MM-DD'),
        gender: gender,
        isAdmin: true,
        userId: userId
      };
    }
    setUserAddFormError({});
    let response = await addorEdit(data, id, checkUser);
    if (response && response.data && response.data.message) {
      if (response.data.message === "add user") {
        setCheckUser(true)
      } else {
        setCheckUser(false);
        setUserAddFormError(response.data.errors);
      }
      setLoadingDisable(false);
    }
  };

  const getRelationCollection = [{ id: 'Self', title: 'Self' }];

  const getFamilyMemberRelationCollection = [
    { id: 'Self', title: 'Self' },
    { id: 'Father', title: 'Father' },
    { id: 'Mother', title: 'Mother' },
    { id: 'Brother', title: 'Brother' },
    { id: 'Sister', title: 'Sister' },
    { id: 'Son', title: 'Son' },
    { id: 'Daughter', title: 'Daughter' },
    { id: 'Wife', title: 'Wife' },
    { id: 'Husband', title: 'Husband' },
    { id: 'Grandparent', title: 'Grandparent' },
    { id: 'Relative', title: 'Relative' },
    { id: 'Friend', title: 'Friend' },
    { id: 'Other', title: 'Others' }
  ];

  const genderItems = [
    { id: 'Male', title: 'Male' },
    { id: 'Female', title: 'Female' }
  ];

  const calculate_age = (dob) => {
    var today = new Date();
    var birthDate = new Date(dob);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    setAge(age_now);
  };

  const handleAge = (e) => {
    let date = moment().subtract(e.target.value, 'years').format("YYYY-MM-DD");
    setDate(date);
    setAge(e.target.value);
  }

  useEffect(() => {
    console.log(addFamilyMember, 'addFamilyMember');
    if (addFamilyMember) {
      console.log("hiii", phoneNumber);
      setCheckUser(true);
      setPhoneNumber(phoneNumber)
    }
  }, [addFamilyMember])

  return (
    <Page title="Add User | Dr. B. Lal">
      <Container maxWidth={true}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          {!addFamilyMember && !checkUser ?
            <>
              <Stack direction="row" spacing={2} alignItems="center">
                <Controls.Input
                  style={{ width: '49%' }}
                  name="phone_number"
                  inputProps={{ maxLength: 10 }}
                  label="Phone Number"
                  type="tel"
                  value={phone_number}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  error={userAddFormError?.phone_number}
                />
              </Stack>
              <Box>
                <LoadingButton size="medium" type="submit" variant="contained">
                  Next
                </LoadingButton>
              </Box>
            </>
            :
            <>
              <Stack direction="row" style={{ justifyContent: 'space-between' }}>
                <Controls.Input
                  style={{ width: '49%' }}
                  name="fullname"
                  fullWidth
                  label="Fullname"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                  error={userAddFormError?.fullname}
                />

                <Controls.Input
                  style={{ width: '49%' }}
                  name="email"
                  fullWidth
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={userAddFormError?.email}
                />
              </Stack>

              <Stack direction="row" spacing={2} alignItems="center">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disableFuture
                    margin="normal"
                    label="DOB"
                    openTo="day"
                    value={dayjs(date)}
                    dateFormat="dd/MM/yyyy"
                    maxDate={dayjs(new Date())}
                    onChange={(newValue) => {
                      calculate_age(moment(newValue).format('L'));
                      setDate(moment(newValue).format('L'));
                    }}
                    views={['year', 'month', 'day']}
                    // renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>

                <Controls.Input
                  style={{ width: '49%' }}
                  name="age"
                  fullWidth
                  label="Age"
                  type="tel"
                  inputProps={{ maxLength: 3 }}
                  value={age}
                  onChange={(e) => handleAge(e)}
                  error={userAddFormError?.age}
                />
              </Stack>

              <Stack direction="row" spacing={2} alignItems="center">
                {addFamilyMember ?
                  <Controls.Input
                    style={{ width: '49%' }}
                    name="phone_number"
                    inputProps={{ maxLength: 10 }}
                    label="Phone Number"
                    type="tel"
                    value={phone_number}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    error={userAddFormError?.phone_number}
                    disabled={true}
                  />
                  : ""
                }
                <FormControl style={{ width: '49%' }}>
                  <InputLabel id="relation">Relation</InputLabel>
                  <MuiSelect
                    id="relation"
                    label="relation"
                    value={relation}
                    onChange={(e) => setRelation(e.target.value)}
                    error={userAddFormError?.relation}
                  >
                    {addFamilyMember ?
                      getFamilyMemberRelationCollection.map((e, key) => {
                        return (
                          <MenuItem key={key} value={e.id}>
                            {e.title}
                          </MenuItem>
                        );
                      })
                      :
                      getRelationCollection.map((e, key) => {
                        return (
                          <MenuItem key={key} value={e.id}>
                            {e.title}
                          </MenuItem>
                        );
                      })
                    }

                  </MuiSelect>
                  <FormHelperText error>
                    {userAddFormError?.relation && userAddFormError?.relation}
                  </FormHelperText>
                </FormControl>
              </Stack>

              <Stack>
                <Controls.RadioGroup
                  name="gender"
                  label="Gender"
                  value={gender}
                  onChange={(e) => {
                    setGender(e.target.value);
                  }}
                  items={genderItems}
                />
              </Stack>

              <Box>
                <LoadingButton size="medium" type="submit" variant="contained">
                  Submit
                </LoadingButton>
              </Box>
            </>
          }
        </Form>
      </Container>
    </Page>
  );
}
