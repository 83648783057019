import React from 'react';
import { useState, useEffect } from 'react';
import {
  Card,
  FormControl,
  InputLabel,
  Grid,
  Stack,
  TextField,
  Select,
  MenuItem,
  Container,
  Typography,
  Breadcrumbs,
  Link,
  FormLabel,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Link as RouterLink } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import Page from '../../components/Page';
import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';
import moment from 'moment';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { Backdrop } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import CKEditor from 'react-ckeditor-component';
import useSession from 'src/hooks/useSession';


export default function Details(props) {
  const session = useSession();

  const [loadingDisable, setLoadingDisable] = useState(false);
  const [userType, setUserType] = useState('All');
  const [userage, setUserAge] = useState('All');
  const [userGender, setUserGender] = useState('All');
  const [cityData, setCitiesData] = useState([]);
  const [userLocation, setUserLocation] = useState('All');
  const [userData, setUserData] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [title, setTitle] = useState(null);
  const [subtitle, setSubtitle] = useState(null);
  const [formError, setFormError] = useState({});
  const [urlCheck, setUrlCheck] = useState("noUrl");
  const [cityInfo, setCityInfo] = useState(null)
  const [type, setType] = useState("");
  const [value, setValue] = useState("")
  const [addtionalListData, setAdditionalListData] = useState([])
  const [loading, setLoading] = useState(false);

  const handleOnSubmit = async (event) => {
    try {
      event.preventDefault();
      setLoadingDisable(true);
      setLoading(true);

      const response = await helper.post(
        {
          title: title,
          subtitle: subtitle,
          userType: userType,
          userAge: userage,
          userGender: userGender,
          userLocation: userLocation,
          userIds: userIds,
          urlCheck: urlCheck,
          additionalData: {
            value: type === "blog" ? value?.slug : value?.Slug,
            id: type === "blog" ? value?.id : value?.Id,
            type: type,
            cityId: cityInfo ? cityInfo[0]?.CityId : "",
            panelId: cityInfo ? cityInfo[0]?.Panel_ID : ""
          }
        },
        apiPath.send_notification,
        session
      );

      if (response.status === 200) {
        setLoading(false);
        setLoadingDisable(false);
        if (response.data.status === 200) {
          setType("")
          setCityInfo(null)
          setValue(null)
          setAdditionalListData([])
          setFormError({})
          toast.success(response.data.message);
        }
        if (response.data.status === 422) {
          setFormError(response.data.errors);
        }
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const getCityList = async () => {
    try {
      const res = await helper.get(`${apiPath.get_all_cities}`, session);
      if (res.status === 200) {
        if (res.data.status === 200) {
          setCitiesData(res.data.data);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getUsersByName = async (e) => {
    try {
      const res = await helper.get(
        `${apiPath.search_users}?search_keyword=${e.target.value}`,
        session
      );
      if (res.status === 200) {
        if (res.data.status === 200) {
          setUserData(res.data.data);
        }
      }
    } catch (error) { }
  };

  const getDataByCategory = async (e) => {
    try {
      const { value } = e.target
      if (["test", "package"].includes(type)) {
        getTestPackagesList(value)
      } else if (type === "blog") {
        getBlogsTableData(value)
      }
    } catch (error) {
      console.error(error)
    }
  }
  const getBlogsTableData = async (val) => {
    setAdditionalListData([])
    try {
      const URL = `${apiPath.get_blog_list}?title=${val}`;
      const res = await helper.get(URL, session);
      if (res?.status === 200) {
        if (res?.data?.success === true) {
          setAdditionalListData(res?.data?.data);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
  const getTestPackagesList = async (_val = "") => {
    try {
      setAdditionalListData([]);
      if (cityInfo && cityInfo[0]?.CityId && cityInfo[0]?.Panel_ID) {
        if (_val.length > 2) {
          await helper
            .postb(
              `/GetFilterTestPackage?CityId=${cityInfo[0]?.CityId}&PanelId=${cityInfo[0]?.Panel_ID}&SearchKeyword=${_val}&Type=${type}`
            )
            .then(async (res) => {
              if (res?.status === 200) {
                if (res?.data?.status_Code === 200) {
                  setAdditionalListData(res?.data?.data?.itemmodel);
                }
              }
            })
            .catch((error) => {
              console.error(`error is ${error.message}`);
            });
        }
      }
    } catch (error) {
      console.error(`error in finaly catch is ${error.message}`);
    }
  };

  const handleAdditionalInfo = (e, val = null) => {
    let close = document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    );
    if (userIds.length) {
      close = close[1]
    } else {
      close = close[0]
    }
    const { name, value: _val } = e.target

    if (name === "type") {
      setAdditionalListData([])
      close?.click()
      setType(_val);
      setValue("")
      setCityInfo(null)
      if (_val === "card") {
        getMembershipcards()
      }
    } else if (name === "city") {
      const selectedCity = cityData.filter((city) => city.CityName === _val)
      setAdditionalListData([])
      close?.click()
      setValue("")
      setCityInfo(selectedCity)
    }
    else {
      setValue(val)
    }
  }

  const getMembershipcards = async () => {
    const response = await helper.postb(`/getMembershipCardList?CityId=78`);
    if (response && response?.status === 200) {
      setAdditionalListData(response?.data?.data);
    }
  }
  const handleLabel = (option) => {
    if (type === "blog") {
      return option?.title ? option?.title : ""
    }
    return option?.NAME ? option?.NAME : ""
  }

  useEffect(() => {
    getCityList();
  }, []);

  const userAge = [];
  for (let index = 1; index <= 150; index++) {
    userAge.push(<MenuItem value={index}>{index} Yrs</MenuItem>);
  }

  return (
    <Page title="Notification | Dr. B. Lal">
      <Backdrop
        sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Container maxWidth={true}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography variant="h4" gutterBottom>
              Notification Manager
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/">
                Dashboard
              </Link>
              <Typography color="text.primary">Notification</Typography>
            </Breadcrumbs>
          </Grid>
        </Stack>

        <Card>
          <CardHeader title="Notification" />
          <CardContent>
            <Form onSubmit={handleOnSubmit} title="Edit Settings | Dr. B. Lal">
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Select User Type</InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Select User Type"
                      value={userType}
                      error={formError?.userType}
                      onChange={(e) => {
                        setUserType(e.target.value);
                        if (e.target.value !== "Selected Users")
                          setUserIds([])
                      }}
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="selected">Selected Users</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {userType != 'selected' && (
                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">Select User Age</InputLabel>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select User Age"
                        value={userage}
                        onChange={(e) => [setUserAge(e.target.value)]}
                      >
                        <MenuItem value="All">All</MenuItem>
                        {userAge}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {userType != 'selected' && (
                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">Select Gender</InputLabel>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Gender"
                        value={userGender}
                        onChange={(e) => [setUserGender(e.target.value)]}
                      >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {userType != 'selected' && (
                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">Select Location</InputLabel>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Location"
                        value={userLocation}
                        onChange={(e) => [setUserLocation(e.target.value)]}
                      >
                        <MenuItem value="All">All</MenuItem>
                        {cityData.length > 0 &&
                          cityData.map((e, key) => {
                            return <MenuItem value={e.CityId}>{e.CityName}</MenuItem>;
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {userType == 'selected' && (
                  <Grid item xs={6}>
                    <Autocomplete
                      multiple
                      disablePortal
                      id="tags-outlined"
                      onChange={(event, value) => setUserIds(value)}
                      options={userData}
                      getOptionLabel={(option) =>
                        option.fullname + '  (' + option.phone_number + ')'
                      }
                      filterSelectedOptions
                      sx={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField
                          error={formError?.userIds}
                          {...params}
                          onChange={getUsersByName}
                          label="Select User"
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography color="text.primary">Additional Information</Typography>
                  <Grid container spacing={2} display="flex" direction="row" alignItems="center" item xs={12} sm={12}>
                    <Grid item xs={12} sm={4}>
                      <FormControl>
                        <InputLabel id="category-type">Select Category</InputLabel>
                        <Select
                          id="demo-simple-select"
                          label="Select Category"
                          name="type"
                          value={type || ""}
                          onChange={handleAdditionalInfo}
                        >
                          <MenuItem value="test">Test</MenuItem>
                          <MenuItem value="package">Package</MenuItem>
                          <MenuItem value="card">Membership Card</MenuItem>
                          <MenuItem value="blog">Blog</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {["test", "package"].includes(type) && <Grid item xs={12} sm={4}>
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">Select Location</InputLabel>
                        <Select
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select Location"
                          name="city"
                          error={formError?.location}
                          value={(cityInfo && cityInfo[0]?.CityName) || ""}
                          onChange={handleAdditionalInfo}
                        >
                          {cityData?.length > 0 &&
                            cityData?.map((e, idx) => {
                              return <MenuItem key={idx} value={e?.CityName}>{e?.CityName}</MenuItem>;
                            })}
                        </Select>
                      </FormControl>
                    </Grid>}
                    {type && <Grid item xs={12} sm={4}>
                      <Autocomplete
                        onInputChange={(e, newValue, reason) => {
                          if (type === 'blog') {
                            const filterDataOnInput = addtionalListData.filter((_val) => _val?.title === newValue)
                            setValue(filterDataOnInput)
                          } else {
                            const filterDataOnInput = addtionalListData.filter((_val) => _val?.NAME === newValue)
                            setValue(filterDataOnInput)
                          }
                        }}
                        selectOnFocus={true}
                        disablePortal
                        id="tags-outlined"
                        clearOnBlur={true}
                        inputValue={value?.NAME}
                        onChange={(e, value) => {
                          handleAdditionalInfo(e, value)
                        }}
                        options={addtionalListData}
                        getOptionLabel={(option) => handleLabel(option)}
                        sx={{ width: '100%' }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              error={formError?.value}
                              {...params}
                              onChange={getDataByCategory}
                              label={`Select ${type.charAt(0).toUpperCase() + type.slice(1)}`}
                            />
                          )
                        }
                        }
                      />
                    </Grid>}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Controls.Input
                    name="title"
                    fullWidth
                    label="Notification Title"
                    value={title}
                    error={formError?.title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Url check</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={urlCheck}
                      onChange={(e) => setUrlCheck(e.target.value)}
                    >
                      <FormControlLabel value="url" control={<Radio />} label="Url" />
                      <FormControlLabel value="noUrl" control={<Radio />} label="No Url" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {urlCheck === "url" ?
                    <CKEditor
                      activeClass="p10"
                      content={subtitle}
                      error={formError?.subtitle}
                      events={{
                        afterPaste: (evt) => {
                          const newContent = evt.editor.getData();
                          setSubtitle(newContent);
                        },
                        change: (evt) => {
                          const newContent = evt.editor.getData();
                          setSubtitle(newContent);
                        }
                      }}
                    ></CKEditor>
                    : <Controls.Input
                      placeholder="Notification Message Body"
                      name="subtitle"
                      fullWidth
                      value={subtitle}
                      error={formError?.subtitle}
                      onChange={(e) => {
                        setSubtitle(e.target.value);
                      }}
                      label="Notification Message Body"
                    />
                  }
                </Grid>
              </Grid>
              <LoadingButton
                size="medium"
                type="submit"
                variant="contained"
                endIcon
                disabled={loadingDisable}
              >
                Send
              </LoadingButton>
            </Form>
          </CardContent>
        </Card>
      </Container>
    </Page >
  );
}