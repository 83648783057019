import React from 'react';
import { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Grid,
  Box,
  TextField,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';
import { getFileFromImageUrl } from 'src/utilities';

export default function Add(props) {
  let { addorEdit, recordForEdit } = props;
  const [radioState, setRadioState] = useState('image');
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState('');
  const [profilePreview, setProfilePreview] = useState('');
  const [thumbnailPreview, setThumbnailPreview] = useState('');
  const [image, setImage] = useState('');
  const [happyCustomersFormError, setHappyCustomersFormError] = useState({});
  const [loadingDisable, setLoadingDisable] = useState(false);
  const [fileTag, setFileTag] = useState(true);

  const getFile = (e,key) => {
    let ext = e.target.value.match(/\.([^\.]+)$/)[1];
    switch (ext) {
      case 'png':
      case 'jpeg':
      case 'jpg':
        const file = e.target.files[0];
        if (key === "image")
        setImage(file);
        else if (key === "profileImage")
        setProfilePreview(file)
      else if (key === "thumbnail")
      setThumbnailPreview(file)
        break;
      default:
        alert('Please upload supported attachment file format (.png, .jpeg, .jpg).');
        e.target.value = '';
    }
  };

  const handleSubmit = async (e) => {
    setLoadingDisable(true);
    e.preventDefault();
    let data = new FormData();
    data.append('image', radioState === "image" ? image : null);
    data.append('profilePreview', profilePreview);
    data.append('thumbnailPreview', thumbnailPreview);
    data.append('name', name);
    data.append('description', description);
    data.append('videoUrl', file);
    data.append('fileTag', radioState);
    if (id) {
      data.append('id', id);
    }
    let response = await addorEdit(data, id);

    if (response && response.data && response.data.errors) {
      setHappyCustomersFormError(response.data.errors);
      setLoadingDisable(false);
    }
  };

  useEffect(() => {
    if (recordForEdit) {
      setId(recordForEdit.id);
      setName(recordForEdit.name);
      setRadioState(recordForEdit.fileTag)
      setDescription(recordForEdit.description);
      if (recordForEdit.fileTag === 'image') {
      typeof recordForEdit.fileUrl === "string" ? convertUrlToFileObj(recordForEdit.fileUrl).then((res) => {
        setImage(res)
        }) : setImage(recordForEdit.fileUrl);
      } else {
        setImage('');
      }
      if (recordForEdit?.other_images){
        typeof recordForEdit.other_images.profile === "string" ? convertUrlToFileObj(recordForEdit.other_images.profile).then((res) => {
          setProfilePreview(res)
        }) : setProfilePreview(recordForEdit.other_images.profile)
        typeof recordForEdit.other_images.thumbnail === "string" ? convertUrlToFileObj(recordForEdit.other_images.thumbnail).then((res) => {
          setThumbnailPreview(res)
        }) : setThumbnailPreview(recordForEdit.other_images.thumbnail)
      }
      if (recordForEdit.fileTag === 'video') {
        setFile(recordForEdit.fileUrl);
      } else {
        setFile('');
      }
      setFileTag(recordForEdit.fileTag);
    }
  }, [recordForEdit]);
  const convertUrlToFileObj = async (imgUrl) => {
      return getFileFromImageUrl(imgUrl)
  }

  return (
    <Form onSubmit={handleSubmit} title="Add Happy Customers| Dr. B. Lal">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controls.Input
            name="title"
            fullWidth
            label="Happy Customer Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={happyCustomersFormError?.name}
          />
        </Grid>
        <Grid item xs={12}>
          <Controls.Input
            name="description"
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={happyCustomersFormError?.description}
          />
          {/* <InputLabel id="demo-simple-select-label" shrink={true}>
            Description
          </InputLabel>
          <CKEditor
            activeClass="p10"
            description={description}
            events={{
              afterPaste: (evt) => {
                const newDescription = evt.editor.getData();
                setDescription(newDescription);
              },
              change: (evt) => {
                const newDescription = evt.editor.getData();
                setDescription(newDescription);
              }
            }}
          ></CKEditor> */}
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">File Type</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="none"
              name="radio-buttons-group"
              value={radioState}
              onChange={(e) => {
                setRadioState(e.target.value);
              }}
            >
              <FormControlLabel value="video" control={<Radio />} label="Video" />
              <FormControlLabel value="image" control={<Radio />} label="Image" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {radioState === 'video' ? (
          <Grid item xs={12}>
            <Controls.Input
              name="file"
              fullWidth
              label="File"
              value={file}
              onChange={(e) => setFile(e.target.value)}
              error={happyCustomersFormError?.file}
            />
          </Grid>
        ) : radioState === 'image' ? (
          <Grid item xs={12}>
            <InputLabel id="demo-simple-select-label" shrink={true}>
              Image
            </InputLabel>
            <TextField
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              name="image"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={(e) => getFile(e, "image")}
              error={happyCustomersFormError?.image}
              helperText={happyCustomersFormError?.image}
            />
          </Grid>
        ) : ( 
          <Grid item xs={12}>
            <InputLabel id="demo-simple-select-label" shrink={true}>
              Image
            </InputLabel>
            <TextField
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              name="image"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={(e) => getFile(e, "image")}
              error={happyCustomersFormError?.image}
              helperText={happyCustomersFormError?.image}
            />
          </Grid>
        )}
            <Grid item xs={12}>
            <InputLabel id="demo-simple-select-label" shrink={true}>
              Profile image
            </InputLabel>
            <TextField
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              name="image"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={(e) => getFile(e, "profileImage")}
              error={happyCustomersFormError?.profilePreview}
              helperText={happyCustomersFormError?.profilePreview}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel id="demo-simple-select-label" shrink={true}>
              Thumbnail
            </InputLabel>
            <TextField
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              name="image"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={(e) => getFile(e, "thumbnail")}
              error={happyCustomersFormError?.thumbnailPreview}
              helperText={happyCustomersFormError?.thumbnailPreview}
            />
          </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <LoadingButton
            size="medium"
            type="submit"
            variant="contained"
            endIcon
            disabled={loadingDisable}
          >
            {id ? 'Update' : 'Add'}
          </LoadingButton>
        </Box>
      </Grid>
    </Form>
  );
}
