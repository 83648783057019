import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import Page from '../../components/Page';
import { Link as RouterLink } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Popup from 'src/components/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TableContainer from '@mui/material/TableContainer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  Card,
  Box,
  Table,
  Stack,
  Paper,
  Grid,
  Breadcrumbs,
  Link,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import { Backdrop } from '@mui/material';
import moment from 'moment';
import OurClientsAdd from './Add';
import useSession from 'src/hooks/useSession';

OurClients.propTypes = {
  sx: PropTypes.object
};

export default function OurClients({ sx }) {
  const [ourClientsData, setOurClientsData] = useState([]);
  const [addOurClients, setAddOurClients] = useState(false);
  const [editOurClients, setEditOurClients] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const session = useSession();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [viewEntry, setViewEntry] = useState({});
  const [loading, setLoading] = useState(false);

  const getOurClientsData = async () => {
    try {
      const res = await helper.get(`${apiPath.get_our_clients_list}`, session);
      if (res.status === 200) {
        if (res.data.success === true) {
          setOurClientsData(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [searchKeyword, setSearchKeyword] = useState('');
  const handleSearch = (e) => {
    let target = e.target;
    setSearchKeyword(target.value);
    setFilterFn({
      fn: (items) => {
        return target.value == ''
          ? items
          : items.filter((x) => x.client_name.toLowerCase().includes(target.value));
      }
    });
  };

  const headeCellsOurClients = [
    { id: 'client_name', label: 'Client Name', disableSorting: true },
    { id: 'client_image', label: 'Client Image', disableSorting: true },
    { id: 'date', label: 'Created At', disableSorting: true },
    { label: 'Edit/View/Delete', disableSorting: true }
  ];
  useEffect(() => {
    getOurClientsData();
  }, []);

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    ourClientsData,
    headeCellsOurClients,
    filterFn,
    searchKeyword
  );

  const handleOurClientsDelete = async (item) => {
    try {
      let res = await helper.delete(`${apiPath.delete_our_clients}/${item.id}`, session);
      if (res.status === 200) {
        getOurClientsData();
        toast.success(res.data.message);
      }
    } catch (e) {
      toast.error('Error', e);
      console.log(e);
    }
  };

  const handleClick = async (item) => {
    setOpenPopup(true);
    try {
      let res = await helper.get(`${apiPath.get_our_clients_list_byid}/${item.id}`, session);
      if (res.status === 200) {
        setViewEntry(res.data.data);
      }
    } catch (e) {
      toast.error('Error', e);
    }
  };

  const addPopup = async (item) => {
    setOpenAddPopup(true);
    setRecordForEdit(item);
  };

  const addorEdit = async (data, id) => {
    setLoading(true);
    try {
      if (recordForEdit == null) {
        const res = await helper.post(data, apiPath.create_our_clients, session);
        if (res.data.success == true) {
          getOurClientsData();
          setOpenAddPopup(false);
          toast.success(res.data.message);
        } else {
          setLoading(false);
          return res;
        }
      } else {
        const res = await helper.put(data, `${apiPath.update_our_clients}/${id}`, session);
        if (res.status === 200) {
          setLoading(false);
          if (res.data.success == true) {
            getOurClientsData();
            setOpenAddPopup(false);
            toast.success(res.data.message);
          } else {
            return res;
          }
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  };
  return (
    <>
      <Page title={'Our Clients | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Our Clients
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">All Our Clients</Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <Toolbar>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Controls.Input
                style={{ width: '70%' }}
                label="Search Our Clients"
                className={'searchInput'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
                onChange={handleSearch}
              />
              <Controls.Button
                text="Add New Our Client"
                variant="outlined"
                startIcon={<AddIcon />}
                className={'newButton'}
                onClick={() => {
                  addPopup();
                  setRecordForEdit(null);
                  setAddOurClients(true);
                }}
              />
            </Stack>
          </Toolbar>
          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.client_name}</TableCell>
                  <TableCell>
                    <Box
                      component="img"
                      src={item.client_image}
                      sx={{ width: 100, height: 50, ...sx }}
                    />
                  </TableCell>
                  <TableCell>{moment(item.created_at).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>
                    <Toolbar>
                      <Controls.ActionButton
                        color="primary"
                        onClick={() => {
                          addPopup(item);
                          setEditOurClients(true);
                        }}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </Controls.ActionButton>
                      <Controls.ActionButton color="primary" onClick={() => handleClick(item)}>
                        <VisibilityIcon fontSize="small" />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          handleOurClientsDelete(item);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </Controls.ActionButton>
                    </Toolbar>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>
      <Popup title={'Our Clients'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <Card>
          <CardHeader title="Details" />
          <CardContent>
            <TableContainer component={Paper}>
              <Table className={'table'} aria-label="simple table">
                <TableBody>
                  <TableRow key="Client Name">
                    <TableCell component="th" scope="row">
                      Client Name
                    </TableCell>
                    <TableCell align="left">{viewEntry.client_name}</TableCell>
                  </TableRow>
                  <TableRow key="Client Image">
                    <TableCell component="th" scope="row">
                      Client Image
                    </TableCell>
                    <TableCell align="left">
                      <Box
                        component="img"
                        src={viewEntry.client_image}
                        sx={{ width: 100, height: 50, ...sx }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Popup>
      <Popup title={'Our Clients'} openPopup={openAddPopup} setOpenPopup={setOpenAddPopup}>
        {addOurClients || editOurClients ? (
          <OurClientsAdd
            title="Edit Our Clients"
            recordForEdit={recordForEdit}
            addorEdit={addorEdit}
          />
        ) : (
          <p>1</p>
        )}
      </Popup>
    </>
  );
}
