import React from 'react';
import {
    TextField,
    Autocomplete,
    FormControl,
    Chip
} from '@mui/material';

const CChipInput = (props) => {
    return (
        <Autocomplete
            required={props.required}
            id={props.id}
            name={props.name}
            variant={props.variant}
            fullWidth
            onBlur={props.onBlur}
            onChange={props.onChange}
            value={props.value || []}
            multiple
            options={[]}
            defaultValue={props.defaultValue || []}
            freeSolo
            renderTags={(value, getTagProps) =>
                (value || []).map((option, index) => {
                    return (
                        <FormControl key={index} className={'rootComponent'}>
                            <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                            />
                        </FormControl>
                    );
                })
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    autoFocus={props.autoFocus || false}
                    variant={props.variant}
                    label={props.label}
                    error={props.error}
                    // helperText={props.helperText}
                />
            )}
        />
    );
};

export default CChipInput;