import {
  Breadcrumbs,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import Page from '../../components/Page';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';

import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { Backdrop } from '@mui/material';
import { localDate } from 'src/utilities';
import useSession from 'src/hooks/useSession';
import dayjs from 'dayjs'

export default function Logs() {
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const session = useSession();
  const [logs, setLogsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [platformType, setPlatformType] = useState('all');
  const [edit, setEditableCells] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const [apiName, setApiName] = useState('');
  const [date, setDate] = useState(localDate(new Date(), 'date'));

  const headCells = [
    { id: 'api_name', label: 'Api Name', disableSorting: true },
    { id: 'status_code', label: 'Status Code', disableSorting: true },
    { id: 'platform_type', label: 'Platform', disableSorting: true },
    { id: 'response', label: 'Response', disableSorting: true },
    { id: 'request', label: 'Request', disableSorting: true },
    { id: 'created_at', label: 'CreatedAt', disableSorting: true }
  ];
  const pageLimit = [10, 25, 50, 100, 250];
  const {
    TblContainer,
    TblHeader,
    TblPagination,
    recordsAfterPagingAndSorting,
    page,
    rowsPerPage
  } = useTable(logs, headCells, filterFn, apiName, pageLimit);

  const handleSearch = (value) => {
    setApiName(value);
  };

  const handleCellBlur = () => {
    setEditableCells(null);
  };
  const handleDoubleClick = (row, col) => {
    setEditableCells({ row, col });
  };
  const handleDateChange = (newValue) => {
    if (newValue !== null) {
      setDate(localDate(newValue, 'date'));
    }
  };

  const handleTimeChange = (time) => {
    const formattedTime = localDate(time, 'time');
    if (!formattedTime?.includes("NaN")) setSelectedTime(time);
  };

  const getLogsData = async () => {
    try {
      setLoading(true);
      let url = `${apiPath.get_logs}`;
      if (rowsPerPage) {
        url = `${url}?size=${rowsPerPage}`;
      }
      if (apiName) {
        url = `${url}&apiName=${apiName}`;
      }
      if (date) {
        url = `${url}&date=${date}`;
      }
      if (selectedTime) {
        const formattedTime = localDate(selectedTime, 'time');
        url = `${url}&time=${formattedTime}`;
      }
      if (platformType) {
        url = `${url}&platform=${platformType}`;
      }
      if (page) {
        url = `${url}&page=${page}`;
      }

      const res = await helper.get(url, session);
      if (res.data.status === 200) {
        setLogsData(res?.data?.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const clearAllFilters = () => {
    setDate(null);
    setSelectedTime(null);
    setApiName('');
    setPlatformType('all');
  };

  useEffect(() => {
    getLogsData();
  }, [apiName, date, page, rowsPerPage, platformType, selectedTime]);

  const handleTextFieldClick = (event) => {
    event.target.select();
  };

  const tableCellComponent = (id, columnValue, columnKey, colWidth) => {
    return (
      <TableCell
        onDoubleClick={() => handleDoubleClick(id, columnKey)}
        className="textEllipses"
        style={{
          maxWidth: `${colWidth}px`
        }}
      >
        {edit?.row === id && edit?.col === columnKey && columnValue ? (
          <TextField
            autoFocus
            defaultValue={columnValue}
            InputProps={{
              readOnly: true,
              onFocus: handleTextFieldClick,
              sx: { padding: 0 }
            }}
            onBlur={handleCellBlur}
            sx={{
              '& .MuiInputBase-root .MuiOutlinedInput-input': {
                padding: '5px !important',
                width: `${colWidth}px !important`
              }
            }}
          />
        ) : (
          columnValue ?? '-'
        )}
      </TableCell>
    );
  };

  return (
    <>
      <Page title={'Logs Data | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Logs{' '}
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">logs </Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <Toolbar>
            <Stack
              direction="row"
              style={{ columnGap: '10px', justifyContent: 'space-between', flex: 0.8 }}
            >
              <Controls.Input
                style={{ width: '20%' }}
                name="api_name"
                type="string"
                value={apiName}
                label="Search Api Name"
                className={'searchInput'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <FormControl sx={{ width: 200, marginTop: '0%' }}>
                <InputLabel id="platform-select-label">Platform Type</InputLabel>
                <Select
                  fullWidth
                  labelId="platform-select-label"
                  id="platform-select"
                  label="Platform Type"
                  value={platformType}
                  onChange={(e) => {
                    setPlatformType(e.target.value);
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="Website">Website</MenuItem>
                  <MenuItem value="App">App</MenuItem>
                  <MenuItem value="Salesforce">Salesforce</MenuItem>
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="SELECT DATE"
                  maxDate={dayjs(new Date())}
                  value={dayjs(date)}
                  onChange={(newValue) => {
                    handleDateChange(newValue);
                  }}
                  // renderInput={(params) => <TextField {...params} />}
                />
                <DesktopTimePicker
                  label="Select Time"
                  value={selectedTime}
                  onChange={(newTime) => handleTimeChange(newTime)}
                  renderInput={(params) => <TextField {...params} />}
                  ampm={false}
                />
              </LocalizationProvider>
              <Controls.Button
                text="Clear Filters"
                variant="outlined"
                className={'newButton'}
                onClick={clearAllFilters}
              />
            </Stack>
          </Toolbar>

          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting()?.map((item, idx) => (
                <TableRow key={item?.id}>
                  {tableCellComponent(idx, item.api_name, 'api_name', 150)}
                  {tableCellComponent(idx, item.status_code, 'status_code', 100)}
                  {tableCellComponent(idx, item.platform_type, 'platform_type', 100)}
                  {tableCellComponent(idx, item.response, 'response', 300)}
                  {tableCellComponent(idx, item.request, 'request', 300)}
                  {tableCellComponent(idx, item.created_at, 'created_at', 150)}
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>
    </>
  );
}
