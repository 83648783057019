import React from 'react';
import { useState, useEffect } from 'react';
import {
  Card,
  FormControl,
  Grid,
  Stack,
  TextField,
  Container,
  Typography,
  Breadcrumbs,
  Link
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import Page from '../../components/Page';
import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import 'date-fns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { ToastContainer, toast } from 'react-toastify';
import useSession from 'src/hooks/useSession';


export default function Details(props) {
  const location = useLocation();
  const [loadingDisable, setLoadingDisable] = useState(false);
  const [cancellationAmount, setCancellationAmount] = useState(null);
  const [formError, setFormError] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [timeInterval, setTimeInterval] = useState(30);
  const [timeIntervalBeforeStart, setTimeIntervalBeforeStart] = useState(60);
  const [pickupCharge, setPickupCharge] = useState(70);

  const session = useSession();

  const handleOnSubmit = async (event) => {
    try {
      event.preventDefault();

      let updateObjects = {
        updateObject: {
          cancellation_percent: cancellationAmount,
          start_time: moment(startDate).format('HH:mm'),
          end_time: moment(endDate).format('HH:mm'),
          time_interval: timeInterval,
          interval_before_start_time: timeIntervalBeforeStart,
          pickup_charge: pickupCharge
        }
      };

      const response = await helper.post(updateObjects, apiPath.update_app_settings, session);

      if (response.status === 200) {
        toast.success('App Setting has been updated successful.');
        getAppSetting();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getAppSetting = async () => {
    try {
      const response = await helper.get(apiPath.get_app_settings, session);
      if (response.status === 200) {
        const data = response.data.data;
        setCancellationAmount(data.cancellation_percent);
        const starttime = moment(data.start_time, 'HH:mm').utc();
        const event1 = new Date();
        event1.setTime(starttime);
        setStartDate(event1);
        const endtime = moment(data.end_time, 'HH:mm').utc();
        const event2 = new Date();
        event2.setTime(endtime);
        setEndDate(event2);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAppSetting();
  }, []);

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9)
  ];

  const handleCancellationNumber = (e) => {
    let value = e.target.value;
    var pattern = new RegExp(/^[0-9\b]+$/);
    setCancellationAmount(value);
    // if (value.length && !pattern.test(value)) {
    //   setFormError({ cancellation_amount: 'Please enter only number.' });
    // } else if (value.length == 3 && parseFloat(value) > 100) {
    //   setFormError({ cancellation_amount: 'Please enter valid cancellation amount.' });
    //   setCancellationAmount(value);
    // } else if (value.length > 3) {
    //   if (parseFloat(value) == 100) {
    //     setCancellationAmount(value);
    //   }
    // } else {
    //   setFormError({});
    //   setCancellationAmount(value);
    // }
  };

  return (
    <Page title="Settings | Dr. B. Lal">
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Container maxWidth={true}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography variant="h4" gutterBottom>
              Settings
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/">
                Dashboard
              </Link>
              <Typography color="text.primary">Settings</Typography>
            </Breadcrumbs>
          </Grid>
        </Stack>

        <Card>
          <CardHeader title="App Settings" />
          <CardContent>
            <Form onSubmit={handleOnSubmit} title="Edit Settings | Dr. B. Lal">
              <Grid container spacing={3}>
                <Grid item xs={6} md={6}>
                  <Controls.Input
                    type="number"
                    name="name"
                    fullWidth
                    label="Booking Cancellation Fee (%)"
                    value={cancellationAmount}
                    onChange={handleCancellationNumber}
                    error={formError?.cancellation_amount}
                    required
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <FormControl>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Start Time"
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={3} md={3}>
                  <FormControl>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="End Time"
                        value={endDate}
                        onChange={(newValue) => {
                          setEndDate(newValue);
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Controls.Input
                    type="number"
                    name="time_interval"
                    fullWidth
                    label="Time Interval"
                    value={timeInterval}
                    onChange={(e) => {
                      setTimeInterval(e.target.value);
                    }}
                    error={formError?.timeInterval}
                    required
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <Controls.Input
                    type="number"
                    name="interval_before_start_time"
                    fullWidth
                    label="Time Interval Before Start Time"
                    value={timeIntervalBeforeStart}
                    onChange={(e) => {
                      setTimeIntervalBeforeStart(e.target.value);
                    }}
                    error={formError?.timeIntervalBeforeStart}
                    required
                  />
                </Grid>
                {/* <Grid item xs={6} md={6}>
                  <Controls.Input
                    type="number"
                    name="pickup_charge"
                    fullWidth
                    label="Home Collection Charge"
                    value={pickupCharge}
                    onChange={(e) => {
                      setPickupCharge(e.target.value);
                    }}
                    error={formError?.pickupCharge}
                    required
                  />
                </Grid> */}
              </Grid>
              <LoadingButton
                size="medium"
                type="submit"
                variant="contained"
                endIcon
                disabled={loadingDisable}
              >
                SAVE
              </LoadingButton>
            </Form>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
