import React from 'react';
import PropTypes from 'prop-types';
import Page from '../../components/Page';
import {
  Stack,
  Box,
  Paper,
  Grid,
  Breadcrumbs,
  Link,
  Button,
  TableRow,
  CardContent,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Controls from '../../components/control/Controls';
import { useState, useEffect } from 'react';
import { Search } from '@mui/icons-material';
import useTable from 'src/components/useTable';
import { Card, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Backdrop } from '@mui/material';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import Popup from 'src/components/Popup';
import { ToastContainer, toast } from 'react-toastify';
import useSession from 'src/hooks/useSession';

List.propTypes = {
  sx: PropTypes.object
};

export default function List({ sx }) {
  const session = useSession();
  const navigate = useNavigate();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [prescriptionInfo, setPrescriptionInfo] = useState(null);
  const [prescriptions, setPrescriptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getUserPrescriptions = async () => {
    try {
      setLoading(true);
      const res = await helper.get(`${apiPath.user_prescriptions}?size=1000`, session);

      if (res.data.status === 200) {
        setLoading(false);
        setPrescriptions(res.data.data.docs);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDelete = async (item) => {
    try {
      const res = await helper.post(
        { table: 'user_prescriptions' },
        `${apiPath.delete_row}/${item.id}`,
        session
      );
      if (res.status === 200 && res.data.status === 200) {
        getUserPrescriptions();
        toast(res.data.message);
      }
    } catch (e) {
      toast('Error', e);
    }
  };

  useEffect(() => {
    getUserPrescriptions();
  }, []);

  const headeCells = [
    { id: 'id', label: 'ID', disableSorting: true },
    { id: 'user.photo', label: 'Image', disableSorting: true },
    { id: 'user.fullname', label: 'Name', disableSorting: true },
    { id: 'user.phone_number', label: 'Mobile', disableSorting: true },
    { id: 'collection_type', label: 'Collection Type' },
    { id: 'address', label: 'Address', disableSorting: true },
    { id: 'action', label: 'Action', disableSorting: true }
  ];

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    prescriptions,
    headeCells,
    filterFn
  );

  const style = {
    margin: 10
  };

  const prescriptionList = (prescriptions, url) => {
    let prescriptionsArr = prescriptions.split(',');
    var cards = [];
    for (let index = 0; index < prescriptionsArr.length; index++) {
      var temp = (
        <p>
          <a target="_blank" href={url + '/' + prescriptionsArr[index]}>
            Prescription {index + 1}
          </a>
          <br></br>
        </p>
      );
      cards.push(temp);
    }
    return cards;
  };

  return (
    <>
      <Page title={'Packages List | Dr. B Lal'}>
        <ToastContainer bg="success" />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                User Prescriptions
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">Prescriptions </Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <Toolbar>
            {/* <Controls.Input
            label="Search Packages"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (<InputAdornment position="start">
                <Search />
              </InputAdornment>)
            }}
            onChange={(e) => {
              setSearchKeyword(e.target.value)
            }}
          /> */}

            {/* <FormControl>
            <InputLabel id="demo-simple-select-label">Select City</InputLabel>
            <Select
              style={style}
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select City"
              value={cityId}
              error={''}
              required
              helperText={''}
              onClick={(e) => {
                setCityId(e.target.dataset.city_id)
              }}
            >
              {citiesData.length > 0 && citiesData.map((e, key) => {
                return <MenuItem  key={key} value={e.CityId} data-city_id={e.CityId} data-panel_id={e.Panel_ID} >{e.CityName}</MenuItem>;
              })}

            </Select>
          </FormControl> */}
          </Toolbar>
          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>
                    <Box
                      component="img"
                      src={`${item.user.photo}`}
                      sx={{ width: 50, height: 50, ...sx }}
                    />
                  </TableCell>
                  <TableCell>{item.user.fullname}</TableCell>
                  <TableCell>{item.user.phone_number}</TableCell>
                  <TableCell>{item.collection_type}</TableCell>
                  <TableCell>{item.address}</TableCell>
                  <TableCell>
                    <Controls.ActionButton
                      color="primary"
                      onClick={() => {
                        setPrescriptionInfo(item);
                        setOpenPopup(true);
                      }}
                    >
                      <VisibilityIcon fontSize="small" />
                    </Controls.ActionButton>
                    <Controls.ActionButton color="secondary" onClick={() => handleDelete(item)}>
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>
      <Popup title={'Prescreption Details'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <CardContent>
          {prescriptionInfo !== null ? (
            <TableContainer component={Paper}>
              <Table className={'table'} aria-label="simple table">
                <TableBody>
                  <TableRow key="FullNmae">
                    <TableCell component="th" scope="row">
                      <b>User UHID</b>
                    </TableCell>
                    <TableCell align="left">{prescriptionInfo.user.uhid}</TableCell>
                  </TableRow>
                  <TableRow key="FullNmae">
                    <TableCell component="th" scope="row">
                      <b>User Name</b>
                    </TableCell>
                    <TableCell align="left">{prescriptionInfo.user.fullname}</TableCell>
                  </TableRow>
                  <TableRow key="FullNmae">
                    <TableCell component="th" scope="row">
                      <b>User Mobile</b>
                    </TableCell>
                    <TableCell align="left">{prescriptionInfo.user.phone_number}</TableCell>
                  </TableRow>
                  <TableRow key="FullNmae">
                    <TableCell component="th" scope="row">
                      <b>Collection Type</b>
                    </TableCell>
                    <TableCell align="left">{prescriptionInfo.collection_type}</TableCell>
                  </TableRow>
                  <TableRow key="FullNmae">
                    <TableCell component="th" scope="row">
                      <b>Instructions</b>
                    </TableCell>
                    <TableCell align="left">{prescriptionInfo.instructions}</TableCell>
                  </TableRow>
                  <TableRow key="FullNmae">
                    <TableCell component="th" scope="row">
                      <b>Address</b>
                    </TableCell>
                    <TableCell align="left">{prescriptionInfo.address}</TableCell>
                  </TableRow>
                  <TableRow key="FullNmae">
                    <TableCell component="th" scope="row">
                      <b>Prescription Doc</b>
                    </TableCell>
                    <TableCell align="left">
                      {prescriptionList(prescriptionInfo.documents, prescriptionInfo.user.email)}
                    </TableCell>
                  </TableRow>
                </TableBody>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(`/create_booking/${prescriptionInfo.id}/${prescriptionInfo.user_id}`);
                  }}
                >
                  Add Booking
                </Button>
              </Table>
            </TableContainer>
          ) : null}
        </CardContent>
      </Popup>
    </>
  );
}
