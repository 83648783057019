import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
  Grid,
  Stack,
  Box
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';
import CChipInput from 'src/components/cChipInput';
import useSession from 'src/hooks/useSession';

export default function Edit(props) {
  const navigate = useNavigate();
  let { recordForEdit } = props;
  const session = useSession();
  const [id, setId] = useState('');
  const [slug, setSlug] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [citiesFormError, setCitiesFormError] = useState({});
  const [loadingDisable, setLoadingDisable] = useState(false);
  const [keywords, setKeywords] = useState([]);

  useEffect(() => {
    if (recordForEdit) {
      setId(recordForEdit.CityId);
      setMetaTitle(recordForEdit.metaTitle);
      setSlug(recordForEdit.slug);
      setMetaDescription(recordForEdit.metaDescription)
      setKeywords(recordForEdit.keywords ? JSON.parse(recordForEdit.keywords) : []);
    }
  }, [recordForEdit]);

  const handleSubmit = async (e) => {
    setLoadingDisable(true);
    e.preventDefault();
    let data = {
      slug: slug,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
      keywords: keywords
    };
    if (id != undefined) {
      let response = await helper.put(data, `/${apiPath.update_cities}?id=${id}`, session);
      if (response.status === 200 && response.data) {
        setLoadingDisable(false);
        if (response.data.status === 200) {
          navigate('/cities');
        }
        if (response.data.status === 422) {
          setCitiesFormError(response.data.errors);
        }
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit} title="Edit Cities | Dr. B. Lal">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Stack>
            <Controls.Input
              name="slug"
              fullWidth
              label="Meta Url"
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
              error={citiesFormError?.slug}
            />

            <Controls.Input
              name="metaTitle"
              fullWidth
              label="Meta Title"
              value={metaTitle}
              onChange={(e) => setMetaTitle(e.target.value)}
              error={citiesFormError?.metaTitle}
              helperText={citiesFormError?.metaTitle}
            />

            <Controls.Input
              name="metaDescription"
              fullWidth
              label="Meta Description"
              value={metaDescription}
              onChange={(e) => setMetaDescription(e.target.value)}
              error={citiesFormError?.metaDescription}
              helperText={citiesFormError?.metaDescription}
            />

            <CChipInput
              required={true}
              id="keywords"
              name="keywords"
              label="Keywords"
              fullWidth
              onChange={(e, value) => {
                setKeywords(value);
              }}
              value={keywords}
              error={citiesFormError?.keywords}
              helperText={citiesFormError?.keywords}
            />

            <Box>
              <LoadingButton
                size="medium"
                type="submit"
                variant="contained"
                endIcon
                disabled={loadingDisable}
              >
                Submit
              </LoadingButton>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Form>
  );
}
