import { useState, useEffect } from 'react';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import Page from '../../components/Page';
import { Link as RouterLink } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import AddIcon from '@mui/icons-material/Add';
import Popup from 'src/components/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import StarRatings from 'react-star-ratings';

import {
  Stack,
  Grid,
  Breadcrumbs,
  Link,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  CircularProgress
} from '@mui/material';

import { Backdrop } from '@mui/material';
import useSession from 'src/hooks/useSession';

export default function List() {
  let navigate = useNavigate();
  // const [filterFn, setFilterFn] = useState({ fn: (items) => {return items; } });
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const session = useSession();
  const [ratingsList, setRatingsList] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [addUser, setAddUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [userDetailsView, setUserDetailsView] = useState(false);

  let [loading, setLoading] = useState(false);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        return target.value == ''
          ? items
          : items.filter((x) => x.fullname.toLowerCase().includes(target.value));
      }
    });
  };

  const getUserRatingsList = async () => {
    try {
      const res = await helper.get(`${apiPath.user_ratings_list}?size=1000`, session);
      if (res.status === 200) {
        if (res.data.success === true) {
          setRatingsList(res.data.data.rows);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const headeCells = [
    { id: 'LisBookId', label: 'Booking ID', disableSorting: true },
    { id: 'username', label: 'Username', disableSorting: true },
    { id: 'phoneNumber', label: 'Contact', disableSorting: true },
    { id: 'ratings', label: 'Ratings', disableSorting: true }
  ];

  useEffect(() => {
    getUserRatingsList();
  }, []);

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    ratingsList,
    headeCells,
    filterFn
  );

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const addorEdit = async (data, id) => {
    setLoading(true);
    try {
      if (recordForEdit == null) {
        const response = await helper.post(data, apiPath.create_user, session);
        if (response.data.success == true) {
          getUsersData();
          setOpenPopup(false);
          toast(response.data.message);
        } else {
          setLoading(false);
          return response;
        }
      } else {
        console.log('in else part');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('Error', error);
    }
  };

  const handleUserDetailsView = async (item) => {
    setUserDetailsView(item);
    setRecordForEdit(false);
    setAddUser(false);
    navigate('/users/details', { state: { id: item.id } });
  };

  return (
    <>
      <Page title={'User Ratings List | Dr. B Lal'}>
        <ToastContainer bg="success" />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Users Ratings
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">User Ratings List</Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.booking.LisBookId}</TableCell>
                  <TableCell>{item.user.fullname}</TableCell>
                  <TableCell>{item.user.phone_number}</TableCell>
                  <TableCell>
                    <StarRatings
                      starDimension="25px"
                      rating={item.ratings_number.number}
                      starRatedColor="green"
                      numberOfStars={5}
                      name="rating"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>
      {/* <Popup title={'Users Management'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <UserAdd title="Edit Blog" 
        addorEdit={addorEdit}
        recordForEdit={recordForEdit} 
        addUser={addUser}
        userDetailsView={userDetailsView}/>
      </Popup> */}
    </>
  );
}
