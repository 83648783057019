import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import Page from '../../components/Page';
import { Link as RouterLink } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import { Search } from '@mui/icons-material';
import Popup from 'src/components/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CardHeader from '@mui/material/CardHeader';
import TableContainer from '@mui/material/TableContainer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Card,
  CardContent,
  Table,
  Stack,
  Paper,
  Grid,
  Breadcrumbs,
  Link,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import { Backdrop } from '@mui/material';
import moment from 'moment';
import useSession from 'src/hooks/useSession';

List.propTypes = {
  sx: PropTypes.object
};

export default function List({ sx }) {
  const [newsLetterData, setNewsLetterData] = useState([]);
  const session = useSession();
  const [viewEntry, setViewEntry] = useState({});
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const getNewsLetterData = async () => {
    try {
      const res = await helper.get(`${apiPath.get_news_letter_list}`, session);
      if (res.status === 200) {
        if (res.data.success === true) {
          setNewsLetterData(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [searchKeyword, setSearchKeyword] = useState('');
  const handleSearch = (e) => {
    let target = e.target;
    setSearchKeyword(target.value);
    setFilterFn({
      fn: (items) => {
        return target.value == ''
          ? items
          : items.filter((x) => x.email.toLowerCase().includes(target.value));
      }
    });
  };

  const headeCellsNewsLetter = [
    { id: 'email', label: 'Email', disableSorting: true },
    { id: 'date', label: 'Created At', disableSorting: true },
    { label: 'View/Delete', disableSorting: true }
  ];

  useEffect(() => {
    getNewsLetterData();
  }, []);

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    newsLetterData,
    headeCellsNewsLetter,
    filterFn,
    searchKeyword
  );

  const openInPopup = (item) => {
    setOpenPopup(true);
  };

  const handleBlogView = async (item) => {
    setOpenPopup(true);
  };

  const handleNewsLetterDelete = async (item) => {
    try {
      let res = await helper.delete(`${apiPath.delete_news_letter}/${item.id}`, session);
      if (res.status === 200) {
        getNewsLetterData();
        toast.success(res.data.message);
      }
    } catch (e) {
      toast.error('Error', e);
    }
  };

  const handleClick = async (item) => {
    setOpenPopup(true);
    try {
      let res = await helper.get(`${apiPath.get_news_letter_list_byid}/${item.id}`, session);
      if (res.status === 200) {
        setViewEntry(res.data.data);
      }
    } catch (e) {
      toast.error('Error', e);
    }
  };

  return (
    <>
      <Page title={'News Letter | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                News Letter
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">All News Letter </Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <Toolbar>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Controls.Input
                style={{ width: '70%' }}
                label="Search News Letter"
                className={'searchInput'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
                onChange={handleSearch}
              />
            </Stack>
          </Toolbar>

          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{moment(item.created_at).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>
                    <Toolbar>
                      <Controls.ActionButton color="primary" onClick={() => handleClick(item)}>
                        <VisibilityIcon fontSize="small" />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          handleNewsLetterDelete(item);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </Controls.ActionButton>
                    </Toolbar>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>
      <Popup title={'News Letter'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <Card>
          <CardHeader title="Details" />
          <CardContent>
            <TableContainer component={Paper}>
              <Table className={'table'} aria-label="simple table">
                <TableBody>
                  <TableRow key="Email">
                    <TableCell component="th" scope="row">
                      Email
                    </TableCell>
                    <TableCell align="left">{viewEntry.email}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Popup>
    </>
  );
}
