import React from 'react';
import PropTypes from 'prop-types';
import Page from '../../components/Page';
import {
  Stack,
  Box,
  Paper,
  Grid,
  Breadcrumbs,
  Link,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import Popup from 'src/components/Popup';
import { Link as RouterLink } from 'react-router-dom';
import Controls from '../../components/control/Controls';
import { useState, useEffect } from 'react';
import { Search } from '@mui/icons-material';
import useTable from 'src/components/useTable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Backdrop } from '@mui/material';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import Switch from '@mui/material/Switch';
import useSession from 'src/hooks/useSession';

List.propTypes = {
  sx: PropTypes.object
};

export default function List({ sx }) {
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const session = useSession();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);

const getRecords = async() => {
  try {
    setLoading(true)
    const res = await helper.get(
      apiPath.need_helps,
      session
    );
    if (res.status === 200) {
      setLoading(false)
      if(res.data.success === true){
        setRecords(res.data.data);
      }
    }
  } catch (error) {
    setLoading(false)
    console.log(error);
  }
}


  useEffect(() => {
    getRecords()
  }, []);



  const headeCells = [
    { id: 'Id', label: 'ID',disableSorting: true },
    { id: 'fullname', label: 'Fullname' ,disableSorting: true },
    { id: 'email', label: 'Email',disableSorting: true },
    { id: 'phone_number', label: 'Phone Number',disableSorting: true },
    { id: 'city', label: 'City',disableSorting: true },
    { id: 'address', label: 'Address',disableSorting: true },
    { id: 'comment', label: 'Comment',disableSorting: true },
    { id: 'attachment', label: 'Attachment',disableSorting: true },
    { id: 'status', label: 'Resolved',disableSorting: true },
  ];

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    records,
    headeCells,
    filterFn
  );

  const style = {
    margin: 10
  };
  const [openPopup, setOpenPopup] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const handleBlogView = async (item) => {
    setPreviewImage(item.attachment);
    setOpenPopup(true);
  };

  const handleStatusChange = async (item) => {
    try {
      setLoading(true);
      var data = {
        table: 'enquiries',
        status: !item.status
      };
      const response = await helper.post(data, `${apiPath.update_status}/${item.id}`, session);
      if (response.status === 200) {
        setLoading(false);
        if (response.data.success === true) {
          getRecords();
          toast.success(response.data.message);
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <>
    <Page title={'Need Helps | Dr. B Lal'}>
    <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      <Backdrop
        sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography variant="h4" gutterBottom>
             Need Help
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/">
                Dashboard
              </Link>
              <Typography color="text.primary">All Inquiry </Typography>
            </Breadcrumbs>
          </Grid>
        </Stack>

        <Toolbar>
          
          {/* <Controls.Input
            label="Search Packages"
            value={SearchKeyword}
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            onChange={(e) => {
              setSearchKeyword(e.target.value);
            }}
          /> */}
               
        </Toolbar>
        <TblContainer>
          <TblHeader />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.fullname}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.phone_number}</TableCell>
                <TableCell>{item.city}</TableCell>
                <TableCell>{item.address}</TableCell>
                <TableCell>{item.comment}</TableCell>
                <TableCell> <Box
                      component="img"
                      onClick={() => {
                        handleBlogView(item);
                      }}
                      src={`${item.attachment}`}
                      sx={{ width: 100, height: 50, ...sx }}
                    /></TableCell>
                      <TableCell>
                    {' '}
                    <Switch
                      checked={item.status}
                      onChange={() => {
                        handleStatusChange(item);
                      }}
                    />
                  </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Container>
    </Page>
          <Popup title={'Attachment'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
          <Paper variant="outlined">
              <img src={previewImage} alt="login" />
            </Paper>
        </Popup>
        </>
  );
}
