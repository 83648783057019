import React from 'react';
import { useState, useEffect, useRef, useMemo } from 'react';
import {
  Switch,
  Card,
  FormControl,
  InputLabel,
  Grid,
  Stack,
  Box,
  TextField,
  Select as MuiSelect,
  MenuItem,
  Container,
  Typography,
  Breadcrumbs,
  Link,
  FormLabel,
  FormControlLabel,
  Button,
  FormGroup,
  Checkbox,
  Select
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';

import { LoadingButton } from '@mui/lab';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import Page from '../../components/Page';
import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';
import { AdapterDayjs }  from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'date-fns';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import DatePicker from 'react-multi-date-picker';
import useSession from 'src/hooks/useSession';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiAutocomplete-inputRoot': {
    maxHeight: '120px',
    overflowY: 'scroll'
    // '& fieldset': {
    //   borderWidth: `1px !important`,
    //   borderColor: `${theme.palette.grey[500_32]} !important`
    // }
  }
}));


export default function Add(props) {
  let { addorEdit, recordForEdit } = props;
  const [couponName, setCouponName] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [attempts, setAttempts] = useState('');
  const [visibility, setVisibility] = useState({
    checkedA: false
  });
  const [newUser, setNewUser] = useState(false);
  const [noBooking, setNoBooking] = useState(false);
  const [locationType, setLocationType] = useState(null);
  const [locationVar, setLocation] = useState([]);
  const [preview, setPreview] = useState('');
  const [image, setImage] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [dob, setDob] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const session = useSession();
  const [loadingDisable, setLoadingDisable] = useState(false);
  const [couponAddError, setCouponAddError] = useState({});
  const [locationList, setLocationList] = useState([]);
  const [testOrPackageList, setTestOrPackageList] = useState([]);
  const [applicationType, setApplicationType] = useState("both")
  const [gender, setGender] = useState("both")
  const [collectionType, setCollectionType] = useState("both")
  const [description, setDescription] = useState("");
  const [minimumOrderValue, setmMinimumOrderValue] = useState("");
  const [maxDiscountValue,  setMaxDiscountValue] = useState("");
  const [couponType,  setCouponType] = useState("static");
  const [bulkId,  setBulkId] = useState("");
  const [bulkCouponCode,  setBulkCouponCode] = useState("newCode");
  const [bulkCouponArr,  setBulkCouponArr] = useState([]);
  const [uhidPhoneArr,  setUhidPhoneArr] = useState([]);
  const [uhidPhoneText,  setUhidPhoneText] = useState("");
  const [uhidPhoneFilterEnabled,  setUhidPhoneFilterEnabled] = useState(false);
  const [uhidPhoneVal,  setUhidPhoneVal] = useState('uhid');
  const [excel, setExcel] = useState(null);
  const [allType, setAllType] = useState({
    test: false,
    package: false
  });
  const [couponCount, setCouponCount] = useState(0)
  const [testDiscountArr, setTestDiscountArr] = useState([{
    discountType: "Percent",
    discount: "",
    testDetail: ""
  }])
  const [id, setId] = useState('');
  const [dates, setDates] = useState([]);
  const [isBulkCouponEdited, setIsBulkCouponEdited] = useState(false);
    const memoizedBulkCouponList = useMemo(() => {
      console.log(bulkCouponArr,"************hnnn")
    return  <StyledAutocomplete
    disablePortal
    id="combo-box-demo"
    // onChange={(event, value) => {setBulkCouponArr(value);}}
    options={[]}
    multiple
    //onChange={handleOnChange}
    onChange={(event, value) => {console.log(value,"******Vdfv val"); setBulkCouponArr(value); setIsBulkCouponEdited(true)}}
    value={bulkCouponArr}
    sx={{ width: '100%' }}
    renderInput={(params) => (
      <TextField
        {...params}
        onChange={(e) => {
          // searchLocation(e.target.value);
          setBulkCouponCode(e.target.value)
        }}
        label="Dynamic Coupon Code"
      />
    )}
  />;
  }, [bulkCouponArr]);
  const excelUploadRef = useRef(null);

  console.log(excel,"****************vvvvvv")
  let el = useRef();

  useEffect(() => {
    if (gender !== "both"){
      setUhidPhoneFilterEnabled(false)
    }
  }, [gender])
  const handleChange = (event) => {
    setVisibility((preVal) => ({
      ...preVal,
      checkedA: event.target.checked
    }));
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    setLoadingDisable(true);

    let data = new FormData();

    if (allType.test || allType.package) {
      let type = "all";
      if (allType.test && allType.package) {
        type = "all"
      } else if (allType.test && !allType.package) {
        type = "test"
      } else if (!allType.test && allType.package) {
        type = "package"
      }
      testDiscountArr[0].testDetail = {
        item_id: type,
        item_name: type,
        item_type: type
      }
    }
    data.append('name', couponName);
    data.append('code',  bulkCouponArr.length ? bulkCouponArr[0] : couponCode);
    data.append('attempts', attempts);
    data.append('visibility', visibility.checkedA);
    data.append('start_datetime', moment(startDate).format('YYYY-MM-DD HH:mm:ss'));
    data.append('end_datetime', moment(endDate).format('YYYY-MM-DD HH:mm:ss'));
    data.append('location_type', locationType);
    data.append('applicationType', applicationType);
    data.append('gender', gender);
    data.append('collectionType', collectionType);
    data.append('newUser', newUser);
    data.append('noBooking', noBooking);
    data.append('description', description);
    data.append('minimumOrderValue', minimumOrderValue);
    data.append('maxDiscountValue',maxDiscountValue);
    data.append('couponType',couponType);
    data.append('couponArr', JSON.stringify(couponType === "static" ? [] : bulkCouponArr.slice(1)))
    data.append("bulkId", couponType === "static" ? "" : bulkId);
    data.append('sheet', gender !== "both" ? null : excel);
    let uhidPhonePayload = JSON.stringify(uhidPhoneArr);

    if (gender === "both"){
      data.append('phone_numbers', uhidPhoneFilterEnabled && uhidPhoneVal === "phone" && uhidPhoneArr.length ? uhidPhonePayload : null);
      data.append('uhids', uhidPhoneFilterEnabled && uhidPhoneVal === "uhid" && uhidPhoneArr.length ? uhidPhonePayload : null);
    }
    if (locationVar && locationVar.length) {
      locationVar.map((item) => {
        data.append('location_ids', item.location_id);
      })
    } else {
      data.append('location_ids', null);
    }
    data.append("couponItem", JSON.stringify(testDiscountArr));
    data.append('image', image);
    if (dates && dates.length) {
      dates.map((item) => {
        if (typeof item === "string") {
          data.append('scheduleDate', item);
        } else {
          data.append('scheduleDate', item.format());
        }
      })
    }

    setCouponAddError({});
    console.log('id ==========', id);
    let response = await addorEdit(data, id);
    if (response && response.data && response.data.message) {
      setCouponAddError(response.data.message);
    }else{
      if (response && response.data && response.data.errors)
      setCouponAddError({...response.data.errors});
    }
    setLoadingDisable(false);
  };

  const searchLocation = async (val) => {
    setLocationList([]);
    try {
      const res = await helper.getQueryString(
        `${apiPath.search_location}`,
        { type: locationType, search_location: val },
        session
      );
      if (res.status === 200) {
        if (res.data.data.length > 0) {
          setLocationList(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const searchLocationById = async (id, type, item_name) => {
    try {
      const res = await helper.getQueryString(
        `${apiPath.search_location}`,
        { type: type, cityId: id },
        session
      );
      if (res.status === 200) {
        if (res.data.success === true) {
          if (res.data.data.length > 0) {
            setLocation(((preValue) => ([
              ...preValue,
              res.data.data[0]
            ])))
            setLocationList(res.data.data);
            if (item_name) {
              searchTestOrPackageId(item_name, res.data.data[0]);
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const searchTestOrPackageId = async (val, locObj) => {
    setTestOrPackageList([]);
    try {
      const res = await helper.getQueryString(
        `${apiPath.search_test_or_package}`,
        { CityId: locObj.CityId, PanelId: locObj.Panel_ID, SearchKeyword: val },
        session
      );
      if (res.status === 200) {
        if (res.data.success === true) {
          if (res.data.data.length > 0) {
            setTestOrPackageList(res.data.data);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSearchLocation = (e) => {
    console.log('search');
    let target = e.target.value;
    searchLocation(e.target.value);
  };

  const handlePercentageChange = (e, index, discountType) => {
    if (discountType == 'Percent') {
      if (isNaN(Number(e.target.value)) || parseInt(e.target.value) > 100) {
        return;
      } else {
        setTestDiscountArr(testDiscountArr.map((test, i) => {
          if (index === i) {
            return {
              ...test,
              discount: e.target.value
            }
          } else {
            return test
          }
        }))
      }
    } else {
      setTestDiscountArr(testDiscountArr.map((test, i) => {
        if (index === i) {
          return {
            ...test,
            discount: e.target.value
          }
        } else {
          return test
        }
      }))
    }
  };

  const searchTestOrPackage = async (val) => {
    setTestOrPackageList([]);
    try {
      const res = await helper.getQueryString(
        `${apiPath.search_test_or_package}`,
        { CityId: locationVar?.[0].CityId, PanelId: locationVar?.[0].Panel_ID, SearchKeyword: val },
        session
      );
      if (res.status === 200) {
        if (res.data.success === true) {
          if (res.data.data.length > 0) {
            setTestOrPackageList(res.data.data);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSearchTestOrPackage = (e) => {
    searchTestOrPackage(e.target.value);
  };

  const handleOnChange = (e, val) => {
    var result = locationList.find((obj) => {
      return obj.location_name === val;
    });
    console.log('result', result);
    if (result !== undefined && result !== '') {
      setLocation(result);
    }
  };

  const getCouponType = [
    { id: 'test', title: 'Test' },
    { id: 'package', title: 'Package' },
    { id: 'test_package', title: 'Test & Package' }
  ];

  const getCouponUsage = [
    { id: 'only_once', title: 'Only Once' },
    { id: 'multiple', title: 'Multiple' }
  ];

  const couponDiscountList = [
    { id: 'Percent', title: 'Percentage Disount' },
    { id: 'Fix', title: 'Value Discount' }
  ];
  const couponTypeList = [
    { id: 'static', title: 'Static Coupon' },
    { id: 'dynamic', title: 'Dynamic Coupon' }
  ];
  const addUhidPhoneFilterList = [
    { id: 'yes', title: 'Yes' },
    { id: 'no', title: 'No' }
  ];

  const getLocationType = [
    { id: 'Center', title: 'Center' },
    { id: 'City', title: 'City' }
  ];

  const getFile = (e) => {
    let ext = e.target.value.match(/\.([^\.]+)$/)[1];
    switch (ext) {
      case 'png':
      case 'jpeg':
      case 'jpg':
        const file = e.target.files[0];
        setImage(file);
        break;
      default:
        alert('Please upload supported attachment file format (.png, .jpeg, .jpg).');
        e.target.value = '';
    }
  };

  const getExcelFile = (e) => {
    const file = e.target.files[0];
    setExcel(file)
  }

  useEffect(() => {
    //console.log('recordForEdit --------', recordForEdit);
    if (recordForEdit) {
      setCouponName(recordForEdit.name);
      setCouponCode(recordForEdit.code);
      if (recordForEdit.couponArr && recordForEdit.couponArr.length){
        let tmpArr = recordForEdit.couponArr.split(",")
        setBulkCouponArr(tmpArr)
        setCouponCount(tmpArr.length)
        setCouponType("dynamic")
        setBulkId(recordForEdit.bulk_id)
      }
      setAttempts(recordForEdit.attempts);
      if (recordForEdit.uhids || recordForEdit.phone_numbers){
        setUhidPhoneFilterEnabled(true)
        setUhidPhoneArr(recordForEdit.uhids ? JSON.parse(recordForEdit.uhids) : JSON.parse(recordForEdit.phone_numbers))
        setUhidPhoneVal(recordForEdit.uhids ? "uhid" : "phone")
      }
      setVisibility((preVal) => ({
        ...preVal,
        checkedA: recordForEdit.visibility
      }));
      setStartDate(recordForEdit.start_datetime);
      setEndDate(recordForEdit.end_datetime);
      setLocationType(recordForEdit.location_type); 
      setmMinimumOrderValue(recordForEdit?.minimum_order_value ? recordForEdit?.minimum_order_value : "0");
      setMaxDiscountValue(recordForEdit?.max_discount ? recordForEdit?.max_discount : "0");
      //setLocation({ location_id: recordForEdit.location_ids });
      if (recordForEdit?.couponItem) {
        console.log(recordForEdit.couponItem,"*****Cddsc")
        recordForEdit.couponItem = recordForEdit.couponItem && typeof recordForEdit.couponItem === "string" ? JSON.parse(recordForEdit.couponItem) : recordForEdit.couponItem;
        recordForEdit.couponItem.map((data) => {
          if (data.testDetail.item_id === "all") {
            setAllType(((preValue) => ({
              ...preValue, 
              test: true,
              package: true
            })))
          } else if (data.testDetail.item_id === "test") {
            setAllType(((preValue) => ({
              ...preValue, 
              test: true,
            })))
          } else if (data.testDetail.item_id === "package") {
            setAllType(((preValue) => ({
              ...preValue, 
              package: true,
            })))
          }
        })
      }
      if (recordForEdit.scheduleDate) {
        recordForEdit.scheduleDate = JSON.parse(recordForEdit.scheduleDate);
        if (typeof recordForEdit.scheduleDate === "object") {
          setDates(recordForEdit.scheduleDate);
        } else {
          setDates([recordForEdit.scheduleDate]);
        }
      } else {
        setDates([])
      }
      setApplicationType(recordForEdit.applicationType);
      setGender(recordForEdit.gender || "both");
      setCollectionType(recordForEdit?.collectionType);
      setNewUser(recordForEdit?.newUser);
      setNoBooking(recordForEdit?.noBooking);
      setDescription(recordForEdit?.description);
      setTestDiscountArr(recordForEdit.couponItem);
      recordForEdit.location_ids= JSON.parse(recordForEdit.location_ids);
      if (typeof recordForEdit.location_ids === "string" || typeof recordForEdit.location_ids === "number") {
        searchLocationById(
          recordForEdit.location_ids,
          recordForEdit.location_type,
          recordForEdit.item_name
        );
      } else {
        if (recordForEdit.location_ids && recordForEdit.location_ids.length) {
          recordForEdit.location_ids.map((item) => {
            searchLocationById(
              item,
              recordForEdit.location_type,
              recordForEdit.item_name
            );
          })
        } else {
          setLocation([]);
        }
      }
      setId(recordForEdit.id);
    }
  }, [recordForEdit]);

  const addMoreTest = () => {
    const obj = {
      discountType: "Percent",
      discount: "",
      testDetail: ""
    };
    const testDiscountAddOnClone = [];
    testDiscountAddOnClone.push(...testDiscountArr);
    testDiscountAddOnClone.push(obj);
    setTestDiscountArr(testDiscountAddOnClone);
  }

  const removeTestDiscount = (index) => {
    const testDiscountAddOnClone = [];
    testDiscountAddOnClone.push(...testDiscountArr);
    testDiscountAddOnClone.splice(index, 1);
    setTestDiscountArr(testDiscountAddOnClone);
  }
  const generateUniqueCouponCodes = (numberOfCodes, baseCode = "newcoupon", codeLength = 6) => {
    console.log(numberOfCodes,"*********numberOfCodes")
    const usedCodes = new Set(); // Store generated codes for uniqueness check
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // Character set for random suffix
  
    const generatedCodes = [];
  
    while (generatedCodes.length < numberOfCodes) {
      let randomSuffix = "";
      for (let i = 0; i < codeLength; i++) {
        randomSuffix += characters.charAt(Math.floor(Math.random() * characters.length));
      }
  
      const couponCode = `${baseCode}${randomSuffix}`;
  
      if (!usedCodes.has(couponCode) && !bulkCouponArr.includes(couponCode)) {
        usedCodes.add(couponCode);
        generatedCodes.push(couponCode);
      }
    }
  
    return generatedCodes;
  }

  const downloadCoupons = async () => {
    if (isBulkCouponEdited)
      await handleSubmit()
    
    const response = await helper.get(apiPath.download_coupon+`?id=${id}`, session);
    if (response.data.success == true) {
      const link = response.data.data.excel_link ?? null;

      if (link){

        console.log(link.split("/").at(-1),"((((((((hvjvhjvhj")
        const a = document.createElement('a');
        a.href = link;
        a.download=link.split("/").at(-1)
        
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }
  }

  const uploadCoupons = async () => {
    excelUploadRef.current.click()
  }

  return (
    <Page title="Add User | Dr. B. Lal">
      <Container maxWidth={true}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography variant="h4" gutterBottom>
              Coupons
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/">
                Dashboard
              </Link>
              <Link underline="hover" color="inherit" component={RouterLink} to="/coupons">
                All Coupons
              </Link>
              {<Typography color="text.primary">coupon Edit</Typography>}
            </Breadcrumbs>
          </Grid>
        </Stack>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Card className="MuiCardPadding">
            <FormControl disabled>
              <Controls.RadioGroup
                name="couponType"
                label="Coupon Type"
                required
                value={couponType}
                onChange={(e) => {
                  if (e.target.value === 'dynamic'){
                    setVisibility({
                        checkedA: false
                    })
                    setUhidPhoneFilterEnabled(false)
                  }
                  setCouponType(e.target.value);
                }}
                disabled={id ? true : false}
                items={couponTypeList}
                error={couponAddError?.couponItem?.[index]?.discountType}
              />
            </FormControl>
            <Stack direction="row" style={{ justifyContent: 'space-between' }}>
              <Controls.Input
                style={{ width: '49%' }}
                name="couponName"
                fullWidth
                label="Coupon Name"
                value={couponName}
                onChange={(e) => setCouponName(e.target.value)}
                error={couponAddError?.couponName}
                required={true}
              />
{
  couponType === "static" ? 

  <Controls.Input
    style={{ width: '49%' }}
    name="couponCode"
    fullWidth
    label="Coupon Code"
    type="couponCode"
    value={couponCode}
    onChange={(e) => setCouponCode(e.target.value)}
    error={couponAddError?.code}
    required
  />
   : <Controls.Input
   style={{ width: '49%' }}
   name="couponCount"
   fullWidth
   label="Coupon Count"
   value={couponCount}
   onChange={(e) => setCouponCount(e.target.value)}
   error={couponAddError?.couponCount}
   required={true}
 />
}
            </Stack>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Gender"
                  value={gender}
                  onChange={(e) => {
                    setGender(e.target.value);
                  }}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="both">Both</MenuItem>
                </Select>
              </FormControl>
 </Grid>
            {
  couponType === "dynamic" ?
  
<Grid item xs={12}>
      <FormControl style={{ width: '100%', marginTop: 0, margin: '8px 8px 8px 0'}}>
           {memoizedBulkCouponList}
        </FormControl>
        <Box style={{position: "absolute",
    right: "26px",
    top: "290px"}}>
          <LoadingButton
            size="medium"
            // type="submit"
            variant="contained"
            onClick={() => {setBulkCouponArr((prev) => [...prev,...generateUniqueCouponCodes(couponCount,bulkCouponCode)]); setIsBulkCouponEdited(true)}}
          >
            Generate
          </LoadingButton>
        </Box>
        {
          bulkCouponArr.length && id 
          ? <><Button
            // variant="outlined"
            style={{marginLeft: '20px'}}
            onClick={(e) => {
              downloadCoupons()
            }}
          >
            {isBulkCouponEdited ? 'Save & ' : ''}Download
            </Button>
            <Button
            // variant="outlined"
            style={{marginLeft: '20px'}}
            onClick={() => {
              uploadCoupons()
            }}
            disabled={gender !== "both"}
          >
            Upload Sheet
            </Button>
            <div style={{marginLeft: "28px",
            color: "red",opacity: "0.7"}}><strong>Note: </strong>Please provide either uhid or phone number for some or all coupons while uploading csv</div>
            </>
          : null
        }
        {excel ? "uploaded" : ""}
        </Grid>

        : null
}

            <Grid item xs={12}>
              <Controls.Input
                name="description"
                rows={5}
                multiline={true}
                fullWidth
                label="Coupon Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                error={couponAddError?.description}
              />
            </Grid>

            <Stack direction="row" spacing={2} alignItems="center">
              <FormControl style={{ width: '49%' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    // renderInput={(props) => <TextField {...props} />}
                    label="Start Date"
                    value={dayjs(startDate)}
                    onChange={(newValue) => {
                      setStartDate(newValue.$d.toISOString());
                    }}
                  />
                </LocalizationProvider>
              </FormControl>

              <FormControl style={{ width: '49%' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    // renderInput={(props) => <TextField {...props} />}
                    label="End Date"
                    value={dayjs(endDate)}
                    onChange={(newValue) => {
                      setEndDate(newValue.$d.toISOString());
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Stack>

            <Stack direction="row" spacing={2} alignItems="center">
              <Typography style={{ margin: '12px' }}>Coupon Area :</Typography>
            </Stack>

            <Stack direction="row" spacing={2} alignItems="center">
              <FormControl style={{ width: '49%' }}>
                <InputLabel id="state">Location Type </InputLabel>
                <MuiSelect
                  id="locationType"
                  label="Location Type"
                  value={locationType}
                  required={true}
                  onChange={(e) => {
                    setTestDiscountArr(testDiscountArr.map((test, i) => {
                      return {
                        ...test,
                        testDetail: ""
                      }
                    }))
                    setLocation([]);
                    setLocationType(e.target.value);
                  }}
                  error={couponAddError?.locationType}
                >
                  {getLocationType.map((e, key) => {
                    return (
                      <MenuItem key={key} value={e.id}>
                        {e.title}
                      </MenuItem>
                    );
                  })}
                </MuiSelect>
              </FormControl>

                <FormControl style={{ width: '49%' }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={(event, value) => {console.log(value,"*******vvvv"); setLocation(value); setLocationList([])}}
                    options={locationList}
                    multiple
                    //onChange={handleOnChange}
                    getOptionLabel={(option) => option?.location_name ? option?.location_name : ""}
                    value={locationVar}
                    sx={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={(e) => {
                          searchLocation(e.target.value);
                        }}
                        label="Search Location..."
                      />
                    )}
                  />
                </FormControl>
            </Stack>

            <Stack direction="row" spacing={2} alignItems="center">
              <Controls.Input
                style={{ width: '49%' }}
                name="attempts"
                fullWidth
                label="Attempts"
                value={attempts}
                onChange={(e) => setAttempts(e.target.value)}
                error={couponAddError?.attempts}
                required={true}
              />
              <FormControl style={{ width: '49%' }} className={"datePicker"}>
                <DatePicker
                  value={dates}
                  onChange={setDates}
                  multiple
                  format="YYYY-MM-DD"
                  placeholder='Select schedule dates'
                />
              </FormControl>
            </Stack>
            <FormControlLabel
              control={
                <Switch
                  checked={visibility.checkedA}
                  onChange={(e) => handleChange(e)}
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled={couponType === 'dynamic' ? true : false}
                />
              }
              label="Visibility"
              labelPlacement='start'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={newUser}
                  onChange={(e) => setNewUser(e.target.checked)}
                  name="newUser"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              }
              label="New User"
              labelPlacement='start'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={noBooking}
                  onChange={(e) => setNoBooking(e.target.checked)}
                  name="noBooking"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              }
              label="No Booking users"
              labelPlacement='start'
            />
            <Grid item xs={12}>
              <FormControl style={{ width: '48%' }}>
                <InputLabel id="demo-simple-select-label">Application Type</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Application Type"
                  value={applicationType}
                  onChange={(e) => {
                    setApplicationType(e.target.value);
                  }}
                >
                  <MenuItem value="app">App</MenuItem>
                  <MenuItem value="website">Website</MenuItem>
                  <MenuItem value="both">Both</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <FormControl>
              <Controls.RadioGroup
                name="couponType"
                label="Do you want to add for specific uhid / mobile number ?"
                required
                value={uhidPhoneFilterEnabled ? "yes" : "no"}
                disabled={couponType === 'dynamic' || gender!=="both" ? true : false}
                onChange={(e) => {
                  setUhidPhoneFilterEnabled(e.target.value === "yes" ? true : false);
                }}
                items={addUhidPhoneFilterList}
                // error={couponAddError?.couponItem?.[index]?.discountType}
              />
            </FormControl>
            {
              uhidPhoneFilterEnabled ?
              <>
                <Grid item xs={12}>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">UHID / Mobile Number</InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="UHID / Mobile Number"
                      value={uhidPhoneVal}
                      onChange={(e) => {
                        setUhidPhoneVal(e.target.value);
                        setUhidPhoneArr([])
                        setUhidPhoneText("")
                      }}
                    >
                      <MenuItem value="uhid">UHID</MenuItem>
                      <MenuItem value="phone">Mobile Number</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Stack direction="row" spacing={2} alignItems="center">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  // onChange={(event, value) => {setBulkCouponArr(value);}}
                  options={[uhidPhoneText]}
                  multiple
                  onChange={(event, value) => {console.log(value, "*********Vdfv vvv");setUhidPhoneArr(value) }}
                  //onChange={handleOnChange}
                  value={uhidPhoneArr}
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => {
                        setUhidPhoneText(e.target.value)
                      }}
                      label={uhidPhoneVal === "phone" ? "Mobile Number" : "UHID"}
                    />
                  )}
                />
                </Stack>

              </>
              : null
            }
            <Grid item xs={12}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Collection Type</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Application Type"
                  value={collectionType}
                  onChange={(e) => {
                    setCollectionType(e.target.value);
                  }}
                >
                  <MenuItem value="home">Home</MenuItem>
                  <MenuItem value="lab">Lab</MenuItem>
                  <MenuItem value="both">Both</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">Select all test/package</FormLabel>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="test"
                  control={<Checkbox checked={allType.test} onChange={((e) => {
                    setAllType((preValue) => ({
                      ...preValue,
                      test: e.target.checked
                    }))
                  })} />}
                  label="Test"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="package"
                  control={<Checkbox checked={allType.package} onChange={((e) => {
                    setAllType((preValue) => ({
                      ...preValue,
                      package: e.target.checked
                    }))
                  })} />}
                  label="Package"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
            {testDiscountArr.map((testDiscount, index) =>
              <React.Fragment key={index}>
                {
                  allType.test || allType.package ?
                    "" :
                    <Stack>
                      <FormControl>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          value={testDiscount.testDetail}
                          onChange={(event, value) => setTestDiscountArr(testDiscountArr.map((test, i) => {
                            if (index === i) {
                              return {
                                ...test,
                                testDetail: value
                              }
                            } else {
                              return test
                            }
                          }))}
                          options={testOrPackageList}
                          getOptionLabel={(option) => option?.item_name ? option?.item_name : ""}
                          defaultValue={testDiscount.testDetail}
                          sx={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={(e) => {
                                searchTestOrPackage(e.target.value);
                              }}
                              label="Search Test or Package..."
                              required={true}
                              error={couponAddError?.couponItem?.[index]?.testDetail}
                            />
                          )}
                        />

                      </FormControl>
                    </Stack>
                }
                <Stack direction="row" spacing={2} alignItems="center">
                  <FormControl>
                    <Controls.RadioGroup
                      name="couponDiscountType"
                      label="Coupon Discount Type"
                      required
                      value={testDiscount.discountType}
                      onChange={(e) => {
                        setTestDiscountArr(testDiscountArr.map((test, i) => {
                          if (index === i) {
                            return {
                              ...test,
                              discountType: e.target.value,
                              discount: "",
                            }
                          } else {
                            return test
                          }
                        }))
                      }}
                      items={couponDiscountList}
                      error={couponAddError?.couponItem?.[index]?.discountType}
                    />
                  </FormControl>
                  {index === 0 ? ""
                    : <Button
                      variant="outlined"
                      onClick={() => {
                        removeTestDiscount(index);
                      }}
                    >
                      <CloseIcon />
                    </Button>}
                </Stack>

                {testDiscount.discountType === 'Percent' ? (
                  <>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <FormControl style={{ width: '49%' }}>
                        <Controls.Input
                          name="discount"
                          fullWidth
                          label="Discount Value (%)"
                          value={testDiscount.discount}
                          onChange={(e) => handlePercentageChange(e, index, testDiscount.discountType)}
                          error={couponAddError[index]?.couponItem?.discount}
                          required={true}
                        />
                      </FormControl>
                    </Stack>
                  </>
                ) : (
                  <>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <FormControl style={{ width: '49%' }}>
                        <Controls.Input
                          name="discount"
                          fullWidth
                          label="Discount Value"
                          value={testDiscount.discount}
                          onChange={(e) => handlePercentageChange(e, index)}
                          error={couponAddError[index]?.couponItem?.discount}
                          required={true}
                        />
                      </FormControl>
                      {/* <FormControl style={{ width: '49%' }}>
                    <Controls.Input
                      name="maximumOrderValue"
                      fullWidth
                      label="Maximum Order value"
                      value={maximumOrderValue}
                      onChange={(e) => setMaximumOrderValue(e.target.value)}
                      error={couponAddError?.maximumOrderValue}
                      required={true}
                    />
                  </FormControl> */}
                    </Stack>
                  </>
                )}
              </React.Fragment>
            )}
            {allType.test || allType.package ?
              "" :
              <Button
                variant="outlined"
                onClick={() => {
                  addMoreTest();
                }}
              >
                Add More Test Discount
              </Button>
            }

          <Stack direction="row" spacing={2} alignItems="center">
            <FormControl style={{ width: '49%' }}>
                        <Controls.Input
                          name="minimumOrderValue"
                          fullWidth
                          label="Minimum Order value"
                          value={minimumOrderValue}
                          onChange={(e) => setmMinimumOrderValue(e.target.value)}
                          error={couponAddError?.minimumOrderValue}
                          required={true}
                        />
                      </FormControl>
                      </Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                      <FormControl style={{ width: '49%' }}>
                        <Controls.Input
                          name="maxDiscountValue"
                          fullWidth
                          label="Maximum Discount Value"
                          value={maxDiscountValue}
                          onChange={(e) => setMaxDiscountValue(e.target.value)}
                          error={couponAddError?.maxDiscountValue}
                        />
                      </FormControl>
                      </Stack>

            <Stack>
              <TextField
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                name="image"
                type="file"
                accept="image/*"
                onChange={getFile}
                ref={el}
                error={couponAddError?.image}
                helperText={couponAddError?.image}
              />
            </Stack>
            {preview ? <img src={preview} alt="couponImage" with="80" height="80" /> : ''}
            <Box>
              <LoadingButton
                size="medium"
                type="submit"
                variant="contained"
                disabled={loadingDisable}
              >
                Submit
              </LoadingButton>
            </Box>
              <input 
              accept=".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              type='file' name="sheet" style={{display:"none"}} onChange={(e)=> {console.log(e.target.files,"*****8888"); getExcelFile(e)}} ref={excelUploadRef}></input>
          </Card>
        </Form>
      </Container>
    </Page>
  );
}
