import React from 'react';
import { useState, useEffect } from 'react';
import {
  InputLabel,
  Grid,
  Box,
  TextField,
  FormControlLabel,
  Switch
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CKEditor from 'react-ckeditor-component';

import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';

export default function Add(props) {
  let { addorEdit, recordForEdit } = props;
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState('');
  const [latestDiagnosticFormError, setLatestDiagnosticFormError] = useState({});
  const [loadingDisable, setLoadingDisable] = useState(false);
  const [status, setStatus] = useState(true);

  const getFile = (e) => {
    let ext = e.target.value.match(/\.([^\.]+)$/)[1];
    switch (ext) {
      case 'png':
      case 'jpeg':
      case 'jpg':
        const file = e.target.files[0];
        setImage(file);
        break;
      default:
        alert('Please upload supported attachment file format (.png, .jpeg, .jpg).');
        e.target.value = '';
    }
  };

  const handleSubmit = async (e) => {
    setLoadingDisable(true);
    e.preventDefault();
    let data = new FormData();
    data.append('image', image);
    data.append('title', title);
    data.append('content', content);
    data.append('status', status);
    if (id) {
      data.append('id', id);
    }
    let response = await addorEdit(data, id);

    if (response && response.data && response.data.errors) {
      setLatestDiagnosticFormError(response.data.errors);
      setLoadingDisable(false);
    }
  };

  useEffect(() => {
    if (recordForEdit) {
      setId(recordForEdit.id);
      setTitle(recordForEdit.title);
      setContent(recordForEdit.content);
      setStatus(recordForEdit.status);
    }
  }, [recordForEdit])

  return (
    <Form onSubmit={handleSubmit} title="Add Latest Diagnostic | Dr. B. Lal">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controls.Input
            name="title"
            fullWidth
            label="Latest Diagnostic Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={latestDiagnosticFormError?.title}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel id="demo-simple-select-label" shrink={true}>
            Image
          </InputLabel>
          <TextField
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            name="image"
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={getFile}
            error={latestDiagnosticFormError?.image}
            helperText={latestDiagnosticFormError?.image}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel id="demo-simple-select-label" shrink={true}>
            Content
          </InputLabel>
          <CKEditor
            activeClass="p10"
            content={content}
            events={{
              afterPaste: (evt) => {
                const newContent = evt.editor.getData();
                setContent(newContent);
              },
              change: (evt) => {
                const newContent = evt.editor.getData();
                setContent(newContent);
              }
            }}
          ></CKEditor>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch checked={status} onChange={(e) => setStatus(e.target.checked)} color="primary" />}
            label="Status"
            labelPlacement="start"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <LoadingButton
            size="medium"
            type="submit"
            variant="contained"
            endIcon
            disabled={loadingDisable}
          >
            {id ? 'Update' : 'Add'}
          </LoadingButton>
        </Box>
      </Grid>
    </Form>
  );
}
