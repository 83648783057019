import { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import {
  Checkbox,
  RadioGroup,
  Radio,
  FormControlLabel,
  Card,
  FormControl,
  Grid,
  Breadcrumbs,
  Link,
  Stack,
  Box,
  TextField,
  Container,
  Typography,
  CircularProgress
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Backdrop } from '@mui/material';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import Controls from '../../components/control/Controls';
import 'react-toastify/dist/ReactToastify.css';
import { CKEditor } from 'ckeditor4-react';
import { Form } from '../../components/useForm';
import Page from '../../components/Page';
import useSession from 'src/hooks/useSession';

export default function AddBlog(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isHome, setIsHome] = useState(null);
  const [fileType, setFileType] = useState('Image');
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  let el = useRef();
  const session = useSession();
  const [blogFormError, setBlogFormError] = useState({});
  const [loadingDisable, setLoadingDisable] = useState(false);

  const fetchNewAndEvents = async () => {
    try {
      let res = await helper.get(`${apiPath.news_event_details}/${id}`, session);
      if (res.status === 200) {
        if (res.data.status === 200) {
          setTitle(res.data.data.title);
          setIsHome(res.data.data.is_home);
          setFileType(res.data.data.file_type);
          setPreviewImage(res.data.data.file);
          setDescription(res.data.data.description);
          if (res.data.data.file_type === "Video") {
            setVideoUrl(res.data.data.file);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    if (id != undefined) {
      fetchNewAndEvents()
    }
  }, []);

  const getFile = (e) => {
    let ext = e.target.value.match(/\.([^\.]+)$/)[1];
    if (fileType == 'Image') {
      switch (ext) {
        case 'png':
        case 'jpeg':
        case 'jpg':
          const file = e.target.files[0];
          setImage(file);
          break;
        default:
          alert('Please upload supported attachment file format (.png, .jpeg, .jpg).');
          e.target.value = '';
      }
    } else if (fileType == 'Video') {
      switch (ext) {
        case 'mp4':
        case 'mov':
        case 'webm':
        case 'wmv':
          const file = e.target.files[0];
          setImage(file);
          break;
        default:
          alert('Please upload supported attachment file format (.mp4, .mov, .webm, .wmv).');
          e.target.value = '';
      }
    } else {
      alert('Please Select file type first.');
      e.target.value = '';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingDisable(true);
    let data = new FormData();

    data.append('image', image);
    data.append('title', title);
    data.append('description', description);
    let is_home = isHome == null ? false : isHome;
    data.append('isHome', is_home);
    data.append('fileType', fileType);
    if (fileType === "Video") {
      data.append("videoUrl", videoUrl);
    }
    setBlogFormError({});
    let response = '';
    let URL = apiPath.news_event_add_update;
    if (id != undefined) {
      URL = `${URL}?id=${id}`;
    }

    response = await helper.post(data, URL, session);

    if (response.status === 200) {
      setLoadingDisable(false);
      if (response.data.status === 200) {
        navigate('/newsevents');
      }
      if (response.data.status === 422) {
        setBlogFormError(response.data.errors);
      }
    }
  };

  let style = {
    color: '#FF4842'
  };
  let pageTitle = 'Add  News & Event';
  if (id != undefined) {
    pageTitle = 'Edit  News & Event';
  }

  return (
    <Page title={`${pageTitle} | Dr. B Lal`}>
      <Backdrop
        sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography variant="h4" gutterBottom>
              News & Event
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/">
                Dashboard
              </Link>
              <Typography color="text.primary">{pageTitle}</Typography>
            </Breadcrumbs>
          </Grid>
        </Stack>
        <Form onSubmit={handleSubmit} title="Add  News & Event | Dr. B. Lal">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card className="MuiCardPadding">
                <Stack spacing={2}>
                  <Controls.Input
                    name="title"
                    fullWidth
                    label="News & Events Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    error={blogFormError?.title}
                    required
                  />

                  <FormControl fullWidth>
                    {id != undefined && description && (
                      <CKEditor
                        fullWidth
                        initData={description}
                        config={{
                          allowedContent: true,
                          height: 250
                        }}
                        onChange={(evt) => {
                          if (evt.editor.getData() === '') {
                            setDescription(' ');
                          } else {
                            setDescription(evt.editor.getData());
                          }
                        }}
                        error={'error'}
                        helperText={blogFormError?.description}
                      />
                    )}

                    {id == undefined && (
                      <CKEditor
                        fullWidth
                        initData={description}
                        config={{
                          allowedContent: true,
                          height: 250
                        }}
                        onChange={(evt) => setDescription(evt.editor.getData())}
                        error={'error'}
                        helperText={blogFormError?.description}
                      />
                    )}

                    <p style={style}>{blogFormError?.description}</p>
                  </FormControl>

                  {isHome !== null && id && (
                    <FormControlLabel
                      style={{ width: '8%' }}
                      control={
                        <Checkbox
                          defaultChecked={isHome}
                          value={isHome}
                          onChange={(e) => {
                            let value = false;
                            if (isHome == null || isHome == false) {
                              value = true;
                            }
                            console.log('value', value);
                            setIsHome(value);
                          }}
                        />
                      }
                      label="For Home"
                      color="success"
                    />
                  )}

                  {id == undefined && (
                    <FormControlLabel
                      style={{ width: '20%' }}
                      control={
                        <Checkbox
                          defaultChecked={isHome}
                          onChange={(e) => {
                            let value = false;
                            if (isHome == null || isHome == false) {
                              value = true;
                            }
                            console.log('value', value);
                            setIsHome(value);
                          }}
                        />
                      }
                      label="For Home"
                      color="success"
                    />
                  )}

                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={fileType}
                    >
                      <FormControlLabel
                        value="Image"
                        control={
                          <Radio
                            onChange={(e) => {
                              setImage(null);
                              setFileType('Image');
                            }}
                          />
                        }
                        label="Image"
                      />
                      <FormControlLabel
                        value="Video"
                        control={
                          <Radio
                            onChange={(e) => {
                              setImage(null);
                              setFileType('Video');
                            }}
                          />
                        }
                        label="Video"
                      />
                    </RadioGroup>
                  </FormControl>
                  {fileType === "Image" ?
                    <TextField
                      name="image"
                      type="file"
                      onChange={getFile}
                      required={`${id === undefined ? 'required' : ''}`}
                      ref={el}
                      error={blogFormError?.image}
                      helperTex
                      t={blogFormError?.image}
                    />
                    :
                    <Controls.Input
                      name="videoUrl"
                      fullWidth
                      label="Video Url"
                      value={videoUrl}
                      onChange={(e) => setVideoUrl(e.target.value)}
                      error={blogFormError?.videoUrl}
                      required
                    />}

                  <Box>
                    <LoadingButton
                      size="medium"
                      type="submit"
                      variant="contained"
                      endIcon
                      disabled={loadingDisable}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                  {previewImage ? <img src={previewImage} alt="Banner_Image" /> : ''}
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Form>
      </Container>
    </Page>
  );
}
