import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {
  Card,
  Breadcrumbs,
  CircularProgress,
  FormControlLabel,
  Link,
  Checkbox,
  Grid,
  Stack,
  Box,
  Container
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import Page from '../../components/Page';
import { Backdrop } from '@mui/material';
import { Form } from '../../components/useForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useSession from 'src/hooks/useSession';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

export default function CustomizedAccordions() {
  const session = useSession();
  const { role_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [models, setModels] = useState([]);
  const [roleName, setRoleName] = useState(null);
  const [modelPermissions, setModelPermissions] = useState([]);

  const handleModelPermissions = (id) => {
    const splitId = id.split('#');
    const module_id = splitId[0];
    const permission = splitId[1];
    const selectedCheckboxes = modelPermissions;

    // Find index
    // const findIdx = selectedCheckboxes.indexOf(model);
    const findIdx = selectedCheckboxes.findIndex((p) => p.module_id == module_id);

    if (findIdx > -1) {
      let updateCheckBox = [...selectedCheckboxes];

      if (permission == 'r_screen' && updateCheckBox[findIdx].permissions['r_screen'] === true) {
        updateCheckBox[findIdx].permissions['r_screen'] = false;
      } else if (
        permission == 'r_screen' &&
        updateCheckBox[findIdx].permissions['r_screen'] === false
      ) {
        updateCheckBox[findIdx].permissions['r_screen'] = true;
      }

      if (permission == 'r_create' && updateCheckBox[findIdx].permissions.r_create === true) {
        updateCheckBox[findIdx].permissions.r_create = false;
      } else if (
        permission == 'r_create' &&
        updateCheckBox[findIdx].permissions.r_create === false
      ) {
        updateCheckBox[findIdx].permissions.r_create = true;
      }

      if (permission == 'r_update' && updateCheckBox[findIdx].permissions.r_update === true) {
        updateCheckBox[findIdx].permissions.r_update = false;
      } else if (
        permission == 'r_update' &&
        updateCheckBox[findIdx].permissions.r_update === false
      ) {
        updateCheckBox[findIdx].permissions.r_update = true;
      }

      if (permission == 'r_view' && updateCheckBox[findIdx].permissions.r_view === true) {
        updateCheckBox[findIdx].permissions.r_view = false;
      } else if (permission == 'r_view' && updateCheckBox[findIdx].permissions.r_view === false) {
        updateCheckBox[findIdx].permissions.r_view = true;
      }

      if (permission == 'r_delete' && updateCheckBox[findIdx].permissions.r_delete === true) {
        updateCheckBox[findIdx].permissions.r_delete = false;
      } else if (
        permission == 'r_delete' &&
        updateCheckBox[findIdx].permissions.r_delete === false
      ) {
        updateCheckBox[findIdx].permissions.r_delete = true;
      }
      setModelPermissions(updateCheckBox);
    } else {
      let permissions = {
        r_screen: permission == 'r_screen' ? true : false,
        r_create: permission == 'r_create' ? true : false,
        r_update: permission == 'r_update' ? true : false,
        r_view: permission == 'r_view' ? true : false,
        r_delete: permission == 'r_delete' ? true : false
      };
      selectedCheckboxes.push({ module_id: module_id, permissions: permissions });
      setModelPermissions(selectedCheckboxes);
    }
  };

  const getModels = async () => {
    try {
      setLoading(true);
      const res = await helper.get(`${apiPath.getModules}?role_id=${role_id}`, session);
      if (res.status === 200) {
        setLoading(false);
        if (res.data.success === true) {
          setModels(res.data.data);
          const data = res.data.data;
          data.map((item) => {
            if (item.role_right) {
              if (item.role_right.r_screen === true) {
                handleModelPermissions(`${item.role_right.module_id}#r_screen`);
              }
              if (item.role_right.r_create === true) {
                handleModelPermissions(`${item.role_right.module_id}#r_create`);
              }
              if (item.role_right.r_update === true) {
                handleModelPermissions(`${item.role_right.module_id}#r_update`);
              }
              if (item.role_right.r_view === true) {
                handleModelPermissions(`${item.role_right.module_id}#r_view`);
              }
              if (item.role_right.r_delete === true) {
                handleModelPermissions(`${item.role_right.module_id}#r_delete`);
              }
            }
          });
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getRoleName = async () => {
    try {
      setLoading(true);
      const res = await helper.get(`${apiPath.getRoleName}/${role_id}`, session);
      if (res.status === 200) {
        setLoading(false);
        if (res.data.success === true) {
          setRoleName(res.data.data.name);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getRoleName();
    getModels();
  }, []);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const res = await helper.post(
        { role_id: role_id, permissions: modelPermissions },
        apiPath.setPermission,
        session
      );
      if (res.status === 200) {
        setLoading(false);
        if (res.data.success === true) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      }
    } catch (error) {}
  };

  const [expanded, setExpanded] = React.useState('panel0');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Page title={'Add Booking | Dr. B Lal'}>
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Backdrop
        sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography variant="h4" gutterBottom>
              Role ({roleName})
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/">
                Dashboard
              </Link>
              <Typography color="text.primary">Set or Edit Permissions</Typography>
            </Breadcrumbs>
          </Grid>
        </Stack>
        <Form onSubmit={handleSubmit} title="Add Permission to Role | Dr. B. Lal">
          <Card className="MuiCardPadding">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div>
                  {models.length &&
                    models.map((item, key) => (
                      <Accordion
                        expanded={expanded === `panel${key}`}
                        onChange={handleChange(`panel${key}`)}
                      >
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                          <Typography>{item.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={`${item.id}#r_screen`}
                                    defaultChecked={
                                      item.role_right && item.role_right.r_screen ? true : false
                                    }
                                    onChange={(e) => handleModelPermissions(e.target.value)}
                                    selected={modelPermissions.includes(`${item.id}#r_screen`)}
                                  />
                                }
                                label="List"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={`${item.id}#r_create`}
                                    defaultChecked={
                                      item.role_right && item.role_right.r_create ? true : false
                                    }
                                    onChange={(e) => handleModelPermissions(e.target.value)}
                                    selected={modelPermissions.includes(`${item.id}#r_create`)}
                                  />
                                }
                                label="Add"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={`${item.id}#r_update`}
                                    defaultChecked={
                                      item.role_right && item.role_right.r_update ? true : false
                                    }
                                    onChange={(e) => handleModelPermissions(e.target.value)}
                                    selected={modelPermissions.includes(`${item.id}#r_update`)}
                                  />
                                }
                                label="Edit"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={`${item.id}#r_view`}
                                    defaultChecked={
                                      item.role_right && item.role_right.r_view ? true : false
                                    }
                                    onChange={(e) => handleModelPermissions(e.target.value)}
                                    selected={modelPermissions.includes(`${item.id}#r_view`)}
                                  />
                                }
                                label="View"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={`${item.id}#r_delete`}
                                    defaultChecked={
                                      item.role_right && item.role_right.r_delete ? true : false
                                    }
                                    onChange={(e) => handleModelPermissions(e.target.value)}
                                    selected={modelPermissions.includes(`${item.id}#r_delete`)}
                                  />
                                }
                                label="Delete"
                              />
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                </div>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <LoadingButton size="medium" type="submit" variant="contained" endIcon>
                    Submit
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Form>
      </Container>
    </Page>
  );
}
