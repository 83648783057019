import React, { useState, useEffect } from 'react';
import { Grid, Typography, Checkbox, Box } from '@mui/material';
import Page from '../../components/Page';
import helper from '../../constants/helper';
import apiPath from 'src/constants/apiPath';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useSession from 'src/hooks/useSession';

const Coupon = (props) => {
    const [allCoupons, setAllCoupons] = useState([]);
    const [allMembershipCard, setAllMembershipCard] = useState([]);
    const [offerType, setOfferType] = useState("Coupon");
    const [offerCode, setOfferCode] = useState("");
    const [offerId, setOfferId] = useState("");
    const [membershipIndex, setMembershipIndex] = useState();
    const session = useSession();

    const getAllOffers = async () => {
        const response = await helper.get(`${apiPath.booking_offers}?members=${props?.memberId}&userId=${props?.userId}&collectionCheck=${props?.collectionType === "Home" ? "home" : "lab"}&locationId=${props.locationId}`, session);
        if (response.status === 200) {
            if (response.data.status === 200) {
                //setBookingTime(response.data.data[0]);
                console.log(response.data.data);
                setAllCoupons(response.data.data.coupons);
                setAllMembershipCard(response.data.data.membership);
            }
        }
    }

    useEffect(() => {
        getAllOffers();
    }, [])

    const handleSubmit = () => {
        if (offerType === "" || offerCode === "") {
            toast.error("Please select atleast one code.");
            return false;
        } else {
            props.handleCouponConfirm(offerType, offerCode, offerId);
            props.setOpenPopup(false);
        }
    }

    return (
        <Page title="Add Coupon | Dr. B. Lal">
            <Grid container>
                <Grid item xs={12}>
                    <Grid item>
                        <Typography variant='h5'>Coupons</Typography>
                    </Grid>
                    <Grid container xs={12}>
                        {allCoupons.map((coupon) => {
                            return (
                                <Grid key={coupon.id} item xs={5.5} className={'couponContainer'}>
                                    <Grid item className={'container1'}>
                                        <Typography className={'couponHead'}>{coupon.name}</Typography>
                                        <span>Coupon Code: {coupon.code}</span>
                                        <span>Expired At: {coupon.end_datetime}</span>
                                    </Grid>
                                    <Grid item className={'couponCheck'}>
                                        <Checkbox checked={offerId === coupon.id} onChange={((e) => {
                                            if (!e.target.checked) {
                                                setOfferType("");
                                                setOfferCode("");
                                                setOfferId("");
                                            } else {
                                                setOfferType("Coupon");
                                                setOfferCode(coupon.code);
                                                setOfferId(coupon.id);
                                            }
                                        })}></Checkbox>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h5'>Membership Cards</Typography>
                    </Grid>
                    <Grid container xs={12}>
                        {allMembershipCard.map((coupon, index) => {
                            return (
                                <Grid key={coupon.id} item xs={5.5} className={'couponContainer'}>
                                    <Grid item className={'container1'}>
                                        <Typography className={'couponHead'}>{coupon.MembershipCardName}</Typography>
                                        <span>Coupon Code: {coupon.CardNo}</span>
                                    </Grid>
                                    <Grid item className={'couponCheck'}>
                                        <Checkbox checked={membershipIndex === index} onChange={((e) => {
                                            if (!e.target.checked) {
                                                setMembershipIndex("");
                                                setOfferId("");
                                                setOfferType("");
                                                setOfferCode("");
                                            } else {
                                                setMembershipIndex(index);
                                                setOfferId(coupon.MembershipCardID);
                                                setOfferType("Membership");
                                                setOfferCode(coupon.CardNo);
                                            }
                                        })}></Checkbox>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12} className={'buttonContainer'}>
                    <Box>
                        <LoadingButton onClick={handleSubmit} size="medium" variant="contained">
                            Confirm
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </Page>
    );
}

export default Coupon;