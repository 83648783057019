import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import Page from '../../components/Page';
import { Link as RouterLink } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import AddIcon from '@mui/icons-material/Add';
import Popup from 'src/components/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import AddItem from './Add';
import moment from 'moment';
import Switch from '@mui/material/Switch';

import {
    Box,
    Stack,
    Grid,
    Breadcrumbs,
    Link,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    Toolbar,
    CircularProgress
} from '@mui/material';

import { Backdrop } from '@mui/material';
import useDragAndDrop from 'src/components/useDragAndDrop';
import useSession from 'src/hooks/useSession';

TeamMembers.propTypes = {
    sx: PropTypes.object
};

function TeamMembers({ sx }) {
    const [coreMembersData, setCoreMembersData] = useState([]);
    const session = useSession();
    const [openPopup, setOpenPopup] = useState(false);
    const [ConfrimAlertOpen, setConfrimAlertOpen] = useState(false);
    const [ConfirmAlertMessage, setConfirmAlertMessage] = useState(null);
    const [ConfirmAlertStatus, setConfirmAlertStatus] = useState(false);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [addBlog, setAddBlog] = useState(false);
    const [editCategory, setEditCategory] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(false);
    const [filterFn, setFilterFn] = useState({
        fn: (items) => {
            return items;
        }
    });

    const handleBulkUpdate = useCallback(async (data) => {
        try {
            const payload = data.reduce((curr, item) => {
                const member = { id: item?.id, priority: item?.priority }
                curr.push(member)
                return curr
            }, [])

            const res = await helper.post(payload, `${apiPath.core_members}/members_bulk_update`, session);
            if (res.status === 200) {
                if (res?.data?.success === true) {
                    getCoreMembersData()
                    toast.success(res?.data?.message)
                }
            }
        } catch (err) {
            toast.error(err.message)
        }
    }, [])

    const { handleDragEnd, handleDragEnter, handleDragStart, dragging } = useDragAndDrop({ coreMembersData, handleBulkUpdate })

    const headeCells = [
        { id: 'image', label: 'Image', disableSorting: true },
        { id: 'fullname', label: 'Name', disableSorting: true },
        { id: 'designation', label: 'Designation', disableSorting: true },
        { id: 'qualification', label: 'Qualification', disableSorting: true },
        { id: 'link', label: 'Link', disableSorting: true },
        // { id: 'description', label: 'Description', disableSorting: true },
        { id: 'member_type', label: 'Member Type', disableSorting: true },
        { id: 'status', label: 'Status', disableSorting: true },
        { id: 'createAt', label: 'Created At', disableSorting: true },
        { id: 'action', label: 'Action', disableSorting: true }
    ];

    const getCoreMembersData = async () => {
        try {
            const res = await helper.get(`${apiPath.core_members}/get_core_members`, session);
            if (res?.status === 200) {
                if (res?.data?.success === true) {
                    setCoreMembersData(res?.data?.data);
                }
            }
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        getCoreMembersData();
    }, []);

    const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
        coreMembersData,
        headeCells,
        filterFn
    );

    const openInPopup = (item) => {
        setRecordForEdit(item);
        setOpenPopup(true);
    };

    const addorEdit = async (data, id) => {
        setLoading(true);
        try {
            if (!recordForEdit) {
                const response = await helper.post(data, `${apiPath.core_members}/add_core_members`, session);
                if (response?.data?.success == true) {
                    getCoreMembersData();
                    setOpenPopup(false);
                    toast.success(response?.data?.message);
                } else {
                    toast.error(response?.data?.message);
                    return response;
                }
            } else {
                const res = await helper.put(data, `${apiPath.core_members}/update_core_members/${id}`, session);
                if (res?.data?.success == true) {
                    getCoreMembersData();
                    setOpenPopup(false);
                    toast.success(res?.data?.message);
                } else {
                    toast.error(res?.data?.message);
                    return res;
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (item) => {
        try {
            setLoading(true);
            const res = await helper.delete(`${apiPath.core_members}/delete_core_members/${item.id}`, session);
            if (res?.data?.status === 200) {
                if (res?.data?.success) {
                    getCoreMembersData();
                    toast.success(res?.data?.message);
                    setConfirmAlertStatus(false);
                }
            }
        } catch (e) {
            toast.error(e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleStatusChange = async (item) => {
        try {
            setLoading(true);
            const data = {
                ...item,
                status: !item?.status
            };

            const response = await helper.put(data, `${apiPath.core_members}/update_core_members/${item?.id}`, session);
            if (response?.status === 200) {
                setLoading(false);
                if (response?.data?.success === true) {
                    getCoreMembersData();
                    toast.success(response?.data?.message);
                }
            }
        } catch (e) {
            setLoading(false);
            console.error(e);
        }
    };

    return (
        <>
            <Page title={'Core Members | Dr. B Lal'}>
                <ToastContainer
                    theme="colored"
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Backdrop
                    sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 999 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Container maxWidth={false}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Typography variant="h4" gutterBottom>
                                Core Members
                            </Typography>

                            <Breadcrumbs aria-label="breadcrumb">
                                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                                    Dashboard
                                </Link>
                                <Typography color="text.primary">core-members</Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Stack>

                    <Toolbar>
                        <Controls.Button
                            text="Add New Item"
                            variant="outlined"
                            startIcon={<AddIcon />}
                            className={'newButton'}
                            onClick={() => {
                                setOpenPopup(true);
                                setRecordForEdit(null);
                                setAddBlog(true);
                            }}
                        />
                    </Toolbar>

                    <TblContainer>
                        <TblHeader />
                        <TableBody>
                            {recordsAfterPagingAndSorting()?.map((item, idx) => (
                                <TableRow
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, idx)}
                                    onDragEnter={
                                        dragging
                                            ? (e) => {
                                                handleDragEnter(e, idx);
                                            }
                                            : null
                                    }
                                    onDragEnd={(e) => handleDragEnd()}
                                    key={item?.id}>
                                    <TableCell>
                                        <Box>
                                            <img
                                                src={`${process.env.REACT_APP_API_BASE_URL}${item?.image}`}
                                                style={{ height: 80, minWidth: 80 }}
                                                alt="life-at-blal"
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={{ width: 50, textTransform: "capitalize" }}>{item?.fullname}</TableCell>
                                    <TableCell sx={{ width: 50 }}>{item?.designation}</TableCell>
                                    <TableCell sx={{ width: 50 }}>{item?.qualification}</TableCell>
                                    <TableCell sx={{ width: 100 }}>{item?.link}</TableCell>
                                    <TableCell sx={{ width: 50 }}>{item?.member_type}</TableCell>
                                    {/* <TableCell sx={{ width: 450 }}>{item?.description}</TableCell> */}
                                    <TableCell>
                                        <Switch
                                            checked={item?.status}
                                            onChange={() => handleStatusChange(item)}
                                        />
                                    </TableCell>
                                    <TableCell>{moment(item?.created_at).format('DD/MM/YYYY')}</TableCell>

                                    <TableCell>
                                        <Controls.ActionButton
                                            color="primary"
                                            onClick={() => {
                                                openInPopup(item);
                                                setEditCategory(true);
                                            }}
                                        >
                                            <EditOutlinedIcon fontSize="small" />
                                        </Controls.ActionButton>

                                        {/* <Controls.ActionButton
                                            color="secondary"
                                            onClick={() => {
                                                handleDelete(item);
                                            }}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </Controls.ActionButton> */}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </TblContainer>
                    <TblPagination />
                </Container>
            </Page>

            <Popup
                title={`${recordForEdit ? 'Edit' : 'Add'} Team Members`}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <AddItem
                    recordForEdit={recordForEdit}
                    addorEdit={addorEdit}
                />
            </Popup>
        </>
    );
}
export default TeamMembers;
