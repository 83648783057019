import React from 'react';
import { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Grid,
  Stack,
  Box,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';
import useSession from 'src/hooks/useSession';

export default function Add(props) {
  let { addorEdit, recordForEdit } = props;
  const [cities, setCities] = useState([]);
  const session = useSession();
  const [id, setId] = useState('');
  const [pincode, setPincode] = useState('');
  const [cityId, setCityID] = useState('');
  const [name, setName] = useState('');
  const [open, setOpen] = useState(false);
  const [pincodeFormError, setPincodeFormError] = useState({});
  const [loadingDisable, setLoadingDisable] = useState(false);

  const getCitites = async () => {
    try {
      const res = await helper.get(`${apiPath.get_citites_list}`, session);
      if (res.status === 200) {
        setCities(res.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCitites();
    if (recordForEdit) {
      setId(recordForEdit.id);
      setCityID(recordForEdit.CityId);
      setName(recordForEdit.name);
      setPincode(recordForEdit.pincode);
    }
  }, ['recordForEdit']);

  const handleSubmit = async (e) => {
    setLoadingDisable(true);
    e.preventDefault();
    let data = {
      id: id,
      name: name,
      CityId: cityId.toString(),
      pincode: pincode.toString()
    };

    let response = await addorEdit(data, id);
    if (response && response.data && response.data.message) {
      setPincodeFormError(response.data.errors);
      setLoadingDisable(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit} title="Add Category | Dr. B. Lal">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Stack>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">City</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={cityId}
                label="Category"
                onChange={(event) => {
                  setCityID(event.target.value);
                }}
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
                onOpen={() => {
                  setOpen(true);
                }}
                error={''}
                helperText={''}
              >
                {cities.map((e, key) => {
                  return (
                    <MenuItem key={key} value={e.CityId}>
                      {e.CityName}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error>{pincodeFormError?.CityId}</FormHelperText>
            </FormControl>
            <Controls.Input
              name="name"
              fullWidth
              label="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={pincodeFormError?.name}
            />

            <Controls.Input
              type="tel"
              name="pincode"
              fullWidth
              label="Pincode"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              error={pincodeFormError?.pincode}
              inputProps={{ maxLength: 6 }}
            />

            <Box>
              <LoadingButton
                size="medium"
                type="submit"
                variant="contained"
                endIcon
                disabled={loadingDisable}
              >
                Submit
              </LoadingButton>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Form>
  );
}
