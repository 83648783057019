import { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Card,
  FormControl,
  Input,
  InputLabel,
  Grid,
  Breadcrumbs,
  Link,
  Stack,
  Box,
  TextField,
  Select,
  MenuItem,
  Container,
  Typography,
  Autocomplete,
  CircularProgress
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Backdrop } from '@mui/material';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import Controls from '../../components/control/Controls';
import 'react-toastify/dist/ReactToastify.css';
import { CKEditor } from 'ckeditor4-react';
import { Form } from '../../components/useForm';
import Page from '../../components/Page';
import { ToastContainer, toast } from 'react-toastify';
import CChipInput from "../../components/cChipInput";
import 'react-toastify/dist/ReactToastify.css';
import useSession from 'src/hooks/useSession';

export default function AddBlog(props) {
  let { addorEdit, recordForEdit } = props;
  const navigate = useNavigate();
  const { blog_id } = useParams();
  const [blogId, setblogId] = useState('');
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [isHome, setIsHome] = useState(null);
  const [published, setPublished] = useState(null);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [blogCategory, setBlogCategory] = useState([]);
  const [blogSubCategory, setBlogSubCategory] = useState([]);
  const [blogCategory_id, set_blogCategory_id] = useState(null);
  const [blogSubCategory_id, set_blogSubCategory_id] = useState(null);
  const [image, setImage] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  let el = useRef();
  const session = useSession();
  const [blogFormError, setBlogFormError] = useState({});
  const [loadingDisable, setLoadingDisable] = useState(false);
  const [isBlogEdit, setIsBlogEdit] = useState(false);
  const [slug, setSlug] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [trending, setTrending] = useState(0)

  const getBlogCategories = async () => {
    try {
      const res = await helper.get(`${apiPath.get_blog_categories}`, session);
      if (res.status === 200) {
        setBlogCategory(res.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setCategoryId = async (categoryObj) => {
    try {
      set_blogCategory_id(categoryObj.id);
      let res = await helper.get(
        `${apiPath.get_blog_categories}?parent_id=${categoryObj.id}`,
        session
      );
      if (res.status === 200) {
        if (res.data.status === 200) {
          if (res.data.data.length > 0) {
            set_blogSubCategory_id(res.data.data[0].id);
          }

          setBlogSubCategory(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setSubCategoryId = (subCategoryObj) => {
    set_blogSubCategory_id(subCategoryObj.id);
  };
  const getBlogDetails = async () => {
    getBlogCategories();
    if (blog_id != undefined) {
      try {
        let res = await helper.get(`${apiPath.blogdetails}?blog_id=${blog_id}`, session);
        if (res.status === 200) {
          if (res.data.status === 200) {
            setIsBlogEdit(true);
            setblogId(blog_id);
            setTitle(res.data.data.title);
            setAuthor(res.data.data.author);
            setIsHome(res.data.data.is_home);
            setPublished(res.data.data.published);
            setImage(res.data.data.image);
            setPreviewImage(res.data.data.image);
            set_blogCategory_id(res.data.data.category_id);
            set_blogSubCategory_id(res.data.data.sub_category_id);
            setCategoryId(res.data.data.categoryDetails);
            setDescription(res.data.data.description);
            setSlug(res.data.data.slug);
            setMetaTitle(res.data.data.metaTitle);
            setMetaDescription(res.data.data.metaDescription);
            setKeywords(res.data.data.keywords ? JSON.parse(res.data.data.keywords) : []);
            setTrending(res.data.data.trending)
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
  useEffect(() => {
    getBlogDetails()
  }, []);

  const getFile = (e) => {
    let ext = e.target.value.match(/\.([^\.]+)$/)[1];
    switch (ext) {
      case 'png':
      case 'jpeg':
      case 'jpg':
        const file = e.target.files[0];
        setImage(file);
        break;
      default:
        alert('Please upload supported attachment file format (.png, .jpeg, .jpg).');
        e.target.value = '';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = new FormData();
    data.append('image', image);
    data.append('title', title);
    data.append('author', author);
    data.append('description', description);
    let is_home = isHome == null ? false : isHome;
    data.append('isHome', is_home);
    let publishedN = published == null ? false : published;
    data.append('published', publishedN);
    data.append('category_id', blogCategory_id ? blogCategory_id.toString() : '');
    data.append('sub_category_id', blogSubCategory_id ? blogSubCategory_id.toString() : '');
    data.append("slug", slug);
    data.append("metaTitle", metaTitle);
    data.append("metaDescription", metaDescription);
    data.append("keywords", keywords);
    data.append("trending", trending)

    let response = '';
    if (blog_id != undefined) {
      response = await helper.put(data, `${apiPath.updtae_blog_details}/${blog_id}`, session);
    } else {
      response = await helper.post(data, `${apiPath.create_blog}`, session);
    }
    if (response.status === 200) {
      if (response.data.status === 200) {
        navigate('/blogs');
      }
      if (response.data.status === 422) {
        if (blog_id != undefined) {
          toast.error(response.data.message);
        }
        setBlogFormError(response.data.errors);
      }
    }
  };

  let style = {
    color: '#FF4842'
  };
  let pageTitle = 'Add Blog';
  if (blog_id != undefined) {
    pageTitle = 'Edit Blog';
  }

  return (
    <Page title={`${pageTitle} | Dr. B Lal`}>
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Backdrop
        sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography variant="h4" gutterBottom>
              Blog
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/">
                Dashboard
              </Link>
              <Typography color="text.primary">{pageTitle}</Typography>
            </Breadcrumbs>
          </Grid>
        </Stack>
        <Form onSubmit={handleSubmit} title="Add Blog | Dr. B. Lal">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card className="MuiCardPadding">
                <Stack spacing={2}>
                  <Controls.Input
                    name="title"
                    fullWidth
                    label="Blog Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    error={blogFormError?.title}
                    helperText={blogFormError?.title}
                  />
                  <Controls.Input
                    name="author"
                    fullWidth
                    label="Medically Reviewed by"
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                    error={blogFormError?.author}
                    helperText={blogFormError?.author}
                  />

                  <FormControl fullWidth>
                    {blog_id != undefined && description && (
                      <CKEditor
                        fullWidth
                        initData={description}
                        config={{
                          allowedContent: true,
                          height: 250
                        }}
                        onChange={(evt) => {
                          if (evt.editor.getData() == '') {
                            setDescription(' ');
                          } else {
                            setDescription(evt.editor.getData());
                          }
                        }}
                        error={blogFormError?.description}
                      />
                    )}

                    {blog_id == undefined && (
                      <CKEditor
                        fullWidth
                        initData={description}
                        config={{
                          allowedContent: true,
                          placeholder: blogFormError?.description
                            ? blogFormError?.description
                            : 'Add content here...',
                          height: 250
                        }}
                        onChange={(evt) => setDescription(evt.editor.getData())}
                      />
                    )}
                  </FormControl>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    {blogCategory_id && blogCategory.length > 0 && blog_id != undefined && (
                      <>
                        <Autocomplete
                          autoSelect={true}
                          id="combo-box-demo"
                          onChange={(event, value) => {
                            setCategoryId(value);
                            set_blogSubCategory_id(null);
                          }}
                          options={blogCategory}
                          getOptionLabel={(option) => option.name}
                          defaultValue={blogCategory.find((v) => {
                            if (blogCategory_id === v.id) {
                              return v;
                            }
                          })}
                          sx={{ width: '100%' }}
                          renderInput={(params) => <TextField {...params} label="Category" />}
                        />
                      </>
                    )}
                    {blog_id == undefined && (
                      <>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          onChange={(event, value) => setCategoryId(value)}
                          options={blogCategory}
                          getOptionLabel={(option) => option.name}
                          sx={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Category"
                              error={blogFormError?.category_id}
                              helperText={blogFormError?.category_id}
                            />
                          )}
                        />
                      </>
                    )}
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    {blogSubCategory.length > 0 && blog_id != undefined && (
                      <>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          onChange={(event, value) => {
                            setSubCategoryId(value);
                          }}
                          options={blogSubCategory}
                          getOptionLabel={(option) => option.name}
                          defaultValue={
                            blogSubCategory_id
                              ? blogSubCategory.find((v) => blogSubCategory_id)
                              : blogSubCategory[0]
                          }
                          sx={{ width: '100%' }}
                          renderInput={(params) => <TextField {...params} label="Sub Category" />}
                        />
                      </>
                    )}

                    {blogSubCategory.length > 0 && blog_id == undefined && (
                      <>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          onChange={(event, value) => setSubCategoryId(value)}
                          options={blogSubCategory}
                          defaultValue={blogSubCategory[0]}
                          getOptionLabel={(option) => option.name}
                          sx={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Sub Category"
                              error={blogFormError?.sub_category_id}
                              helperText={blogFormError?.sub_category_id}
                            />
                          )}
                        />
                        {/* <p style={style}>{blogFormError?.sub_category_id}</p> */}
                      </>
                    )}
                  </Stack>

                  <Controls.Input
                    name="metaUrl"
                    fullWidth
                    label="Meta Url"
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                    error={blogFormError?.slug}
                    helperText={blogFormError?.slug}
                  />

                  <Controls.Input
                    name="metaTitle"
                    fullWidth
                    label="Meta Title"
                    value={metaTitle}
                    onChange={(e) => setMetaTitle(e.target.value)}
                    error={blogFormError?.metaTitle}
                    helperText={blogFormError?.metaTitle}
                  />

                  <Controls.Input
                    name="metaDescription"
                    fullWidth
                    label="Meta Description"
                    value={metaDescription}
                    onChange={(e) => setMetaDescription(e.target.value)}
                    error={blogFormError?.metaDescription}
                    helperText={blogFormError?.metaDescription}
                  />

                  <CChipInput
                    required={true}
                    id="keywords"
                    name="keywords"
                    label="Keywords"
                    fullWidth
                    onChange={(e, value) => {
                      setKeywords(value);
                    }}
                    value={keywords}
                    error={blogFormError?.keywords}
                    helperText={blogFormError?.keywords}
                  />

                  {isHome !== null && blog_id && (
                    <FormControlLabel
                      style={{ width: '8%' }}
                      control={
                        <Checkbox
                          defaultChecked={isHome}
                          value={isHome}
                          onChange={(e) => {
                            let value = false;
                            if (isHome == null || isHome == false) {
                              value = true;
                            }
                            console.log('value', value);
                            setIsHome(value);
                          }}
                        />
                      }
                      label="For Home"
                      color="success"
                    />
                  )}

                  {blog_id == undefined && (
                    <FormControlLabel
                      style={{ width: '8%' }}
                      control={
                        <Checkbox
                          defaultChecked={isHome}
                          onChange={(e) => {
                            let value = false;
                            if (isHome == null || isHome == false) {
                              value = true;
                            }
                            console.log('value', value);
                            setIsHome(value);
                          }}
                        />
                      }
                      label="For Home"
                      color="success"
                    />
                  )}

                  {published !== null && blog_id && (
                    <FormControlLabel
                      style={{ width: '8%' }}
                      control={
                        <Checkbox
                          defaultChecked={published}
                          value={published}
                          onChange={(e) => {
                            let value = false;
                            if (published == null || published == false) {
                              value = true;
                            }

                            setPublished(value);
                          }}
                        />
                      }
                      label="Published"
                      color="success"
                    />
                  )}

                  {blog_id == undefined && (
                    <FormControlLabel
                      style={{ width: '8%' }}
                      control={
                        <Checkbox
                          defaultChecked={published}
                          onChange={(e) => {
                            let value = false;
                            if (published == null || published == false) {
                              value = true;
                            }

                            setPublished(value);
                          }}
                        />
                      }
                      label="Published"
                      color="success"
                    />
                  )}
                  <FormControlLabel
                    style={{ width: '8%' }}
                    control={
                      <Checkbox
                        defaultChecked={trending}
                        value={trending}
                        onChange={(e) => {
                          let value = 0;
                          if (trending == null || trending == 0) {
                            value = 1;
                          }

                          setTrending(value);
                        }}
                      />
                    }
                    label="Trending"
                    color="success"
                  />

                  <TextField
                    name="image"
                    type="file"
                    accept="image/*"
                    onChange={getFile}
                    ref={el}
                    error={blogFormError?.image}
                    helperText={blogFormError?.image}
                  />
                  {/* <p style={style}>{blogFormError?.image}</p> */}
                  
                  <Box>
                    <LoadingButton size="medium" type="submit" variant="contained" endIcon>
                      {blog_id ? 'Update' : 'Add'}
                    </LoadingButton>
                  </Box>
                  {previewImage ? <img src={previewImage} alt="Banner_Image" /> : ''}
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Form>
      </Container>
    </Page>
  );
}
