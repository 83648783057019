import * as React from 'react';
import Alert from '@mui/lab/Alert';

export default function ErrorResponse(props) {
  return (
    <Alert sx={{ mb: 3 }} severity="error">
      {props.msg}
    </Alert>
  );
}
