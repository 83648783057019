import React from 'react';
import { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  FormHelperText,
  Grid,
  Box,
  Select as MuiSelect,
  MenuItem,
  Container,
  CircularProgress
} from '@mui/material';
import { Backdrop } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import Page from '../../components/Page';
import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';
import 'date-fns';
import moment from 'moment';
import useSession from 'src/hooks/useSession';

export default function Add(props) {
  let { addorEdit, recordForEdit, addUser, userDetailsView } = props;
  const [fullname, setFullname] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone_number, setPhoneNumber] = useState(null);
  const [password, setPassword] = useState(null);
  const [roles, setRoles] = useState([]);
  const [role_id, setRoleId] = useState(null);
  const session = useSession();
  const [userAddFormError, setUserAddFormError] = useState(null);
  const [id, setId] = useState(null);
  let [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      fullname: fullname,
      email: email,
      phone_number: phone_number ? phone_number.toString() : '',
      role_id: role_id ? role_id.toString() : '',
      password: password
    };

    let response = await addorEdit(data, id);
    if (response && response.data && response.data.message) {
      setUserAddFormError(response.data.errors);
    }
  };

  const getSubAdminRoles = async () => {
    try {
      setLoading(true);
      const response = await helper.get(apiPath.getSubAdminRoles, session);
      if (response.status === 200) {
        setLoading(false);
        if (response.data.success === true) {
          setRoles(response.data.data);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  };

  useEffect(() => {
    getSubAdminRoles();
  }, []);

  return (
    <Page title="Add Sub Admin | Dr. B. Lal">
      <Backdrop
        sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={true}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Select Role</InputLabel>
                <MuiSelect
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Role"
                  value={role_id}
                  onChange={(e) => setRoleId(e.target.value)}
                  error={userAddFormError?.role_id}
                >
                  <MenuItem value={null}>Select Role</MenuItem>
                  {roles.length > 0 &&
                    roles.map((e, key) => {
                      return (
                        <MenuItem key={key} value={e.id}>
                          {e.name}
                        </MenuItem>
                      );
                    })}
                </MuiSelect>
                <FormHelperText error>{userAddFormError?.role_id}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="fullname"
                label="Fullname"
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
                error={userAddFormError?.fullname}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="email"
                fullWidth
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={userAddFormError?.email}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="password"
                inputProps={{ maxLength: 10 }}
                label="Password"
                type="tel"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={userAddFormError?.password}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                name="phone_number"
                inputProps={{ maxLength: 10 }}
                label="Phone Number"
                type="tel"
                value={phone_number}
                onChange={(e) => setPhoneNumber(e.target.value)}
                error={userAddFormError?.phone_number}
              />
            </Grid>
          </Grid>

          <Box>
            <LoadingButton size="medium" type="submit" variant="contained">
              Submit
            </LoadingButton>
          </Box>
        </Form>
      </Container>
    </Page>
  );
}
