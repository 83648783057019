import React, { useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import NotFound from './pages/Page404';
import { sideBarItems } from './sideBarItems';
import useSession from './hooks/useSession';

export default function Router() {
  const session = useSession();
  const UserRole = session?.profile?.user ?  session?.profile?.user?.role : null;

  const [sideBarItem, setSideBarItem] = useState(sideBarItems);

  useEffect(() => {
    if (sideBarItems.length && UserRole) {
      if (UserRole?.role_rights?.length) {
        let sideBarArr = [];
        sideBarItems.map((item) => {
          let obj = UserRole.role_rights.find(
            (i) => ((i.module.tbl_name === item.permissionModule && i[`${item.permissionType}`]) || item.permissionModule === "dashboard")
          );
          if (obj) {
            sideBarArr.push(item);
          }
        })
        setSideBarItem(sideBarArr);
      }
    }
  }, [sideBarItems])

  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: sideBarItem
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password/:user_id/:token', element: <ResetPassword /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
