import React from 'react';
import { useState, useEffect } from 'react';
import {
  Card,
  Paper,
  Grid,
  Stack,
  Container,
  Typography,
  Breadcrumbs,
  Link
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import Page from '../../components/Page';
import 'date-fns';
import moment from 'moment';
import useTable from 'src/components/useTable';
import { useLocation } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import useSession from 'src/hooks/useSession';

export default function Details(props) {
  const location = useLocation();
  const [couponDetails, setCouponDetails] = useState([]);
  const [familyMembersDetails, setFamilyMembersDetails] = useState([]);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const session = useSession();

  const getCouponDetails = async () => {
    try {
      const res = await helper.get(`${apiPath.coupon_details}` + location.state.id, session);
      if (res.status === 200) {
        if (res?.data?.data?.couponItem) {
          res.data.data.couponItem = JSON.parse(res.data.data.couponItem);
        }
        setCouponDetails(res.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const headeCells = [
    { id: 'field', label: 'Field' },
    { id: 'Data', label: 'data' }
  ];

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    couponDetails,
    headeCells,
    filterFn
  );

  useEffect(() => {
    getCouponDetails();
  }, []);

  return (
    <Page title="Add User | Dr. B. Lal">
      <Container maxWidth={true}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography variant="h4" gutterBottom>
              Coupon Details
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/">
                Dashboard
              </Link>
              <Link underline="hover" color="inherit" component={RouterLink} to="/coupons">
                Coupon Details
              </Link>
              {<Typography color="text.primary">Coupon Details</Typography>}
            </Breadcrumbs>
          </Grid>
        </Stack>

        <Card>
          <CardHeader title="Personal Details" />
          <CardContent>
            {couponDetails !== undefined && couponDetails !== '' && couponDetails.id !== '' ? (
              <TableContainer component={Paper}>
                <Table className={'table'} aria-label="simple table">
                  <TableBody>
                    <TableRow key="couponName">
                      <TableCell component="th" scope="row">
                        Coupon Name
                      </TableCell>
                      <TableCell align="left">{couponDetails.name}</TableCell>
                    </TableRow>
                    <TableRow key="code">
                      <TableCell component="th" scope="row">
                        Code
                      </TableCell>
                      <TableCell align="left">{couponDetails.code}</TableCell>
                    </TableRow>
                    <TableRow key="attempts">
                      <TableCell component="th" scope="row">
                        Attempts
                      </TableCell>
                      <TableCell align="left">{couponDetails.attempts}</TableCell>
                    </TableRow>
                    <TableRow key="visibility">
                      <TableCell component="th" scope="row">
                        Visibility
                      </TableCell>
                      <TableCell align="left">{couponDetails.visibility ? "Visible" : "Invisible"}</TableCell>
                    </TableRow>
                    <TableRow key="startsOn">
                      <TableCell component="th" scope="row">
                        Start On
                      </TableCell>
                      <TableCell align="left">
                        {moment(couponDetails.start_datetime).format('L')}
                      </TableCell>
                    </TableRow>
                    <TableRow key="endsOn">
                      <TableCell component="th" scope="row">
                        Ends On
                      </TableCell>
                      <TableCell align="left">
                        {moment(couponDetails.end_datetime_datetime).format('L')}
                      </TableCell>
                    </TableRow>
                    <TableRow key="locationType">
                      <TableCell component="th" scope="row">
                        Location Type
                      </TableCell>
                      <TableCell align="left">{couponDetails.location_type}</TableCell>
                    </TableRow>
                    <TableRow key="locationType">
                      <TableCell component="th" scope="row">
                        Schedule Date
                      </TableCell>
                      <TableCell align="left">{couponDetails.scheduleDate ? typeof JSON.parse(couponDetails.scheduleDate) === "object" ? JSON.parse(couponDetails.scheduleDate).join(",") : JSON.parse(couponDetails.scheduleDate) : "-"}</TableCell>
                    </TableRow>
                    <TableRow key="locationType">
                      <TableCell component="th" scope="row">
                        Application Type
                      </TableCell>
                      <TableCell align="left">{couponDetails.applicationType ? couponDetails.applicationType : "-"}</TableCell>
                    </TableRow>
                    {couponDetails?.couponItem?.map((item, index) => {
                      return (
                        <>
                          <Grid>
                            {`Coupon Item ${index + 1}`}
                          </Grid>
                          <TableRow key="locationType">
                            <TableCell component="th" scope="row">
                              Item Type
                            </TableCell>
                            <TableCell align="left">{item?.testDetail?.item_type}</TableCell>
                          </TableRow>
                          <TableRow key="locationType">
                            <TableCell component="th" scope="row">
                              Item Name
                            </TableCell>
                            <TableCell align="left">{item?.testDetail?.item_name}</TableCell>
                          </TableRow>
                          <TableRow key="locationType">
                            <TableCell component="th" scope="row">
                              Discount Type
                            </TableCell>
                            <TableCell align="left">{item?.discountType}</TableCell>
                          </TableRow>
                          <TableRow key="locationType">
                            <TableCell component="th" scope="row">
                              Discount
                            </TableCell>
                            <TableCell align="left">{item?.discount ? item?.discount : "-"}</TableCell>
                          </TableRow>
                          <TableRow key="locationType">
                            <TableCell component="th" scope="row">
                              Maximum discount Value
                            </TableCell>
                            <TableCell align="left">{item?.maxDiscountValue ? item?.maxDiscountValue : "-"}</TableCell>
                          </TableRow>
                          <TableRow key="locationType">
                            <TableCell component="th" scope="row">
                              Minimum order Value
                            </TableCell>
                            <TableCell align="left">{item?.minimumOrderValue ? item?.minimumOrderValue : "-"}</TableCell>
                          </TableRow>
                        </>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </CardContent>
        </Card>
        <br />
      </Container>
    </Page>
  );
}
