import React from 'react'
import { useState } from 'react';
import { Card, FormControl, InputLabel, Stack, Box, TextField, Select as MuiSelect, MenuItem, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment'
import dayjs from 'dayjs'

export default function Add(props) {
    let { addorEdit, recordForEdit, addUser, userDetailsView } = props;
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [age, setAge] = useState('');
    const [phone_number, setPhoneNumber] = useState('');
    const [relation, setRelation] = useState('');
    const [dob, setDob] = useState('');
    const [gender, setGender] = useState('');
    const [date, setDate] = useState(new Date());
    const [loadingDisable, setLoadingDisable] = useState(false);
    const [userAddFormError, setUserAddFormError] = useState({});
    const [id, setId] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingDisable(true)
        let data = {
            "fullname": fullname,
            "email": email,
            "age": age,
            "phone_number": phone_number.toString(),
            "relation": relation,
            "dob": moment(date).format('YYYY-MM-DD'),
            "gender": gender
        }
        setUserAddFormError({});
        let response = await addorEdit(data, id);
        if (response && response.data && response.data.message){
        setUserAddFormError(response.data.message);
        setLoadingDisable(false);
        }
    }


    const getRelationCollection = [
        { id: 'Self', title: 'Self' },
    ];

    const genderItems = [
        { id: 'male', title: 'Male' },
        { id: 'female', title: 'Female' },
        { id: 'other', title: 'Other' },
    ]

    return (
        <Page title="Add User | Dr. B. Lal">
            <Container maxWidth={true}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Card className="MuiCardPadding">
                        <Stack direction="row" style={{ justifyContent: 'space-between' }}>
                            <Controls.Input
                                style={{ width: "49%" }}
                                name="fullname"
                                fullWidth
                                label="Fullname"
                                value={fullname}
                                onChange={(e) => setFullname(e.target.value)}
                                error={userAddFormError?.fullname}
                                required={true}
                            />

                            <Controls.Input
                                style={{ width: "49%" }}
                                name="email"
                                fullWidth
                                label="Email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={userAddFormError?.email}
                                required
                            />

                        </Stack>

                        <Stack direction="row" spacing={2} alignItems="center">
                            <Controls.Input
                                style={{ width: "49%" }}
                                name="phone_number"
                                inputProps={{ maxLength: 10 }}
                                label="Phone Number"
                                type="tel"
                                value={phone_number}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                error={userAddFormError?.phone_number}
                                required
                            />
                            <FormControl style={{ width: "49%" }}>
                                <InputLabel id="relation">Relation</InputLabel>
                                <MuiSelect
                                    id="relation"
                                    label="relation"
                                    value={relation}
                                    required
                                    onChange={(e) => setRelation(e.target.value)}
                                    error={userAddFormError?.relation}
                                >
                                    {getRelationCollection.map((e, key) => {
                                        return <MenuItem key={key} value={e.id}>{e.title}</MenuItem>;
                                    })}
                                </MuiSelect>
                            </FormControl>
                        </Stack>

                        <Stack direction="row" spacing={2} alignItems="center">
                            <Controls.Input
                                style={{ width: "49%" }}
                                name="age"
                                fullWidth
                                label="Age"
                                type="tel"
                                inputProps={{ maxLength: 2 }}
                                value={age}
                                onChange={(e) => setAge(e.target.value)}
                                required
                                error={userAddFormError?.age}
                            />

                            <FormControl style={{ width: "49%" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        disableFuture
                                        margin="normal"
                                        label="DOB"
                                        openTo="day"
                                        required
                                        value={dayjs(date)}
                                        dateFormat="dd/MM/yyyy"
                                        maxDate={dayjs(new Date())} 
                                        onChange={(newValue) => {
                                            setDate(moment(newValue).format("L"));
                                        }}
                                        views={['year', 'month', 'day']}
                                        // renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Stack>

                        <Stack>
                            <Controls.RadioGroup
                                name="gender"
                                label="Gender"
                                required
                                value={gender}
                                onChange={(e) => { setGender(e.target.value) }}
                                items={genderItems}
                                error={userAddFormError?.gender}
                            />
                        </Stack>

                        <Box>
                            <LoadingButton
                                size="medium"
                                type="submit"
                                variant="contained"
                                disabled={loadingDisable}
                            >
                                Submit
                            </LoadingButton>
                        </Box>
                    </Card>
                </Form>
            </Container>
        </Page>
    );
}
