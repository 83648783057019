import Cookies from 'js-cookie';

const useSession = () => {
    let authToken = Cookies.get("token") || null;
    let authProfile  = Cookies.get("profile")
    
    const removeSession = (cookieName) => Cookies.remove(cookieName);
    const setSession = ({
        cookieName,
        data,
        expire = 7
    }) => {
        Cookies.set(cookieName, data, { expires: expire });
    }
    return {
        token:authToken,
        profile: {
            user: authProfile ? JSON.parse(authProfile): {},
        },
        isAuthenticated: authToken,
        removeSession,
        setSession
    }
}

export default useSession;