import React from 'react';
import { useState, useEffect, useRef } from 'react';
import {
  Card,
  FormControl,
  InputLabel,
  Grid,
  Stack,
  Box,
  TextField,
  Select,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';

export default function AddCategory(props) {
  let { addorEdit, recordForEdit } = props;
  let el = useRef();
  const [categoryId, setCategoryId] = useState(null);
  const [name, setName] = useState(null);
  const [subTitle, setSubTitle] = useState("");
  const [image, setImage] = useState(null);
  const [categoryFormError, setCategoryFormError] = useState({});
  const [previewImage, setPreviewImage] = useState('');
  const [loadingDisable, setLoadingDisable] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = new FormData();
    data.append('id', categoryId);
    data.append('title', name);
    data.append('subtitle', subTitle);
    data.append('image', image);
    setCategoryFormError({});
    let response = await addorEdit(data);
    if (response && response.data && response.data.message) {
      setCategoryFormError(response.data.message);
    }
  };

  const getFile = (e) => {
    let ext = e.target.value.match(/\.([^\.]+)$/)[1];
    switch (ext) {
      case 'png':
      case 'jpeg':
      case 'jpg':
        const file = e.target.files[0];
        setImage(file);
        break;
      default:
        alert('Please upload supported attachment file format (.png, .jpeg, .jpg).');
        e.target.value = '';
    }
  };

  useEffect(() => {
    if (recordForEdit) {
      setCategoryId(recordForEdit.id);
      setName(recordForEdit.title);
      setSubTitle(recordForEdit.subtitle);
      setImage(recordForEdit.image);
      setPreviewImage(recordForEdit.image);
    }
  }, ['recordForEdit']);

  return (
    <Form onSubmit={handleSubmit} title="Add Why Choose BLal | Dr. B. Lal">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Controls.Input
            name="name"
            fullWidth
            label="Title"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={categoryFormError?.name}
            required
          />
          <Controls.Input
            name="subtitle"
            fullWidth
            label="Sub Title"
            value={subTitle}
            onChange={(e) => setSubTitle(e.target.value)}
            error={categoryFormError?.subtitle}
            required
          />
          <Controls.Input
            name="image"
            type="file"
            accept="image/*"
            onChange={getFile}
            required={`${categoryId == null ? 'required' : ''}`}
            ref={el}
            error={categoryFormError?.image}
            helperTex
            t={categoryFormError?.image}
          />{' '}
          {previewImage ? <img src={previewImage} alt="Banner_Image" with="80" height="80" /> : ''}
          <Box className="btn-Margin">
            <LoadingButton size="medium" type="submit" variant="contained" endIcon>
              Add
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
}
