import { Box, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect, useRef, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';

const AddItem = ({ addorEdit, recordForEdit }) => {
  let el = useRef();
  const [itemId, setItemId] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [lifeAtBlalFormError, setLifeAtBlalFormError] = useState({});
  const [previewImage, setPreviewImage] = useState('');
  const [loadingDisable, setLoadingDisable] = useState(false);

  const handleSubmit = async (e) => {
    setLoadingDisable(true);
    e.preventDefault();

    let data = new FormData();
    data.append('id', itemId);
    data.append('title', title);
    data.append('description', description);
    data.append('image', image);
    if (recordForEdit) {
      data.append('status', recordForEdit?.status)
    }
    try {
      const response = await addorEdit(data, itemId);
      if (response?.status === 200) {
        setLoadingDisable(false);
        if (response?.data?.status === 422) {
          setLifeAtBlalFormError(response.data.errors);
        }
        if (response.data.status === 500) {
          toast.error(response.data.message);
        }
      }
    } catch (e) {
      toast.error(e.message);
      console.error(e);
    }
  };

  const getFile = (e) => {
    let ext = e?.target?.value?.match(/\.([^\.]+)$/)?.[1];
    switch (ext) {
      case 'png':
      case 'jpeg':
      case 'jpg':
        const file = e?.target?.files?.[0];
        setImage(file);
        break;
      default:
        alert('Please upload supported attachment file format (.png, .jpeg, .jpg).');
        e.target.value = '';
    }
  };

  useEffect(() => {
    if (recordForEdit) {
      setItemId(recordForEdit.id);
      setDescription(recordForEdit.description);
      setTitle(recordForEdit.title);
      setImage(recordForEdit.image);
      setPreviewImage(recordForEdit.image);
    }
  }, [recordForEdit]);
  return (
    <>
      {' '}
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Form onSubmit={handleSubmit} title="Add Item | Dr. B. Lal">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Controls.Input
              name="title"
              fullWidth
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              error={lifeAtBlalFormError?.title}
              helperText={lifeAtBlalFormError?.title}
            />
            <Controls.Input
              name="description"
              fullWidth
              multiline
              rows={4}
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              error={lifeAtBlalFormError?.description}
              helperText={lifeAtBlalFormError?.description}
            />
            <Controls.Input
              name="image"
              type="file"
              accept="image/*"
              onChange={getFile}
              ref={el}
              error={lifeAtBlalFormError?.image}
              helperText={lifeAtBlalFormError?.image}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {previewImage && (
                <Box className="btn-Margin">
                  <img src={previewImage} alt="preview" width="100" height="80" />{' '}
                </Box>
              )}
              <Box className="btn-Margin">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  endIcon
                  sx={{ marginTop: 4 }}
                  disabled={loadingDisable}
                >
                  {itemId ? 'Update' : 'Add'}
                </LoadingButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default AddItem;
