import { useState, useEffect, Fragment } from 'react';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import Page from '../../components/Page';
import { Link as RouterLink } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Popup from 'src/components/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import moment from 'moment';
import CouponsAdd from './Add';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useNavigate } from 'react-router-dom';
import Switch from '@mui/material/Switch';

import {
  Stack,
  Paper,
  Grid,
  Breadcrumbs,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';

import { Backdrop } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateRangePicker from '@mui/lab/DateRangePicker';
import useSession from 'src/hooks/useSession';
import dayjs from 'dayjs'

export default function List() {
  let navigate = useNavigate();
  // const [filterFn, setFilterFn] = useState({ fn: (items) => {return items; } });
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const session = useSession();
  const [couponData, setCouponData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [selectedDate, handleDateChange] = useState([null, null]);
  const [couponStatus, setCouponStatus] = useState('all');
  const [couponType, setCouponType] = useState('all');
  const [addCoupon, setAddCoupon] = useState(false);
  const [editCoupon, setEditCoupon] = useState(false);
  const [couponDetailsView, setCouponDetailsView] = useState(false);
  const [couponImage, setCouponImage] = useState('');
  const [viewCoupon, setViewCoupon] = useState('');
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [listCoupon, setListCoupon] = useState(true);
  const [startDate,setStartDate] = useState(null)
  const [endDate,setEndDate] = useState(null)

  let [loading, setLoading] = useState(false);

  const [searchKeyword, setSearchKeyword] = useState('');
  const handleSearch = (e) => {
    let target = e.target;
    setSearchKeyword(target.value);
    setFilterFn({
      fn: (items) => {
        console.log('items -----', items);
        return target.value == ''
          ? items
          : items.filter((x) => x.name.toLowerCase().includes(target.value));
      }
    });
  };

  const getcouponData = async () => {
    try {
      setLoading(true);
      const res = await helper.get(
        `${apiPath.coupons_list}?&dates=${[startDate,endDate]}&status=${couponStatus}&coupon_type=${couponType}`,
        session
      );
      if (res.status === 200) {
        setLoading(false);
        if (res.data.status === 200) {
          setTotalRevenue(res.data.data.revenue.total);
          setCouponData(res.data.data.docs);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const headeCells = [
    { id: 'name', label: 'Name', disableSorting: true },
    { id: 'code', label: 'Code', disableSorting: true },
    { id: 'attempts', label: 'Attempts', disableSorting: true },
    { id: 'visibility', label: 'Visibility', disableSorting: true },
    { id: 'start_datetime', label: 'From', disableSorting: true },
    { id: 'end_datetime', label: 'To', disableSorting: true },
    { id: 'location_type', label: 'Location Type', disableSorting: true },
    { id: 'Status', label: 'Status', disableSorting: true },
    { id: 'action', label: 'Action', disableSorting: true }
  ];

  useEffect(() => {
    getcouponData();
  }, [startDate, endDate, couponStatus, couponType]);

  const resetFilter = () => {
    handleDateChange([null, null]);
    setCouponStatus('all');
    setCouponType('all');
  };

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    couponData,
    headeCells,
    filterFn,
    searchKeyword
  );

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setAddCoupon(false);
    setViewCoupon(false);
    setEditCoupon(true);
    setListCoupon(false);
  };

  const addorEdit = async (data, id) => {
    // setLoading(true);
    try {
      if (recordForEdit == null) {
        const response = await helper.post(data, apiPath.add_coupon, session);
        if (response.data.success == true) {
          getcouponData();
          setListCoupon(true);
          setAddCoupon(false);
          setEditCoupon(false);
          setTimeout(() => {
            toast.success(response.data.message);
          }, 2000);
        } else {
          setLoading(false);
          return response;
        }
      } else {
        const response = await helper.post(data, `${apiPath.edit_coupon}/${id}`, session);
        if (response.data.success == true) {
          getcouponData();
          setListCoupon(true);
          setAddCoupon(false);
          setEditCoupon(false);
          setTimeout(() => {
            toast.success(response.data.message);
          }, 2000);
        } else {
          setLoading(false);
          return response;
        }
      }
      // setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('Error', error);
    }
  };

  const handlecouponDetailsView = async (item) => {
    setCouponDetailsView(item);
    setRecordForEdit(false);
    setAddCoupon(false);
    navigate('/coupons/details', { state: { id: item.id } });
  };

  const handlecouponImageView = async (item) => {
    setCouponImage(item.image);
    setEditCoupon(false);
    setAddCoupon(false);
    setViewCoupon(true);
    setOpenPopup(true);
  };

  const handleStatusChange = async (item) => {
    try {
      setLoading(true);
      var data = {
        table: 'coupons',
        status: !item.status
      };
      const response = await helper.post(data, `${apiPath.update_status}/${item.id}`, session);
      if (response.status === 200) {
        setLoading(false);
        if (response.data.success === true) {
          getcouponData();
          toast.success(response.data.message);
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleCouponDelete = async (item) => {
    try {
      console.log(item,"***********dfsfdsdfssdf")
      var data = {
        model: 'Coupon',
        row_id: item.id,
        is_dynamic: item.couponArr ? !!item.couponArr.length : false
      };
      const response = await helper.post(data, apiPath.delete_coupon, session);
      if (response.data.status === 200) {
        getcouponData();
        toast.success(response.data.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {listCoupon ? (
        <>
          <Page title={'Coupons List | Dr. B Lal'}>
            <ToastContainer
              theme="colored"
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Backdrop
              sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Container maxWidth={false}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Typography variant="h4" gutterBottom>
                    Coupons List
                  </Typography>

                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/">
                      Dashboard
                    </Link>
                    <Typography color="text.primary">All Coupons </Typography>
                  </Breadcrumbs>
                </Grid>
                <Controls.Button
                    text="Add New Coupon"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    // className={`${'newButton'} ${'addCoupon'}`}
                    className={`addCouponBtn`}
                    onClick={() => {
                      setOpenPopup(true);
                      setRecordForEdit(null);
                      setAddCoupon(true);
                      setListCoupon(false);
                    }}
                  />
                <FormControl sx={{ minWidth: '209px', maxWidth: '272px', flexDirection: 'row', justifyContent: 'space-between'}}>
                  <InputLabel id="demo-simple-select-label" sx={{position: 'relative', marginRight:'14px'}}>
                    <strong>Total Revenue</strong>
                  </InputLabel>
                  <Link to="/coupon_revenues" component={RouterLink}>
                    <Controls.Button
                      text={totalRevenue}
                      // variant="contained"
                      // className={'newButton'}
                    />
                  </Link>
                </FormControl>
              </Stack>

              <Toolbar>
                <Stack direction="row" style={{ justifyContent: 'space-between' }}>
                  <Controls.Input
                    style={{ width: '25%' }}
                    label="Search Coupons"
                    className={'searchInput'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      )
                    }}
                    onChange={handleSearch}
                  />
                  <FormControl sx={{ m: 1, width: 400, marginTop: '0%' }}>
                    <div style={{ display: 'flex', alignItems: "center", gap: '15px' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          // renderInput={(props) => <TextField {...props} />}
                          format="DD/MM/YYYY"
                          label="From Date"
                          maxDate={dayjs(new Date())}
                          value={dayjs(startDate)}
                          onChange={(newValue) => {
                            setStartDate(newValue);
                          }}
                        />
                        <span>to</span>
                        <DatePicker
                          // renderInput={(props) => <TextField {...props} />}
                          format="DD/MM/YYYY"
                          label="To Date"
                          maxDate={dayjs(new Date())}
                          value={dayjs(endDate)}
                          onChange={(newValue) => {
                            setEndDate(newValue);
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateRangePicker
                        startText="FROM DATE"
                        endText="TO DATE"
                        value={selectedDate}
                        inputFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        onChange={(newValue) => {
                          handleDateChange(newValue);
                        }}
                        renderInput={(startProps, endProps) => (
                          <Fragment>
                            <TextField {...startProps} />
                            <Box sx={{ mx: 2 }}> to </Box>
                            <TextField {...endProps} />
                          </Fragment>
                        )}
                      />
                    </LocalizationProvider> */}
                  </FormControl>
                  <FormControl sx={{ m: 1, width: 150, marginTop: '0%' }}>
                    <InputLabel id="demo-simple-select-label">Coupon Status</InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Coupon Status"
                      onChange={(e) => {
                        setCouponStatus(e.target.value);
                      }}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="1">Active</MenuItem>
                      <MenuItem value="0">Inactive</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, width: 150, marginTop: '0%' }}>
                    <InputLabel id="demo-simple-select-label">Coupon Type</InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Coupon Type"
                      onChange={(e) => {
                        setCouponType(e.target.value);
                      }}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="City">City</MenuItem>
                      <MenuItem value="Center">Center</MenuItem>
                      <MenuItem value="Test">Test</MenuItem>
                      <MenuItem value="Package">Package</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, width: 105, marginTop: '0%' }}>
                    <Controls.Button
                      text="Reset"
                      variant="contained"
                      color="error"
                      onClick={resetFilter}
                      className={'newButton'}
                    />
                  </FormControl>
                  {/* <Controls.Button
                    text="Add New Coupon"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    className={`${'newButton'} ${'addCoupon'}`}
                    onClick={() => {
                      setOpenPopup(true);
                      setRecordForEdit(null);
                      setAddCoupon(true);
                      setListCoupon(false);
                    }}
                  /> */}
                </Stack>
              </Toolbar>

              <TblContainer>
                <TblHeader />
                <TableBody>
                  {recordsAfterPagingAndSorting().map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.code}</TableCell>
                      <TableCell>{item.attempts}</TableCell>
                      <TableCell>{item.visibility ? 'True' : 'False'}</TableCell>
                      <TableCell>{moment(item.start_datetime).format('DD/MM/YYYY')}</TableCell>
                      <TableCell>{moment(item.end_datetime).format('DD/MM/YYYY')}</TableCell>
                      <TableCell>{item.location_type}</TableCell>
                      <TableCell>
                        <Switch
                          checked={item.status}
                          onChange={() => {
                            handleStatusChange(item);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Controls.ActionButton
                          color="default"
                          onClick={() => {
                            handlecouponDetailsView(item);
                          }}
                        >
                          <VisibilityIcon fontSize="small" />
                        </Controls.ActionButton>
                        <Controls.ActionButton
                          color="primary"
                          onClick={() => {
                            openInPopup(item);
                            // setEditBanner(true);
                          }}
                        >
                          <EditOutlinedIcon fontSize="small" />
                        </Controls.ActionButton>

                        <Controls.ActionButton
                          color="secondary"
                          onClick={() => {
                            handleCouponDelete(item);
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </Controls.ActionButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </TblContainer>
              <TblPagination />
            </Container>
          </Page>
        </>
      ) : addCoupon || editCoupon ? (
        <CouponsAdd
          title="Edit Coupon"
          addorEdit={addorEdit}
          recordForEdit={recordForEdit}
          addCoupon={addCoupon}
          couponDetailsView={couponDetailsView}
        />
      ) : (
        <Paper variant="outlined">
          <img src={couponImage} alt="Coupon" />
        </Paper>
      )}
    </>
  );
}
