import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

//
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import BlogsList from './pages/blogs/List';
import BlogAdd from './pages/blogs/Add';

import NewsEvenetList from './pages/news_events/List';
import NewsEvenetAdd from './pages/news_events/Add';

import UserList from './pages/users/List';
import UserAdd from './pages/users/Add';
import UserDetails from './pages/users/Details';

import SubAdminList from './pages/subadmins/List';
import SubAdminAdd from './pages/subadmins/Add';
import SubAdminDetails from './pages/subadmins/Details';

import PincodeList from './pages/pincodes/List';
import PincodeAdd from './pages/pincodes/Add';

import RoleList from './pages/roles/List';
import RoleAdd from './pages/roles/Add';
import Module from './pages/roles/Module';

import CategoryList from './pages/blogCategory/List';
import SubCategoryList from './pages/SubCategory/List';

import Banner from './pages/Banners/List';
import HealthBulletin from './pages/healthBulletin/list';
import HappyCustomers from './pages/happyCustomers/list';
import PartnerWithUs from './pages/partnerWithUs/list';
import LatestDiagnostic from './pages/latestDiagnostic/list';
import ReferPatient from './pages/referPatient/list';
import NewsLetter from './pages/newsLetter/list';
import Awards from './pages/awards/list';
import Messages from './pages/messages/list';
import OurClients from './pages/ourClients/list';
import Feedback from './pages/feedback/list';

import Packages from './pages/Packages/List';

import BookingsList from './pages/Bookings/List';
import BookingsDetails from './pages/Bookings/Details';

import CouponsList from './pages/Coupons/List';
import CouponsDetails from './pages/Coupons/Details';

import NotFound from './pages/Page404';

import BookingEarnings from './pages/bookingEarnings/List';
import UserRatingsList from './pages/UserRatings/List';

import FaqCategoryList from './pages/FaqCategory/List';
import FaqList from './pages/Faq/List';

import NeedHelpList from './pages/NeedHelp/List';
import RevenueList from './pages/Revenue/List';

//Maan
import MembershipCardList from './pages/membership/List';
import MembershipCardDetails from './pages/membership/Details';
import AppSetting from './pages/settings/index';
import WhyChooseBlal from './pages/whychooseblal/List';
import PrescriptionList from './pages/prescriptions/List';
import BookingsAdd from './pages/Bookings/Add';
import ChangePassword from './pages/admins/change_password';
import Profile from './pages/admins/profile';
import Notification from './pages/notifications/index';
import ContentList from './pages/contents/List';
import ContentEdit from './pages/contents/Edit';
import Cities from './pages/cities/list';
import UserAppStatus from "./pages/userAppStatus";
import Logs from './pages/logs';
import LifeAtBlal from "./pages/lifeAtBlal";
import TeamMembers from "./pages/teamMembers";
import CityDetails from "./pages/cities/Details"

export const sideBarItems = [
  {
    path: '/',
    checkAccess: false,
    permissionModule: 'dashboard',
    permissionType: 'r_screen',
    element: <Navigate to="/dashboard" replace />
  },
  {
    path: 'dashboard',
    checkAccess: false,
    permissionModule: 'dashboard',
    permissionType: 'r_screen',
    element: <DashboardApp />
  },
  {
    path: 'pincodes',
    checkAccess: true,
    permissionModule: 'pincodes',
    permissionType: 'r_screen',
    element: <PincodeList />
  },
  {
    path: 'users',
    checkAccess: true,
    permissionModule: 'users',
    permissionType: 'r_screen',
    element: <UserList />
  },
  {
    path: 'users/add',
    checkAccess: true,
    permissionModule: 'users',
    permissionType: 'r_create',
    element: <UserAdd />
  },
  {
    path: 'users/:user_id',
    checkAccess: true,
    permissionModule: 'users',
    permissionType: 'r_view',
    element: <UserDetails />
  },
  {
    path: 'subadmin',
    checkAccess: true,
    permissionModule: 'subadmin',
    permissionType: 'r_screen',
    element: <SubAdminList />
  },
  {
    path: 'users_app_status',
    checkAccess: true,
    permissionModule: 'users_app_status',
    permissionType: 'r_screen',
    element: <UserAppStatus />
  },
  {
    path: 'blogs',
    checkAccess: true,
    permissionModule: 'blogs',
    permissionType: 'r_screen',
    element: <BlogsList />
  },
  {
    path: `blogs/add`,
    checkAccess: true,
    permissionModule: 'blogs',
    permissionType: 'r_create',
    element: <BlogAdd />
  },
  {
    path: `blogs/edit/:blog_id`,
    checkAccess: true,
    permissionModule: 'blogs',
    permissionType: 'r_update',
    element: <BlogAdd />
  },
  {
    path: 'blog_category',
    checkAccess: true,
    permissionModule: 'blog_categories',
    permissionType: 'r_screen',
    element: <CategoryList />
  },
  {
    path: 'blog_sub_category',
    checkAccess: true,
    permissionModule: 'blog_sub_categories',
    permissionType: 'r_screen',
    element: <SubCategoryList />
  },
  {
    path: 'banner',
    checkAccess: true,
    permissionModule: 'home_banners',
    permissionType: 'r_screen',
    element: <Banner />
  },
  {
    path: 'cities',
    checkAccess: true,
    permissionModule: 'cities',
    permissionType: 'r_screen',
    element: <Cities />
  },
  {
    path: 'health_bulletin',
    checkAccess: true,
    permissionModule: 'health_bulletin',
    permissionType: 'r_screen',
    element: <HealthBulletin />
  },
  {
    path: 'happy_customers',
    checkAccess: true,
    permissionModule: 'happy_customers',
    permissionType: 'r_screen',
    element: <HappyCustomers />
  },
  {
    path: 'our_clients',
    checkAccess: true,
    permissionModule: 'our_clients',
    permissionType: 'r_screen',
    element: <OurClients />
  },
  {
    path: 'partner_with_us_forms',
    checkAccess: true,
    permissionModule: 'partner_with_us',
    permissionType: 'r_screen',
    element: <PartnerWithUs />
  },
  {
    path: 'news_letter',
    checkAccess: true,
    permissionModule: 'news_letter',
    permissionType: 'r_screen',
    element: <NewsLetter />
  },
  {
    path: 'awards',
    checkAccess: true,
    permissionModule: 'awards',
    permissionType: 'r_screen',
    element: <Awards />
  },
  {
    path: 'messages',
    checkAccess: true,
    permissionModule: 'messages',
    permissionType: 'r_screen',
    element: <Messages />
  },
  {
    path: 'feedback',
    checkAccess: true,
    permissionModule: 'feedback',
    permissionType: 'r_screen',
    element: <Feedback />
  },
  {
    path: 'latest_diagnostic',
    checkAccess: true,
    permissionModule: 'latest_diagnostic',
    permissionType: 'r_screen',
    element: <LatestDiagnostic />
  },
  {
    path: 'refer_patient',
    checkAccess: true,
    permissionModule: 'refer_patient',
    permissionType: 'r_screen',
    element: <ReferPatient />
  },
  {
    path: 'coupons',
    checkAccess: true,
    permissionModule: 'coupons',
    permissionType: 'r_screen',
    element: <CouponsList />
  },
  {
    path: 'coupons/details',
    checkAccess: true,
    permissionModule: 'coupons',
    permissionType: 'r_view',
    element: <CouponsDetails />
  },
  //Role Module
  {
    path: 'roles',
    checkAccess: true,
    permissionModule: 'roles',
    permissionType: 'r_screen',
    element: <RoleList />
  },
  {
    path: 'roles/:role_id/module/permissions',
    checkAccess: true,
    permissionModule: 'modules',
    permissionType: 'r_screen',
    element: <Module />
  },
  {
    path: 'newsevents',
    checkAccess: true,
    permissionModule: 'news_events',
    permissionType: 'r_screen',
    element: <NewsEvenetList />
  },
  {
    path: 'newsevents/add',
    checkAccess: true,
    permissionModule: 'news_events',
    permissionType: 'r_create',
    element: <NewsEvenetAdd />
  },
  {
    path: 'newsevents/edit/:id',
    checkAccess: true,
    permissionModule: 'news_events',
    permissionType: 'r_update',
    element: <NewsEvenetAdd />
  },
  //Maan
  {
    path: 'tests_packages',
    checkAccess: true,
    permissionModule: 'tests_packages',
    permissionType: 'r_screen',
    element: <Packages />
  },
  {
    path: 'membership_cards',
    checkAccess: true,
    permissionModule: 'membership_cards',
    permissionType: 'r_screen',
    element: <MembershipCardList />
  },
  {
    path: 'membership_cards/:id',
    checkAccess: true,
    permissionModule: 'membership_cards',
    permissionType: 'r_view',
    element: <MembershipCardDetails />
  },
  {
    path: 'settings',
    checkAccess: true,
    permissionModule: 'app_settings',
    permissionType: 'r_update',
    element: <AppSetting />
  },
  {
    path: 'user_prescriptions',
    checkAccess: true,
    permissionModule: 'user_prescriptions',
    permissionType: 'r_screen',
    element: <PrescriptionList />
  },
  {
    path: 'create_booking/:id/:user_id',
    checkAccess: true,
    permissionModule: 'bookings',
    permissionType: 'r_create',
    element: <BookingsAdd />
  },
  {
    path: 'change_password',
    checkAccess: false,
    permissionModule: 'users',
    permissionType: 'r_update',
    element: <ChangePassword />
  },
  {
    path: 'profile',
    checkAccess: false,
    permissionModule: 'users',
    permissionType: 'r_update',
    element: <Profile />
  },
  {
    path: 'notifications',
    checkAccess: true,
    permissionModule: 'user_notifications',
    permissionType: 'r_screen',
    element: <Notification />
  },
  {
    path: 'faq_categories',
    checkAccess: true,
    permissionModule: 'faq_category',
    permissionType: 'r_screen',
    element: <FaqCategoryList />
  },
  {
    path: 'faqs',
    checkAccess: true,
    permissionModule: 'faqs',
    permissionType: 'r_screen',
    element: <FaqList />
  },
  {
    path: 'contents',
    checkAccess: true,
    permissionModule: 'contents',
    permissionType: 'r_screen',
    element: <ContentList />
  },
  {
    path: 'content/:slug',
    checkAccess: true,
    permissionModule: 'contents',
    permissionType: 'r_update',
    element: <ContentEdit />
  },
  {
    path: 'whychooseblal',
    checkAccess: true,
    permissionModule: 'core_values',
    permissionType: 'r_screen',
    element: <WhyChooseBlal />
  },
  //End Maan

  {
    path: 'bookings',
    checkAccess: true,
    permissionModule: 'bookings',
    permissionType: 'r_screen',
    element: <BookingsList />
  },
  {
    path: 'bookings/details/:booking_id',
    checkAccess: true,
    permissionModule: 'bookings',
    permissionType: 'r_view',
    element: <BookingsDetails />
  },
  {
    path: 'booking_earnings',
    checkAccess: true,
    permissionModule: 'booking_earnings',
    permissionType: 'r_screen',
    element: <BookingEarnings />
  },

  {
    path: 'user_ratings',
    checkAccess: true,
    permissionModule: 'user_ratings',
    permissionType: 'r_screen',
    element: <UserRatingsList />
  },
  {
    path: 'need_helps',
    checkAccess: true,
    permissionModule: 'user_ratings',
    permissionType: 'r_screen',
    element: <NeedHelpList />
  },
  {
    path: 'coupon_revenues',
    checkAccess: true,
    permissionModule: 'coupon_revenues',
    permissionType: 'r_screen',
    element: <RevenueList />
  },
  {
    path: 'logs',
    checkAccess: true,
    permissionModule: 'logs',
    permissionType: 'r_screen',
    element: <Logs />
  },
  {
    path: 'life_at_blal',
    checkAccess: true,
    permissionModule: 'life_at_blal',
    permissionType: 'r_screen',
    element: <LifeAtBlal />
  },
  {
    path: 'core_members',
    checkAccess: true,
    permissionModule: 'core_members',
    permissionType: 'r_screen',
    element: <TeamMembers />
  },
  {
    path: 'cities/details',
    checkAccess: true,
    permissionModule: 'cities',
    permissionType: 'r_screen',
    element: <CityDetails />
  }
];
