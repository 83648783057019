import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ErrorResponse from '../../ErrorResponse';
import helper from '../../../constants/helper';
import apiPath from '../../../constants/apiPath';
import useSession from 'src/hooks/useSession';

export default function LoginForm(props) {
  const [showPassword, setShowPassword] = useState(false);
  const [errorResponse, setErrorResponse] = useState('');
  const session = useSession();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const email = localStorage.getItem('remember_email')
    ? localStorage.getItem('remember_email')
    : '';

  const password = localStorage.getItem('remember_password')
    ? localStorage.getItem('remember_password')
    : '';

  const remember = localStorage.getItem('remember') ? localStorage.getItem('remember') : false;

  const formik = useFormik({
    initialValues: {
      email: email,
      password: password,
      remember: remember
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      handleToggle();
      try {
        const res = await helper.post(values, apiPath.adminLogin);

        if (res.status === 200) {
          if (!res.data.success) {
            setErrorResponse(res.data.message);
            setTimeout(() => setErrorResponse(''), 5000);
          } else {
            if (values.remember) {
              localStorage.setItem('remember', values.remember);
              localStorage.setItem('remember_email', values.email);
              localStorage.setItem('remember_password', values.password);
            } else {
              if (localStorage.getItem('remember_email')) {
                localStorage.removeItem('remember_email');
              }
              if (localStorage.getItem('remember')) {
                localStorage.removeItem('remember');
              }
              if (localStorage.getItem('remember_password')) {
                localStorage.removeItem('remember_password');
              }
            }
            session.setSession({ cookieName: "token", data: res.data.data.token });
            session.setSession({ cookieName: "profile", data: JSON.stringify(res.data.data.user) });
          }
        }
      } catch (e) {
        setErrorResponse('');
        setTimeout(() => setErrorResponse(''), 5000);
        console.log(e);
      }
      handleClose();
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  if (session.isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <FormikProvider value={formik}>
      {errorResponse && <ErrorResponse msg={errorResponse} />}
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            required
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            required
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="/forgot-password">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
