import React from 'react';
import { useState } from 'react';
import {
  Card,
  Grid,
  Stack,
  Container,
  Typography,
  Breadcrumbs,
  Link
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import Page from '../../components/Page';
import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';
import 'date-fns';
import moment from 'moment';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { ToastContainer, toast } from 'react-toastify';
import useSession from 'src/hooks/useSession';

export default function Details(props) {
  const [loadingDisable, setLoadingDisable] = useState(false);
  const [oldpassword, setOldpassword] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [formError, setFormError] = useState({});

  const session = useSession();

  const handleOnSubmit = async (event) => {
    try {
      event.preventDefault();
      setLoadingDisable(true);
      const response = await helper.post(
        { oldpassword: oldpassword, password: password, cpassword: confirmPassword },
        apiPath.update_password,
        session
      );

      if (response.status === 200) {
        setLoadingDisable(false);
        if (response.data.status === 422) {
          setFormError(response.data.errors);
        }
        if (response.data.status === 200) {
          setFormError({});
          toast.success(response.data.message);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9)
  ];
  console.log('formError', formError);
  return (
    <Page title="Settings | Dr. B. Lal">
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Container maxWidth={true}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography variant="h4" gutterBottom>
              Change Password
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/">
                Dashboard
              </Link>
              <Typography color="text.primary">Change Password</Typography>
            </Breadcrumbs>
          </Grid>
        </Stack>

        <Card>
          <CardHeader title="Change Password" />
          <CardContent>
            <Form onSubmit={handleOnSubmit} title="Edit Settings | Dr. B. Lal">
              <Grid container spacing={3}>
                <Grid item xs={6} md={6}>
                  <Controls.Input
                    type="password"
                    name="oldpassword"
                    fullWidth
                    label="Old Password"
                    value={oldpassword}
                    error={formError?.oldpassword}
                    onChange={(e) => {
                      setOldpassword(e.target.value);
                    }}
                  />
                  <Controls.Input
                    type="password"
                    name="password"
                    fullWidth
                    label="New Password"
                    value={password}
                    error={formError?.password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <Controls.Input
                    type="password"
                    name="confirm"
                    fullWidth
                    label="Confirm Password"
                    value={confirmPassword}
                    error={formError?.cpassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />

                  <LoadingButton
                    size="medium"
                    type="submit"
                    variant="contained"
                    endIcon
                    disabled={loadingDisable}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
