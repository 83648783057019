import React from 'react';
import { useState } from 'react';
import {
    Grid,
    Stack,
    Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';
import CChipInput from 'src/components/cChipInput';
import useSession from 'src/hooks/useSession';

export default function Add(props) {
    const session = useSession();
    const [slug, setSlug] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [citiesFormError, setCitiesFormError] = useState({});
    const [loadingDisable, setLoadingDisable] = useState(false);
    const [keywords, setKeywords] = useState([]);
    const [cityName, setCityName] = useState('')
    const [cityId, setCityId] = useState(null)
    const [panelId, setPanelId] = useState(null)
    const [panelName, setPanelName] = useState('')
    const [latitude, setLatitude] = useState(null)
    const [longitude, setLongitude] = useState(null)
    const [queryName, setQueryName] = useState('')

    const handleSubmit = async (e) => {
        try {
            setLoadingDisable(true);
            e.preventDefault();
            let data = {
                slug: slug,
                metaTitle: metaTitle,
                metaDescription: metaDescription,
                keywords: keywords,
                cityName,
                cityId,
                panelId,
                panelName,
                latitude,
                longitude,
                queryName
            };
            let response = await helper.post(data, `/${apiPath.add_city}`, session);
            if (response && response.status === 200 && response.data) {
                setLoadingDisable(false);
                if (response.data.status === 200) {
                    props.setOpenPopup(false)
                    props.fetchCityList()
                    props.toast.success(response.data.message)
                }
                else if (response.data.status === 422) {
                    setCitiesFormError(response.data.errors);
                } else {
                    props.toast.error("Something went wrong!")
                }
            }
        } catch (err) {
            console.error(err)
        } finally {
            setLoadingDisable(false)
        }
    };

    return (
        <Form onSubmit={handleSubmit} title="Add Cities | Dr. B. Lal">
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Stack>
                        <Controls.Input
                            name="cityname"
                            fullWidth
                            label="CityName*"
                            value={cityName}
                            onChange={(e) => setCityName(e.target.value)}
                            error={citiesFormError?.cityname}
                        />
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Controls.Input
                                name="cityid"
                                fullWidth
                                label="CityId*"
                                value={cityId}
                                type="number"
                                onChange={(e) => setCityId(e.target.value)}
                                error={citiesFormError?.cityid}
                            />
                            <Controls.Input
                                name="panelid"
                                fullWidth
                                label="PanelId*"
                                value={panelId}
                                type="number"
                                onChange={(e) => setPanelId(e.target.value)}
                                error={citiesFormError?.panelid}
                            />
                        </Stack>
                        <Controls.Input
                            name="panelname"
                            fullWidth
                            label="Panel Name*"
                            value={panelName}
                            onChange={(e) => setPanelName(e.target.value)}
                            error={citiesFormError?.panelname}
                        />
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Controls.Input
                                name="latitude"
                                fullWidth
                                label="Latitude*"
                                value={latitude}
                                type="number"
                                onChange={(e) => setLatitude(e.target.value)}
                                error={citiesFormError?.latitude}
                            />
                            <Controls.Input
                                name="longitude"
                                fullWidth
                                label="Longitude*"
                                value={longitude}
                                type="number"
                                onChange={(e) => setLongitude(e.target.value)}
                                error={citiesFormError?.longitude}
                            />
                        </Stack>
                        <Controls.Input
                            name="queryname"
                            fullWidth
                            label="Query CityName*"
                            value={queryName}
                            onChange={(e) => setQueryName(e.target.value)}
                            error={citiesFormError?.queryname}
                        />
                        <Controls.Input
                            name="slug"
                            fullWidth
                            label="Meta Url*"
                            value={slug}
                            onChange={(e) => setSlug(e.target.value)}
                            error={citiesFormError?.slug}
                        />

                        <Controls.Input
                            name="metaTitle"
                            fullWidth
                            label="Meta Title*"
                            value={metaTitle}
                            onChange={(e) => setMetaTitle(e.target.value)}
                            error={citiesFormError?.metaTitle}
                            helperText={citiesFormError?.metaTitle}
                        />

                        <Controls.Input
                            name="metaDescription"
                            fullWidth
                            label="Meta Description*"
                            value={metaDescription}
                            onChange={(e) => setMetaDescription(e.target.value)}
                            error={citiesFormError?.metaDescription}
                            helperText={citiesFormError?.metaDescription}
                        />

                        <CChipInput
                            required={true}
                            id="keywords"
                            name="keywords"
                            label="Keywords*"
                            fullWidth
                            onChange={(e, value) => {
                                setKeywords(value);
                            }}
                            value={keywords}
                            error={citiesFormError?.keywords}
                            helperText={citiesFormError?.keywords}
                        />

                        <Box mt={2}>
                            <LoadingButton
                                size="medium"
                                type="submit"
                                variant="contained"
                                endIcon
                                disabled={loadingDisable}
                            >
                                Submit
                            </LoadingButton>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </Form>
    );
}
