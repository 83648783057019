import React from 'react';
import { useState, useEffect } from 'react';
import {
  Card,
  Paper,
  Grid,
  Stack,
  Container,
  Typography,
  Breadcrumbs,
  Link,
  Button,
  CircularProgress
} from '@mui/material';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import Page from '../../components/Page';
import 'date-fns';
import moment from 'moment';
import useTable from 'src/components/useTable';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Popup from 'src/components/Popup';
import { Backdrop } from '@mui/material';
import UserAdd from './Add';
import { ToastContainer, toast } from 'react-toastify';
import AddressModel from './AddressModel';
import MembershipCard from './MembershipCardModel';
import useSession from 'src/hooks/useSession';

export default function Details(props) {
  const { user_id } = useParams();
  const [usersDetails, setUserDetails] = useState({});
  const [userAddress, setUserAddress] = useState([]);
  const [membershipCards, setMemershipCards] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [addressPopup, setAddressPopup] = useState(false);
  const [membershipPopup, setMembershipPopup] = useState(false);
  const [allAddress, setAllAddress] = useState([]);
  let [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  //https://lims.blallab.com:443/LISWebAPI/getMembershipCardDetailsByMobileNo?MobileNo=7414059605

  const [familyMembersDetails, setFamilyMembersDetails] = useState([]);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const session = useSession();

  const getMembershipCards = async (phone_number) => {
    try {
      const res = await helper.postb(
        `/getMembershipCardDetailsByMobileNo?MobileNo=${phone_number}`
      );

      if (res?.status === 200) {
        if (res?.data?.status_Code === 200) {
          setMemershipCards(res?.data?.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getUserDetails = async () => {
    try {
      const res = await helper.get(`${apiPath.user_details}` + user_id, session);
      if (res?.status === 200) {
        setUserDetails(res?.data?.data);
        setUserAddress(res?.data?.data?.user_addresses);
        getMembershipCards(res?.data?.data?.phone_number);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getUserFamilyMembers = async () => {
    try {
      setLoading(true);
      const res = await helper.getQueryString(
        `${apiPath.user_family_members}`,
        { user_id: user_id, phone_number: usersDetails?.phone_number },
        session
      );
      setFamilyMembersDetails(res?.data?.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getAllUserAddress = async () => {
    try {
      setLoading(true);
      const res = await helper.getQueryString(
        `${apiPath.get_address}`,
        { user_id: user_id },
        session
      );
      setAllAddress(res?.data?.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  const headeCells = [
    { id: 'field', label: 'Field' },
    { id: 'Data', label: 'data' }
  ];

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    usersDetails,
    headeCells,
    filterFn
  );

  useEffect(() => {
    getUserDetails();
    getAllUserAddress();
  }, []);

  useEffect(() => {
    if (usersDetails && Object.keys(usersDetails)?.length) {
      getUserFamilyMembers();
    }
  }, [usersDetails])

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9)
  ];

  const addorEdit = async (data, id) => {
    setLoading(true);
    try {
      const response = await helper.post(data, apiPath.add_family_members, session);
      if (response.data.success == true) {
        getUserFamilyMembers();
        setOpenPopup(false);
        toast.success(response?.data?.message);
      } else {
        setLoading(false);
        toast.error(response?.data?.message);
        return response;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Page title="Add User | Dr. B. Lal">
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Backdrop
        sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={true}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography variant="h4" gutterBottom>
              User
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/">
                Dashboard
              </Link>
              <Link underline="hover" color="inherit" component={RouterLink} to="/users">
                Users
              </Link>
              {<Typography color="text.primary">User Details</Typography>}
            </Breadcrumbs>
          </Grid>
        </Stack>

        <Card>
          <CardHeader title="Personal Details" />
          <CardContent>
            {usersDetails !== undefined && usersDetails !== '' && usersDetails?.id !== '' ? (
              <TableContainer component={Paper}>
                <Table className={'table'} aria-label="simple table">
                  <TableBody>
                    {/* {rows.map((row) => (
                              <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                  {row.name}
                                </TableCell>
                                <TableCell align="left">{row.calories}</TableCell>
                              </TableRow>
                            ))} */}
                    <TableRow key="FullNmae">
                      <TableCell component="th" scope="row">
                        Name
                      </TableCell>
                      <TableCell align="left">{usersDetails?.fullname}</TableCell>
                    </TableRow>
                    <TableRow key="dob">
                      <TableCell component="th" scope="row">
                        DOB
                      </TableCell>
                      <TableCell align="left">{moment(usersDetails?.dob).format('L')}</TableCell>
                    </TableRow>
                    <TableRow key="gender">
                      <TableCell component="th" scope="row">
                        Gender
                      </TableCell>
                      <TableCell align="left">{usersDetails?.gender}</TableCell>
                    </TableRow>
                    <TableRow key="phone">
                      <TableCell component="th" scope="row">
                        Phone No
                      </TableCell>
                      <TableCell align="left">{usersDetails?.phone_number}</TableCell>
                    </TableRow>
                    <TableRow key="email">
                      <TableCell component="th" scope="row">
                        Email
                      </TableCell>
                      <TableCell align="left">{usersDetails?.email}</TableCell>
                    </TableRow>
                    <TableRow key="location">
                      <TableCell component="th" scope="row">
                        Location
                      </TableCell>
                      <TableCell align="left">
                        {userAddress?.length > 0 && (
                          <Typography color="text.primary">
                            {`${userAddress[0]?.type} - ${userAddress[0]?.number}, ${userAddress[0]?.area1}, ${userAddress[0]?.area2}, ${userAddress[0]?.city} (${userAddress[0]?.state}) ,${userAddress[0]?.pincode}`}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow key="totalBookings">
                      <TableCell component="th" scope="row">
                        Total Bookings
                      </TableCell>
                      <TableCell align="left">6</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(`/create_booking/2/${user_id}`, { state: { addBooking: true } });
                  }}
                >
                  Add Booking
                </Button>
              </TableContainer>
            ) : null}
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardHeader title="Family Members Details" action={
            <Button
              variant="outlined"
              onClick={() => {
                setOpenPopup(true);
              }}
            >
              Add Family Members
            </Button>
          } />

          <CardContent>
            {familyMembersDetails !== undefined &&
              familyMembersDetails !== '' &&
              familyMembersDetails?.id !== '' ? (
              <TableContainer component={Paper}>
                <Table className={"table"} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Relation</TableCell>
                      <TableCell>Age</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {familyMembersDetails !== undefined &&
                      familyMembersDetails !== '' &&
                      familyMembersDetails?.length > 0 &&
                      familyMembersDetails?.map((value, index) => (
                        <TableRow key={value?.id}>
                          <TableCell>{value?.fullname}</TableCell>
                          <TableCell>{value?.relation}</TableCell>
                          <TableCell>{value?.age}</TableCell>
                          <TableCell>{value?.email}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardHeader title="Membership Cards" action={
            <Button
              variant="outlined"
              onClick={() => {
                setMembershipPopup(true);
              }}
            >
              Add Membership Card
            </Button>
          } />
          <CardContent>
            {membershipCards?.length > 0 && (
              <TableContainer component={Paper}>
                <Table className={'table'} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Card No</TableCell>
                      <TableCell>Membership Card Name</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>ValidFrom</TableCell>
                      <TableCell>ValidTo</TableCell>
                      <TableCell>Discount in (%)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {membershipCards.map((value, index) => (
                      <TableRow key={value?.id}>
                        <TableCell>{value?.CardNo}</TableCell>
                        <TableCell>{value?.MembershipCardName}</TableCell>
                        <TableCell>{(value?.MemberDetailsListModel?.map((user) => user?.PatientName))?.join(", ")}</TableCell>
                        <TableCell>
                          {moment(value?.ValidFrom, 'DD/MM/YYYY').format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell>
                          {moment(value?.ValidTo, 'DD/MM/YYYY').format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell>{value?.discountinpercentage}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardHeader title="User Addresses" action={
            <Button
              variant="outlined"
              onClick={() => {
                setAddressPopup(true);
              }}
            >
              Add New Address
            </Button>
          } />
          <CardContent>
            {allAddress?.length > 0 && (
              <TableContainer component={Paper}>
                <Table className={'table'} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Address Type</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Latitude</TableCell>
                      <TableCell>Longitude</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allAddress.map((value) => (
                      <TableRow key={value?.id}>
                        <TableCell>{value?.type ? value?.type : "-"}</TableCell>
                        <TableCell>{`${value?.number ? `${value?.number},` : ""} ${value?.area1 ? `${value?.area1},` : ""} ${value?.area2 ? `${value?.area2},` : ""} ${value?.city ? `${value?.city},` : ""} ${value?.state ? `${value?.state},` : ""} ${value?.pincode ? `${value?.pincode},` : ""}`}</TableCell>
                        <TableCell>{value?.latitude ? value?.latitude : "-"}</TableCell>
                        <TableCell>
                          {value?.longitude ? value?.longitude : "-"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        </Card>
      </Container>
      <Popup title={'Add Family Member'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <UserAdd
          addorEdit={addorEdit}
          addFamilyMember={true}
          phoneNumber={usersDetails?.phone_number}
          userId={usersDetails?.id}
        />
      </Popup>
      <Popup title={'Add Address'} openPopup={addressPopup} setOpenPopup={setAddressPopup}>
        <AddressModel setAddressPopup={setAddressPopup} userId={usersDetails?.id} getAllUserAddress={getAllUserAddress} />
      </Popup>
      <Popup title={'Add Membership Card'} openPopup={membershipPopup} setOpenPopup={setMembershipPopup}>
        <MembershipCard setMembershipPopup={setMembershipPopup} userId={usersDetails?.id} session={session} familyMembersDetails={familyMembersDetails} mobileNumber={usersDetails?.phone_number} />
      </Popup>
    </Page>
  );
}
