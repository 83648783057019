import React from 'react';
import { useState, useEffect } from 'react';
import {
  Card,
  Paper,
  Grid,
  Stack,
  Container,
  Typography,
  Breadcrumbs,
  Link
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import Page from '../../components/Page';
import useTable from 'src/components/useTable';
import { useLocation } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import useSession from 'src/hooks/useSession';


export default function Details(props) {
  const location = useLocation();
  const [cityDetails, setCityDetails] = useState([]);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const session = useSession();

  const getCityDetails = async () => {
    try {
      console.log(location.state.id, "location")
      const res = await helper.get(`${apiPath.get_all_cities}?cityId=${location.state.id}`, session);
      if (res?.status === 200 && res?.data?.status === 200) {
        setCityDetails(res?.data?.data[0]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const headeCells = [
    { id: 'field', label: 'Field' },
    { id: 'Data', label: 'data' }
  ];

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    cityDetails,
    headeCells,
    filterFn
  );

  useEffect(() => {
    getCityDetails();
  }, []);

  return (
    <Page title="Add User | Dr. B. Lal">
      <Container maxWidth={true}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography variant="h4" gutterBottom>
              City Details
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/">
                Dashboard
              </Link>
              <Link underline="hover" color="inherit" component={RouterLink} to="/cities">
                Cities
              </Link>
              {<Typography color="text.primary">City Details</Typography>}
            </Breadcrumbs>
          </Grid>
        </Stack>

        <Card>
          <CardHeader title="City Details" />
          <CardContent>
            {cityDetails !== undefined && cityDetails !== '' && cityDetails.CityId !== '' ? (
              <TableContainer component={Paper}>
                <Table className={'table'} aria-label="simple table">
                  <TableBody>
                    <TableRow key="CityName">
                      <TableCell component="th" scope="row">
                        City Name
                      </TableCell>
                      <TableCell align="left">{cityDetails?.CityName}</TableCell>
                    </TableRow>
                    <TableRow key="query">
                      <TableCell component="th" scope="row">
                        City Query Name
                      </TableCell>
                      <TableCell align="left">{cityDetails?.QueryCityName}</TableCell>
                    </TableRow>
                    <TableRow key="panelid">
                      <TableCell component="th" scope="row">
                        Panel ID
                      </TableCell>
                      <TableCell align="left">{cityDetails?.Panel_ID}</TableCell>
                    </TableRow>
                    <TableRow key="panelname">
                      <TableCell component="th" scope="row">
                        Panel Name
                      </TableCell>
                      <TableCell align="left">{cityDetails?.PanelName}</TableCell>
                    </TableRow>
                    <TableRow key="longitude">
                      <TableCell component="th" scope="row">
                        Longitude
                      </TableCell>
                      <TableCell align="left">
                        {cityDetails?.Longitude}
                      </TableCell>
                    </TableRow>
                    <TableRow key="latitude">
                      <TableCell component="th" scope="row">
                        Latitude
                      </TableCell>
                      <TableCell align="left">
                        {cityDetails?.Latitude}
                      </TableCell>
                    </TableRow>
                    <TableRow key="status">
                      <TableCell component="th" scope="row">
                        status
                      </TableCell>
                      <TableCell align="left">{cityDetails?.status}</TableCell>
                    </TableRow>
                    <TableRow key="slug">
                      <TableCell component="th" scope="row">
                        slug
                      </TableCell>
                      <TableCell align="left">{cityDetails?.slug}</TableCell>
                    </TableRow>
                    <TableRow key="metaTitle">
                      <TableCell component="th" scope="row">
                        metaTitle
                      </TableCell>
                      <TableCell align="left">{cityDetails?.metaTitle}</TableCell>
                    </TableRow>
                    <TableRow key="metaDescription">
                      <TableCell component="th" scope="row">
                        metaDescription
                      </TableCell>
                      <TableCell align="left">{cityDetails?.metaDescription}</TableCell>
                    </TableRow>
                    <TableRow key="keywords">
                      <TableCell component="th" scope="row">
                        keywords
                      </TableCell>
                      <TableCell align="left">{cityDetails?.keywords}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </CardContent>
        </Card>
        <br />
      </Container>
    </Page>
  );
}
