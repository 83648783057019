import React from 'react'
import { Button } from '@mui/material';

export default function ActionButton(props) {
    const { color, children, onClick } = props;

    return (
        <Button
            className={`${"rootListCoupon"} ${color === 'primary' ? 'primary': 'secondary'}`}
            onClick={onClick}>
            {children}
        </Button>
    )
}