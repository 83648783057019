import { useRef, useState, useEffect } from "react";

function useDragAndDrop({ coreMembersData, handleBulkUpdate }) {
    const [srcIdx, setSrcIdx] = useState()
    const [dragging, setDragging] = useState(false);
    const dragItemNode = useRef();
    const [teamMembers, setTeamMembers] = useState([...coreMembersData])

    useEffect(() => {
        setTeamMembers(coreMembersData)
    }, [coreMembersData])

    const handleDragEnd = () => {
        setDragging(false);
        dragItemNode.current.removeEventListener("dragend", handleDragEnd);
        dragItemNode.current = null;

        handleBulkUpdate(teamMembers)

    };

    /**
     *  handle start dragging
     */
    const handleDragStart = (e, dragIdx) => {
        setSrcIdx(dragIdx)
        dragItemNode.current = e.target
        dragItemNode.current.addEventListener("dragend", handleDragEnd);
        // set dragging true asynchronously to persist drag card style
        setTimeout(() => {
            setDragging(true);
        }, 0);
    };

    /**
     * handle dropping file at target index
     */
    const handleDragEnter = (e, targetIdx) => {
        if (dragItemNode.current !== e.target) {
            let list = [...teamMembers];

            // swapping member card items
            const swap = list[srcIdx];
            list[srcIdx] = list[targetIdx];
            list[targetIdx] = swap;
            // swapping priorities of members
            const swapPriority = list[srcIdx].priority;
            list[srcIdx].priority = list[targetIdx].priority;
            list[targetIdx].priority = swapPriority;

            setTeamMembers(list);
            setSrcIdx(targetIdx)
        }
    };
    return {
        handleDragEnd,
        handleDragEnter,
        handleDragStart,
        dragging,
        teamMembers
    }
}

export default useDragAndDrop