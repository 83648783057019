import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Stack,
  Grid,
  Breadcrumbs,
  Link,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import Page from '../../components/Page';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import { Search } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop } from '@mui/material';
import useSession from 'src/hooks/useSession';

export default function NewsEventList() {
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const session = useSession();
  let [loading, setLoading] = useState(false);

  const getBlogsTableData = async () => {
    try {
      const res = await helper.get(`${apiPath.news_event_list}`, session);
      if (res.status === 200) {
        setRecords(res.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const headeCells = [
    { id: 'title', label: 'Title', disableSorting: true },
    { id: 'file_type', label: 'File Type', disableSorting: true },
    { id: 'actions', label: 'Actions', disableSorting: true }
  ];

  useEffect(() => {
    getBlogsTableData();
  }, []);

  const [searchKeyword, setSearchKeyword] = useState('');
  const handleSearch = (e) => {
    let target = e.target;
    setSearchKeyword(target.value);
    setFilterFn({
      fn: (items) => {
        return target.value == ''
          ? items
          : items.filter((x) => x.title.toLowerCase().includes(target.value));
      }
    });
  };

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    records,
    headeCells,
    filterFn,
    searchKeyword
  );

  const handleBlogDelete = async (item) => {
    try {
      let res = await helper.get(`${apiPath.news_event_delete}/${item.id}`, session);
      if (res.status === 200) {
        getBlogsTableData();
        toast.success(res.data.api_name);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Page title={'News Event List | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                News & Event
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">News & Event</Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <Toolbar>
            <Controls.Input
              label="Search News & Event"
              className={'searchInput'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
              onChange={handleSearch}
            />

            <Controls.Button
              text="Add News & Event"
              variant="outlined"
              startIcon={<AddIcon />}
              className={'newButton'}
              onClick={() => {
                navigate('/newsevents/add');
              }}
            />
          </Toolbar>
          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.file_type}</TableCell>
                  <TableCell>
                    <Controls.ActionButton
                      color="primary"
                      onClick={() => {
                        navigate(`/newsevents/edit/${item.id}`);
                      }}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </Controls.ActionButton>

                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        handleBlogDelete(item);
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>
    </>
  );
}
