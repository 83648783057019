import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import Page from '../../components/Page';
import { Link as RouterLink } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Popup from 'src/components/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import HealthBulletinAdd from './Add';
import {
  Stack,
  Paper,
  Grid,
  Box,
  Breadcrumbs,
  Link,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import { Backdrop } from '@mui/material';
import moment from 'moment';
import useSession from 'src/hooks/useSession';

List.propTypes = {
  sx: PropTypes.object
};

export default function List({ sx }) {
  const [healthBulletinData, setHealthBulletinData] = useState([]);
  const session = useSession();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [addHealthBulletin, setAddHealthBulletin] = useState(false);
  const [editHealthBulletin, setEditHealthBulletin] = useState(false);
  const [healthBulletinImage, setHealthBulletinImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const getHealthBulletinData = async () => {
    try {
      const res = await helper.get(`${apiPath.get_health_bulletin_list}`, session);

      if (res.status === 200) {
        if (res.data.success === true) {
            setHealthBulletinData(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [searchKeyword, setSearchKeyword] = useState('');
  const handleSearch = (e) => {
    let target = e.target;
    setSearchKeyword(target.value);
    setFilterFn({
      fn: (items) => {
        return target.value == ''
          ? items
          : items.filter((x) => x.title.toLowerCase().includes(target.value));
      }
    });
  };

  const headeCellsHealthBulletin = [
    { id: 'image', label: 'Image', disableSorting: true },
    { id: 'title', label: 'Title', disableSorting: true },
    { id: 'fileUrl', label: 'File Url', disableSorting: true },
    { id: 'status', label: 'Status', disableSorting: true },
    { id: 'date', label: 'Created At', disableSorting: true },
    { id: 'action', label: 'Action', disableSorting: true }
  ];

  useEffect(() => {
    getHealthBulletinData();
  }, []);

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    healthBulletinData,
    headeCellsHealthBulletin,
    filterFn,
    searchKeyword
  );

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const addorEdit = async (data, id) => {
    setLoading(true);
    try {
      if (recordForEdit == null) {
        const res = await helper.post(data, apiPath.create_health_bulletin, session);
        if (res.data.success == true) {
          getHealthBulletinData();
          setOpenPopup(false);
          toast.success(res.data.message);
        } else {
          setLoading(false);
          return res;
        }
      } else {
        const res = await helper.put(data, `${apiPath.update_health_bulletin}/${id}`, session);
        if (res.status === 200) {
          setLoading(false);
          if (res.data.success == true) {
            getHealthBulletinData();
            setOpenPopup(false);
            toast.success(res.data.message);
          } else {
            return res;
          }
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  };

  const handleBlogView = async (item) => {
    setHealthBulletinImage(item.image);
    setEditHealthBulletin(false);
    setAddHealthBulletin(false);
    setOpenPopup(true);
  };

  const handleHealthBulletinDelete = async (item) => {
    try {
      let res = await helper.delete(`${apiPath.delete_health_bulletin}/${item.id}`, session);
      if (res.status === 200) {
        getHealthBulletinData();
        toast.success(res.data.message);
      }
    } catch (e) {
      toast.error('Error', e);
    }
  };

  return (
    <>
      <Page title={'Banner List | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Health Bulletin
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">All Health Bulletin </Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <Toolbar>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Controls.Input
                style={{ width: '70%' }}
                label="Search Health Bulletin"
                className={'searchInput'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
                onChange={handleSearch}
              />

              <Controls.Button
                text="Add New Health Bulletin"
                variant="outlined"
                startIcon={<AddIcon />}
                className={'newButton'}
                onClick={() => {
                  setOpenPopup(true);
                  setRecordForEdit(null);
                  setAddHealthBulletin(true);
                }}
              />
            </Stack>
          </Toolbar>

          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <Box
                      component="img"
                      onClick={() => {
                        handleBlogView(item);
                      }}
                      src={item.image}
                      sx={{ width: 100, height: 50, ...sx }}
                    />
                  </TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.fileUrl}</TableCell>
                  <TableCell>{item.status ? 'Active' : 'Inactive'}</TableCell>
                  <TableCell>{moment(item.created_at).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>
                    <Controls.ActionButton
                      color="primary"
                      onClick={() => {
                        openInPopup(item);
                        setEditHealthBulletin(true);
                      }}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </Controls.ActionButton>

                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        handleHealthBulletinDelete(item);
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>
      <Popup title={'Health Bulletin'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
        {addHealthBulletin || editHealthBulletin ? (
          <HealthBulletinAdd title="Edit Health Bulletin" recordForEdit={recordForEdit} addorEdit={addorEdit} />
        ) : (
          <Paper variant="outlined">
            <img src={healthBulletinImage} alt="login" />
          </Paper>
        )}
      </Popup>
    </>
  );
}
