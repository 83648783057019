import React, { useState, useEffect } from 'react';
import { Container, CircularProgress, Grid, Autocomplete, TextField, Button } from "@mui/material";
import { Backdrop } from '@mui/material';
import Page from "../../components/Page";
import helper from 'src/constants/helper';
import apiPath from 'src/constants/apiPath';
import { ToastContainer, toast } from 'react-toastify';

const MembershipCard = (props) => {
    const [loading, setLoading] = useState(false);
    const [allMembershipCard, setAllMembershipCard] = useState([]);
    const [cardDetail, setCardDetail] = useState({});
    const [patientDetail, setPatientDetail] = useState({});
    const [dependentPatient, setDependentPatient] = useState([]);
    const [familyMemberDetails, setFamilyMemberDetails] = useState([]);
    const [senior, setSenior] = useState(false);

    const getAllMembershipCard = async () => {
        try {
            setLoading(true);
            const response = await helper.postb(`/getMembershipCardList?CityId=78`);
            if (response && response.status === 200) {
                setLoading(false);
                setAllMembershipCard(response.data.data);
            } else {
                setLoading(false);
                console.log("Error in fetching membership card");
            }
        } catch (err) {
            console.log(err);
        }
    }

    const getUserFamilyMembers = async () => {
        try {
            setLoading(true);
            let url = apiPath.user_family_members
            if (senior) {
                url = `${apiPath.user_family_members}?senior=${senior}`
            }
            const res = await helper.getQueryString(
                `${url}`,
                { user_id: props.userId, phone_number: props?.mobileNumber },
                props.session
            );
            setFamilyMemberDetails(res.data.data);
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const dataObj = {
                name: cardDetail.NAME,
                card_id: cardDetail.ItemID,
                mobile_number: props?.mobileNumber.toString(),
                user_id: props?.userId.toString(),
                card_detail: cardDetail,
                patient_detail: patientDetail,
                dependent_patient_detail: dependentPatient,
                patient_id: patientDetail.uhid,
                dependent_patient_id: dependentPatient.map((patient) => patient.uhid),
                card_status: "Pending"
            }
            const createData = await helper.post(dataObj, apiPath.create_membership_purchase, props.session);
            if (createData.data && createData.data.status === 200) {
                setLoading(false);
                props.setMembershipPopup(false);
                toast.success(createData.data.message);
            } else {
                toast.error(createData.data.message);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getAllMembershipCard();
    }, [])

    useEffect(() => {
        if (props && props?.userId && props?.mobileNumber) {
            getUserFamilyMembers();
        }
    }, [props])


    useEffect(() => {
        getAllMembershipCard();
    }, [senior])

    return (
        <Page title="Add Address | Dr. B. Lal">
            <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
            <Backdrop
                sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container maxWidth={true}>
                <form name="membershipForm" onSubmit={handleSubmit}>
                    <Grid container>
                        <Grid item xs={12} className={'textField'}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                value={cardDetail}
                                onChange={(event, value) => {
                                    if (value.Id === 8) {
                                        setSenior(true)
                                    } else {
                                        setSenior(false);
                                    }
                                    setCardDetail(value);
                                }}
                                options={allMembershipCard}
                                getOptionLabel={(option) => option?.NAME ? option?.NAME : ""}
                                sx={{ width: '100%' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Membership Card"
                                        required={true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} className={'textField'}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                value={patientDetail}
                                onChange={(event, value) => setPatientDetail(value)}
                                options={familyMemberDetails}
                                getOptionLabel={(option) => option?.fullname ? option?.fullname : ""}
                                sx={{ width: '100%' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Patient"
                                        required={true}
                                    />
                                )}
                            />
                        </Grid>
                        {patientDetail && patientDetail?.id ?
                            <Grid item xs={12} className={'textField'}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    value={dependentPatient}
                                    onChange={(event, value) => setDependentPatient(value)}
                                    options={familyMemberDetails?.filter((member) => member.id !== patientDetail.id)}
                                    getOptionLabel={(option) => option?.fullname ? option?.fullname : ""}
                                    sx={{ width: '100%' }}
                                    multiple
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Patient"
                                        />
                                    )}
                                />
                            </Grid>
                            : ""}
                        <Grid item xs={12} className={'textField'}>
                            <Button
                                variant="outlined"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </Page>
    )
}

export default MembershipCard;