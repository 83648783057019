import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ErrorResponse from '../../ErrorResponse';
import helper from '../../../constants/helper';
import apiPath from '../../../constants/apiPath';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useSession from 'src/hooks/useSession';

export default function ForgotPasswordForm(props) {
  const [errorResponse, setErrorResponse] = useState('');
  const session = useSession();
  const [resetLink, setResetLink] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      handleToggle();
      try {
        const res = await helper.post(values, apiPath.send_token);

        if (res.status === 200) {
          if (!res.data.success) {
            toast.error(res.data.message);
            // setErrorResponse(res.data.message);
            setTimeout(() => setErrorResponse(''), 5000);
          } else {
            setResetLink(res.data.data);
            toast.success(res.data.message);
          }
        }
      } catch (e) {
        setErrorResponse('');
        setTimeout(() => setErrorResponse(''), 5000);
        console.log(e);
      }
      handleClose();
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  if (session.isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {resetLink && (
        <Link component={RouterLink} variant="subtitle2" to={`/${resetLink}`}>
          Reset Password link as temporary?
        </Link>
      )}
      <FormikProvider value={formik}>
        {errorResponse && <ErrorResponse msg={errorResponse} />}
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              required
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}

            <Link component={RouterLink} variant="subtitle2" to="/login">
              Sign In?
            </Link>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Send
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
}
