import React from 'react';
import { useState, useEffect, useRef } from 'react';
import {
  Card,
  FormControl,
  InputLabel,
  Grid,
  Stack,
  Box,
  TextField,
  Select,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import useSession from 'src/hooks/useSession';

export default function AddCategory(props) {
  let { addorEdit, recordForEdit } = props;
  let el = useRef();
  const session = useSession();
  const [categoryId, setCategoryId] = useState('');
  const [faqCatID, setFaqCatID] = useState('');
  const [faqCategories, setBlogCategory] = useState([]);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [categoryFormError, setCategoryFormError] = useState({});
  const [loadingDisable, setLoadingDisable] = useState(false);

  const handleSubmit = async (e) => {
    setLoadingDisable(true);
    e.preventDefault();
    const response = await addorEdit(
      { category_id: faqCatID, question: question, answer: answer },
      categoryId
    );
    if (response !== undefined && response.status === 200) {
      setLoadingDisable(false);
      if (response.data.status === 422) {
        setCategoryFormError(response.data.errors);
      }
    }
  };

  const getBlogsCategoryData = async () => {
    try {
      const res = await helper.get(`${apiPath.faqCatList}`, session);
      if (res.status === 200) {
        if (res.data.success === true) {
          setBlogCategory(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getBlogsCategoryData();
  }, []);

  useEffect(() => {
    if (recordForEdit) {
      setCategoryId(recordForEdit.id);
      setFaqCatID(recordForEdit.category_id);
      setQuestion(recordForEdit.question);
      setAnswer(recordForEdit.answer);
    }
  }, ['recordForEdit']);

  return (
    <Form onSubmit={handleSubmit} title="Add Faq | Dr. B. Lal">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <FormControl>
            <InputLabel id="demo-simple-select-label">Select Category</InputLabel>

            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select Category"
              required
              value={faqCatID}
              onChange={(e) => {
                setFaqCatID(e.target.value);
              }}
            >
              {faqCategories.length > 0 &&
                faqCategories.map((e, key) => {
                  return <MenuItem value={e.id}>{e.name}</MenuItem>;
                })}
            </Select>
          </FormControl>

          <Controls.Input
            multiline={true}
            rows={3}
            name="question"
            fullWidth
            label="Question"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            required
          />

          <Controls.Input
            multiline={true}
            rows={5}
            name="answer"
            fullWidth
            label="Answer"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            required
          />

          <Box className="btn-Margin">
            <LoadingButton
              size="medium"
              type="submit"
              variant="contained"
              endIcon
              disabled={loadingDisable}
            >
              {categoryId ? 'Update' : 'Add'}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
}
