import { useState, useEffect } from 'react';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import Page from '../../components/Page';
import { Link as RouterLink } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import { Search } from '@mui/icons-material';
import Popup from 'src/components/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import UserAdd from './Add';
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

import {
  Stack,
  Grid,
  Breadcrumbs,
  Link,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress,
  Select,
  MenuItem
} from '@mui/material';

import { Backdrop } from '@mui/material';
import useSession from 'src/hooks/useSession';

export default function List() {
  let navigate = useNavigate();
  // const [filterFn, setFilterFn] = useState({ fn: (items) => {return items; } });
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const session = useSession();
  const [usersData, setBookingEarningsData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [addUser, setAddUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [userDetailsView, setUserDetailsView] = useState(false);
  const [paymentMode, setPaymentMode] = useState('all');
  const [sort, setsort] = useState('DESC');
  const [searchKeyword, setSearchKeyword] = useState('');

  let [loading, setLoading] = useState(false);

  const handleSearch = (e) => {
    setSearchKeyword(e.target.value);
  };

  const getBookingEarningsData = async () => {
    try {
      const res = await helper.get(
        `${apiPath.booking_earnings_list}?size=2000&search_keyword=${searchKeyword}&sort=${sort}&payment_mode=${paymentMode}`,
        session
      );
      if (res.status === 200) {
        if (res.data.success === true) {
          setBookingEarningsData(res.data.data.docs);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const headeCells = [
    { id: 'transactionId', label: 'Transaction ID', disableSorting: true },
    { id: 'bookingId', label: 'Booking ID', disableSorting: true },
    { id: 'modeOfPayment', label: 'Payment Mode', disableSorting: true },
    { id: 'paymentAmount', label: 'Pay Amount', disableSorting: true },
    { id: 'userName', label: 'User', disableSorting: true },
    { id: 'userMobileNumber', label: 'Contact No', disableSorting: true },
    { id: 'dateAndTime', label: 'Created At', disableSorting: true }
    // { id: 'action', label: 'Action'},
  ];

  useEffect(() => {
    getBookingEarningsData();
  }, [searchKeyword, paymentMode, sort]);

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    usersData,
    headeCells,
    filterFn,
    searchKeyword
  );

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const addorEdit = async (data, id) => {
    setLoading(true);
    try {
      if (recordForEdit == null) {
        const response = await helper.post(data, apiPath.create_user, session);
        if (response.data.success == true) {
          getBookingEarningsData();
          setOpenPopup(false);
          toast(response.data.message);
        } else {
          setLoading(false);
          return response;
        }
      } else {
        console.log('in else part');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('Error', error);
    }
  };

  const handleUserDetailsView = async (item) => {
    setUserDetailsView(item);
    setRecordForEdit(false);
    setAddUser(false);
    navigate('/users/details', { state: { id: item.id } });
  };

  const handleChangePaymentMode = (e) => {
    setPaymentMode(e.target.value);
  };

  const handleChangeSort = (e) => {
    setsort(e.target.value);
  };

  // const searchData = async () => {
  //   try {
  //     const res = await helper.getQueryString(
  //       `${apiPath.booking_earnings_list}`,
  //       { search_keyword: searchKeyword, sort: sort, payment_mode: paymentMode },
  //       session
  //     );
  //     if (res.status === 200) {
  //       setBookingEarningsData(res.data.data.docs);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // useEffect(() => {
  //   searchData();
  // }, [searchKeyword, paymentMode, sort]);

  return (
    <>
      <Page title={'Booking Earnings | Dr. B Lal'}>
        <ToastContainer bg="success" />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Booking Earnings
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">Earnings </Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <Toolbar>
            <Stack direction="row" style={{ justifyContent: 'space-between' }}>
              <Controls.Input
                style={{ width: '49%' }}
                label="Search Booking ID"
                className={'searchInput'}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
                value={searchKeyword}
                onChange={handleSearch}
              />

              <FormControl sx={{ m: 1, width: 300, marginTop: '0%' }}>
                <InputLabel id="demo-multiple-name-label">Payment Mode</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={paymentMode}
                  onChange={handleChangePaymentMode}
                  input={<OutlinedInput label="Payment Mode" />}
                  // MenuProps={MenuProps}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="online">Online</MenuItem>
                  <MenuItem value="cash">Cash</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, width: 300, marginTop: '0%' }}>
                <InputLabel id="demo-multiple-name-label">Sort</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={sort}
                  onChange={handleChangeSort}
                  input={<OutlinedInput label="Sort" />}
                  // MenuProps={MenuProps}
                >
                  <MenuItem value="ASC">Oldest - Newest</MenuItem>
                  <MenuItem value="DESC">Newest - Oldest</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Toolbar>

          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.transactions ? item.transactions : 'None'}</TableCell>
                  <TableCell>{item.LisBookId}</TableCell>
                  <TableCell>{item.payment_mode}</TableCell>
                  <TableCell>{item.final_amount}</TableCell>
                  <TableCell>{item.user.fullname}</TableCell>
                  <TableCell>{item.user.phone_number}</TableCell>
                  <TableCell>{moment(item.created_at).format('DD/MM/YYYY')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>
      <Popup title={'Users Management'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <UserAdd
          title="Edit Blog"
          addorEdit={addorEdit}
          recordForEdit={recordForEdit}
          addUser={addUser}
          userDetailsView={userDetailsView}
        />
      </Popup>
    </>
  );
}
