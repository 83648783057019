import React from 'react';
import { useState, useEffect } from 'react';
import {
  Grid,
  Stack,
  Box
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';
import useSession from 'src/hooks/useSession';

export default function Add(props) {
  let { addorEdit, recordForEdit } = props;
  const session = useSession();
  const [id, setId] = useState(null);
  const [name, setName] = useState('');
  const [pincodeFormError, setPincodeFormError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (recordForEdit) {
      setId(recordForEdit.id);
      setName(recordForEdit.name);
    }
  }, ['recordForEdit']);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let data = {
      name: name
    };

    setPincodeFormError({});
    let response = await addorEdit(data, id);
    if (response && response.data && response.data.message) {
      setPincodeFormError(response.data.message);
    }
  };

  return (
    <Form onSubmit={handleSubmit} title="Add Role | Dr. B. Lal">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Stack>
            <Controls.Input
              name="name"
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              error={pincodeFormError?.name}
            />

            <Box>
              <LoadingButton size="medium" type="submit" variant="contained" endIcon>
                Submit
              </LoadingButton>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Form>
  );
}
