import { useState, useEffect } from 'react';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import Page from '../../components/Page';
import { Link as RouterLink } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Popup from 'src/components/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import SubCategoryAdd from './Add';
import moment from 'moment';

import {
  Stack,
  Grid,
  Breadcrumbs,
  Link,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import { Backdrop } from '@mui/material';
import Switch from '@mui/material/Switch';
import useSession from 'src/hooks/useSession';

export default function List() {
  const [blogSubCategoryData, setBlogSubCategory] = useState([]);
  const session = useSession();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [addBlog, setAddBlog] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState('all');
  let [loading, setLoading] = useState(false);

  const getBlogsSubCategoryData = async () => {
    try {
      const res = await helper.get(
        `${apiPath.get_sub_category_list}?category_id=${categoryId}`,
        session
      );
      if (res.status === 200) {
        setBlogSubCategory(res.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getCategory = async () => {
    try {
      const res = await helper.get(`${apiPath.get_blog_categories}`, session);
      if (res.status === 200) {
        if (res.data.status === 200) {
          setCategories(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const headeCells = [
    { id: 'category', label: 'Category', disableSorting: true },
    { id: 'sub_category', label: 'Sub Category', disableSorting: true },
    { id: 'date', label: 'Created At', disableSorting: true },
    { id: 'status', label: 'Status', disableSorting: true },
    { id: 'action', label: 'Action', disableSorting: true }
  ];
  const [searchKeyword, setSearchKeyword] = useState('');
  const handleSearch = (e) => {
    let target = e.target;
    setSearchKeyword(target.value);
    setFilterFn({
      fn: (items) => {
        return target.value == ''
          ? items
          : items.filter((x) => x.name.toLowerCase().includes(target.value));
      }
    });
  };

  useEffect(() => {
    getBlogsSubCategoryData();
  }, [categoryId]);

  useEffect(() => {
    getCategory();
  }, []);

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    blogSubCategoryData,
    headeCells,
    filterFn,
    searchKeyword
  );

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const addorEdit = async (data, id) => {
    setLoading(true);
    try {
      if (recordForEdit == null) {
        const response = await helper.post(data, apiPath.create_subcategory, session);
        if (response.data.success == true) {
          getBlogsSubCategoryData();
          setOpenPopup(false);
          toast.success(response.data.message);
        } else {
          setLoading(false);
          return response;
        }
      } else {
        const res = await helper.put(data, `${apiPath.updtae_sub_category}/${id}`, session);
        if (res.data.success == true) {
          getBlogsSubCategoryData();
          setOpenPopup(false);
          toast.success(res.data.message);
        } else {
          setLoading(false);
          return res;
        }
      }
    } catch (error) {
      setLoading(false);
      console.log('Error', error);
    }
    setLoading(false);
  };

  const handleSubCategoryDelete = async (item) => {
    try {
      let res = await helper.delete(`${apiPath.delete_category}/${item.id}`, session);
      if (res.status === 200) {
        getBlogsSubCategoryData();
        toast.success(res.data.message);
      }
    } catch (e) {
      toast.error('Error', e);
    }
  };

  const handleStatusChange = async (item) => {
    try {
      setLoading(true);
      var data = {
        table: 'blog_categories',
        status: !item.status
      };
      const response = await helper.post(data, `${apiPath.update_status}/${item.id}`, session);
      if (response.status === 200) {
        setLoading(false);
        if (response.data.success === true) {
          getBlogsSubCategoryData();
          toast.success(response.data.message);
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      <Page title={'Sub Category List | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={true}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Sub Category
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">Sub Category</Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <Toolbar>
            <Controls.Input
              label="Search sub category"
              className={'searchInput'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
              onChange={handleSearch}
            />

            <FormControl sx={{ m: 1, width: 200, marginTop: '0%' }}>
              <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
              <Select
                style={{ marginLeft: '10px' }}
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Category"
                value={categoryId}
                onChange={(e) => {
                  setCategoryId(e.target.value);
                }}
              >
                <MenuItem value="all">All</MenuItem>
                {categories.length > 0 &&
                  categories.map((e, key) => {
                    return <MenuItem value={e.id}>{e.name}</MenuItem>;
                  })}
              </Select>
            </FormControl>

            <Controls.Button
              text="Add Sub Category"
              variant="outlined"
              startIcon={<AddIcon />}
              className={'newButton'}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
                setAddBlog(true);
              }}
            />
          </Toolbar>

          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.sub_cat_name.name}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{moment(item.created_at).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>
                    <Switch
                      checked={item.status}
                      onChange={() => {
                        handleStatusChange(item);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Controls.ActionButton
                      color="primary"
                      onClick={() => {
                        openInPopup(item);
                        setEditCategory(true);
                      }}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </Controls.ActionButton>

                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        handleSubCategoryDelete(item);
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>
      <Popup
        title={`${recordForEdit ? 'Edit' : 'Add'} Blog Sub Category`}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <SubCategoryAdd title="Edit Blog" recordForEdit={recordForEdit} addorEdit={addorEdit} />
      </Popup>
    </>
  );
}
