import React from 'react';
import { useState, useEffect } from 'react';
import {
  Card,
  FormControl,
  InputLabel,
  Grid,
  Stack,
  Box,
  TextField,
  Select,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
// import useSession from 'react-session-hook';
import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';
import useSession from 'src/hooks/useSession';

export default function Add(props) {
  let { addorEdit, recordForEdit } = props;
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [categoryFormError, setCategoryFormError] = useState({});
  const [blogCategory, setBlogCategory] = useState([]);
  const [blogCategory_id, set_blogCategory_id] = useState('');
  const [open, setOpen] = useState(false);
  const session = useSession();
  const [loadingDisable, setLoadingDisable] = useState(false);

  const handleSubmit = async (e) => {
    setLoadingDisable(true);
    e.preventDefault();
    let data = {
      id: id,
      name: name,
      parent_id: blogCategory_id.toString()
    };

    let response = await addorEdit(data, id);
    if (response && response.data && response.data.message) {
      setCategoryFormError(response.data.errors);
      setLoadingDisable(false);
    }
  };

  const handleChange = async (event) => {
    set_blogCategory_id(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const getBlogCategories = async () => {
    try {
      const res = await helper.get(`${apiPath.get_blog_categories}`, session);

      if (res.status === 200) {
        if (res.data.success === true) {
          setBlogCategory(res.data.data);
          set_blogCategory_id(res.data.data[0].id);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getBlogCategories();
    if (recordForEdit) {
      setId(recordForEdit.id);
      set_blogCategory_id(recordForEdit.parent_id);
      setName(recordForEdit.name);
    }
  }, ['recordForEdit']);

  return (
    <Form onSubmit={handleSubmit} title="Add Category | Dr. B. Lal">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Stack>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="Category"
                value={blogCategory_id}
                label="Category"
                onChange={handleChange}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                error={''}
                helperText={''}
              >
                {blogCategory.map((e, key) => {
                  return (
                    <MenuItem key={key} value={e.id}>
                      {e.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Controls.Input
              name="name"
              fullWidth
              label="Sub category Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={categoryFormError?.name}
            />
          </Stack>
          <Box className="btn-Margin">
            <LoadingButton
              size="medium"
              type="submit"
              variant="contained"
              endIcon
              disabled={loadingDisable}
            >
              {id ? 'Update' : 'Add'}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
}
