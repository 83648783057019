import React, {useState} from 'react';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box, Button, Grid } from '@mui/material';
import { BaseOptionChart } from '../../charts';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment';
import dayjs from 'dayjs'
// ----------------------------------------------------------------------
export default function AppWebsiteVisits(props) {
  const totalSales = props.totalSales;
  const [startDate,setStartDate] = useState(null)
  const [endDate,setEndDate] = useState(null)

  const resetFilter = () => {
    setStartDate(null)
    setEndDate(null)
    props.func(null);
  };

  const CHART_DATA = [
    {
      name: 'Total Sales',
      type: 'column',
      data: totalSales.totalSales
    },
    {
      name: 'Home Collections',
      type: 'area',
      data: totalSales.totalSalesHome
    },
    {
      name: 'Lab Collections',
      type: 'line',
      data: totalSales.totalSalesLab
    }
  ];
  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [0, 2, 3] },
    plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
    fill: { type: ['solid', 'gradient', 'solid'] },
    labels: totalSales.label,
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} visits`;
          }
          return y;
        }
      }
    }
  });

  return (
    <Card>
      <CardHeader title="Sales graph" subheader={`${moment().year()} Year`} />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div style={{ display: 'flex', alignItems: "center", gap: '15px' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    label="From Date"
                    maxDate={dayjs(new Date())}
                    value={dayjs(startDate)}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                  />
                  <span>to</span>
                  <DatePicker
                    format="DD/MM/YYYY"
                    label="To Date"
                    maxDate={dayjs(new Date())}
                    value={dayjs(endDate)}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                    }}
                  />
                </LocalizationProvider>
              </div>
            </Grid>
            <Grid item xs={6} container>
              <Button variant="contained" color="error" onClick={resetFilter}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Box>

        <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={550} />
      </Box>
    </Card>
  );
}
