import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL1;
const BAPI_URL = process.env.REACT_APP_BLAL_API_BASE_URL;
const helper = {
  get: async (path = '', session = {}) => {
    const url = `${API_URL}${path}`;
    /* alert(url); */
    const res = await axios.get(url, {
      headers: {
        Authorization: session.token
      }
    });
    if (res.status === 401 || res.data.status === 401) {
      window.location = '/login';
    }
    return res;
  },
  getQueryString: async (path = '', jsonObj = {}, session = {}) => {
    const query = await helper.serialize(jsonObj);

    const url = `${API_URL}${path}?${query}`;
    const res = await axios.get(url, {
      headers: {
        Authorization: session.token
      }
    });
    if (res.status === 401 || res.data.status === 401) {
      window.location = '/login';
    }
    return res;
  },
  delete: async (path = '', session = {}) => {
    const url = API_URL + path;
    const res = await axios.delete(url, {
      headers: {
        Authorization: session.token
      }
    });
    if (res.status === 401 || res.data.status === 401) {
      window.location = '/login';
    }
    return res;
  },
  put: async (jsonObj = {}, path = '', session = {}) => {
    const url = API_URL + path;
    const res = await axios.put(url, jsonObj, {
      headers: {
        Authorization: session.token
      }
    });
    if (res.status === 401 || res.data.status === 401) {
      window.location = '/login';
    }
    return res;
  },
  post: async (jsonObj = {}, path = '', session = {}) => {
    const url = API_URL + path;
    const res = await axios.post(url, jsonObj, {
      headers: {
        Authorization: session.token
      }
    });
    if (res.status === 401 || res.data.status === 401) {
      window.location = '/login';
    }
    return res;
  },
  postb: async (path = '') => {
    try {
      const resToken = await axios.post(`${BAPI_URL}/api/login?UserName=1234&Password=abcd`)
      if (resToken.status === 200) {
        const data = resToken.data.Result.AccessToken;
        const url = `${BAPI_URL}${path}`;
        const res = await axios.post(url, {}, {
          headers: {
            Authorization: data,
          },
        });
        return res;
      }
    } catch (err) {
      return null;
    }
  },
  formPost: async (jsonObj = {}, path = '', session) => {
    const url = API_URL + path;
    const res = await fetch(url, {
      method: 'POST',
      body: jsonObj,
      headers: {
        'x-access-token': session.token || ''
      }
    });
    return { response: await res, status: await res.status };
  },
  serialize: function (obj, prefix) {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + '[' + p + ']' : p,
          v = obj[p];
        str.push(
          v !== null && typeof v === 'object'
            ? this.serialize(v, k)
            : encodeURIComponent(k) + '=' + encodeURIComponent(v)
        );
      }
    }
    return str.join('&');
  },
  parseJwt: (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%00${c.charCodeAt(0).toString(16)}`.slice(-2))
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
};
export default helper;
