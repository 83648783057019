import { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Card,
  Link,
  Container,
  Typography,
  Breadcrumbs,
  FormControl,
  Grid,
  Stack,
  Box,
  CircularProgress
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import Controls from '../../components/control/Controls';
import CKEditor from 'react-ckeditor-component';
import { Form } from '../../components/useForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop } from '@mui/material';
import useSession from 'src/hooks/useSession';

export default function Edit() {
  const session = useSession();
  const [loading, setLoading] = useState(false);
  const { slug } = useParams();
  const [title, setTitle] = useState(null);
  const [body, setBody] = useState(null);
  const [loadingDisable, setLoadingDisable] = useState(false);

  const getContent = async () => {
    try {
      const res = await helper.post({}, `${apiPath.content_details}/${slug}`, session);

      if (res.status === 200) {
        if (res.data.status === 200) {
          setTitle(res.data.data.title);
          setBody(res.data.data.body);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getContent();
  }, []);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const res = await helper.post(
        {
          requestBody: {
            title: title,
            body: body
          }
        },
        `${apiPath.content_details}/${slug}`,
        session
      );

      if (res.status === 200) {
        setLoading(false);
        if (res.data.status === 200) {
          setTitle(res.data.data.title);
          setBody(res.data.data.body);
          toast.success(res.data.message);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  };

  return (
    <Page title={'Contents | Dr. B Lal'}>
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Backdrop
        sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography variant="h4" gutterBottom>
              Contents
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/">
                Dashboard
              </Link>
              <Typography color="text.primary">Content List</Typography>
            </Breadcrumbs>
          </Grid>
        </Stack>
        <Form onSubmit={handleSubmit} title="Add Blog | Dr. B. Lal">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card className="MuiCardPadding">
                <Stack spacing={2}>
                  <Controls.Input
                    name="title"
                    fullWidth
                    label="Content Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />

                  <FormControl fullWidth>
                    {body && (
                      <CKEditor
                        activeClass="p10"
                        content={body}
                        events={{
                          afterPaste: (evt) => {
                            const newContent = evt.editor.getData();
                            setBody(newContent);
                          },
                          change: (evt) => {
                            const newContent = evt.editor.getData();
                            setBody(newContent);
                          }
                        }}
                      />
                    )}
                  </FormControl>
                  <Box>
                    <LoadingButton size="medium" type="submit" variant="contained" endIcon>
                      Update
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Form>
      </Container>
    </Page>
  );
}
