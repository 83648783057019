import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
// import useSession from 'react-session-hook';
import Page from '../../components/Page';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import { Search } from '@mui/icons-material';
// import { makeStyles } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import Popup from 'src/components/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TableContainer from '@mui/material/TableContainer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  Card,
  Box,
  Table,
  Stack,
  Paper,
  Grid,
  Breadcrumbs,
  Link,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import { Backdrop } from '@mui/material';
import moment from 'moment';
import Edit from './edit';
import Add from './add'
import useSession from 'src/hooks/useSession';

Cities.propTypes = {
  sx: PropTypes.object
};

export default function Cities({ sx }) {
  const [allCities, setAllCities] = useState([]);
  const [editCities, setEditCities] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const session = useSession();
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [openAddCityPopup, setOpenAddCityPopup] = useState(false)
  const [loading, setLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const navigate = useNavigate();

  const getAllCities = async () => {
    try {
      const res = await helper.get(`${apiPath.get_cities}`, session);
      if (res.status === 200) {
        if (res.data.success === true) {
          setAllCities(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const useStyles = makeStyles((theme) => ({
  //   pageContent: {
  //     margin: theme.spacing(5),
  //     padding: theme.spacing(3)
  //   },
  //   searchInput: {
  //     width: '15%'
  //   },
  //   newButton: {
  //     position: 'absolute',
  //     right: '10px'
  //   }
  // }));

  // const classes = useStyles();

  const headeCellsHappyCustomers = [
    { id: 'cityName', label: 'City Name', disableSorting: true },
    { id: 'slug', label: 'Meta Url', disableSorting: true },
    { id: 'metaTitle', label: 'Meta Title', disableSorting: true },
    { id: 'status', label: 'Status', disableSorting: true },
    { label: 'Action', disableSorting: true }
  ];
  useEffect(() => {
    getAllCities();
  }, []);

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    allCities,
    headeCellsHappyCustomers,
    filterFn
  );

  const addPopup = async (item) => {
    setOpenAddPopup(true);
    setRecordForEdit(item);
  };

  const handlecouponDetailsView = async (item) => {
    console.log(item, "city ietem")
    navigate('/cities/details', { state: { id: item.CityId } });
  };

  return (
    <>
      <Page title={'Happy Customers | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={10} sm={10} md={10} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Cities
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">All Cities</Typography>
              </Breadcrumbs>
            </Grid>
            <Controls.Button
              text="Add City"
              variant="outlined"
              onClick={() => {
                setOpenAddCityPopup(true);
              }}
            />
          </Stack>
          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.CityName}</TableCell>
                  <TableCell>{item.slug ? item.slug : "-"}</TableCell>
                  <TableCell>{item.metaTitle ? item.metaTitle : "-"}</TableCell>
                  <TableCell>{item.status === 1 ? "Active" : "Inactive"}</TableCell>
                  <TableCell>
                    <Toolbar>
                      <Controls.ActionButton
                        color="default"
                        onClick={() => {
                          handlecouponDetailsView(item);
                        }}
                      >
                        <VisibilityIcon fontSize="small" />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        color="primary"
                        onClick={() => {
                          addPopup(item);
                          setEditCities(true);
                        }}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </Controls.ActionButton>
                    </Toolbar>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>
      <Popup title={'Cities'} openPopup={openAddPopup} setOpenPopup={setOpenAddPopup}>
        {editCities ? (
          <Edit
            title="Edit Cities"
            recordForEdit={recordForEdit}
          />
        ) : (
          <p>1</p>
        )}
      </Popup>
      <Popup title={'Cities'} openPopup={openAddCityPopup} setOpenPopup={setOpenAddCityPopup}>
        {openAddCityPopup ? (
          <Add
            title="Add City"
            toast={toast}
            fetchCityList={getAllCities}
            setOpenPopup={setOpenAddCityPopup}
          />
        ) : (
          null
        )}
      </Popup>
    </>
  );
}
