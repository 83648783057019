import React from 'react';
import { useState, useEffect } from 'react';
import {
  Card,
  Paper,
  Grid,
  Stack,
  Container,
  Typography,
  Breadcrumbs,
  Link
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import Page from '../../components/Page';
import 'date-fns';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import useSession from 'src/hooks/useSession';

export default function Details(props) {
  const { booking_id } = useParams();
  const [bookingDetails, setBookingDetails] = useState(null);
  const [invoiceType, setInvoiceType] = useState(false);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });

  const session = useSession();
  const [labNo, setLabNo] = useState(null);
  const [reports, setReports] = useState([]);
  const headeCells = [
    { id: 'field', label: 'Field' },
    { id: 'Data', label: 'data' }
  ];

  useEffect(() => {
    getBookingDetails();
  }, []);

  const getBookingDetails = async () => {
    try {
      const response = await helper.get(`${apiPath.booking_details}${booking_id}`, session);

      if (response.status === 200 && response.data.status === 200) {
        if (response.data.data.LedgerTransactionNo) {
          setLabNo(response.data.data.LedgerTransactionNo);
          let packageCheck = false;
          response.data.data.booking_member_tests.map((test) => {
            if (test.test_type === "Package") {
              packageCheck = true;
            }
          })
          setInvoiceType(packageCheck);
        }
        setBookingDetails(response.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    reportTest();
  }, [labNo]);

  const reportTest = async () => {
    try {
      const response = await helper.postb(`/api/ReportStatus?LedgerTransactionNo=${labNo}
      `);

      if (response.status === 200) {
        if (response.data.status_Code === 200) {
          setReports(response.data.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const prescriptionList = (prescriptions) => {
    var cards = [];
    for (let index = 0; index < prescriptions.length; index++) {
      var temp = (
        <p>
          <a target="_blank" href={prescriptions[index]}>
            Prescription {index + 1}
          </a>
          <br></br>
        </p>
      );
      cards.push(temp);
    }
    return cards;
  };
  return (
    <Page title="Booking Details | Dr. B. Lal">
      <Container maxWidth={true}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography variant="h4" gutterBottom>
              Booking
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/">
                Dashboard
              </Link>
              <Link underline="hover" color="inherit" component={RouterLink} to="/bookings">
                Bookings
              </Link>
              {<Typography color="text.primary">Booking Details</Typography>}
            </Breadcrumbs>
          </Grid>
        </Stack>

        {bookingDetails !== null ? (
          <>
            <Card>
              <CardHeader title="Booking Details" />
              <CardContent>
                <TableContainer component={Paper}>
                  <Table className={'table'} aria-label="simple table">
                    <TableBody>
                      <TableRow key=" Booking ID">
                        <TableCell component="th" scope="row">
                          Booking ID
                        </TableCell>
                        <TableCell align="left">{bookingDetails.LisBookId}</TableCell>
                      </TableRow>

                      <TableRow key="Collection Type">
                        <TableCell component="th" scope="row">
                          Collection Type
                        </TableCell>
                        <TableCell align="left">{bookingDetails.collection_type}</TableCell>
                      </TableRow>

                      {bookingDetails.prescriptions_id && (
                        <TableRow key="Prescriptions Docs">
                          <TableCell component="th" scope="row">
                            Prescriptions Docs
                          </TableCell>
                          <TableCell align="left">
                            {prescriptionList(bookingDetails.prescription_attachments)}
                          </TableCell>
                        </TableRow>
                      )}

                      {bookingDetails.collection_type === 'Home' && bookingDetails.fleet_accept && (
                        <TableRow key="Agent Details">
                          <TableCell component="th" scope="row">
                            Agent Details
                          </TableCell>
                          <TableCell align="left">
                            {JSON.parse(bookingDetails.fleet_accept).fleet_name} ({' '}
                            {JSON.parse(bookingDetails.fleet_accept).fleet_phone})
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow key="Booking Schedule">
                        <TableCell component="th" scope="row">
                          Booking Schedule
                        </TableCell>
                        {console.log(bookingDetails.booking_date_time, 'bookingDetails.booking_date_time')}
                        <TableCell align="left">
                          {bookingDetails.collection_type === 'Home'
                            ? bookingDetails.booking_date_time
                            : bookingDetails.booking_date_time}
                        </TableCell>
                      </TableRow>
                      {bookingDetails.collection_type === 'Home' && (
                        <TableRow key=" Tookan Task Id">
                          <TableCell component="th" scope="row">
                            Tookan Task Id
                          </TableCell>
                          <TableCell align="left">{bookingDetails.task_id}</TableCell>
                        </TableRow>
                      )}
                      <TableRow key="Status">
                        <TableCell component="th" scope="row">
                          Status
                        </TableCell>
                        <TableCell align="left">{bookingDetails.status}</TableCell>
                      </TableRow>

                      <TableRow key="Primary User">
                        <TableCell component="th" scope="row">
                          Primary User
                        </TableCell>
                        <TableCell align="left">{bookingDetails.user.fullname}</TableCell>
                      </TableRow>

                      <TableRow key="Phone Number">
                        <TableCell component="th" scope="row">
                          Phone Number
                        </TableCell>
                        <TableCell align="left">{bookingDetails.user.phone_number}</TableCell>
                      </TableRow>

                      <TableRow key="email">
                        <TableCell component="th" scope="row">
                          Email
                        </TableCell>
                        <TableCell align="left">{bookingDetails.user.email}</TableCell>
                      </TableRow>

                      {bookingDetails.collection_type === 'Home' && (
                        <TableRow key="Address">
                          <TableCell component="th" scope="row">
                            Address
                          </TableCell>
                          <TableCell align="left">{bookingDetails.address_id}</TableCell>
                        </TableRow>
                      )}

                      {bookingDetails.membership_card_id !== null ? (
                        <TableRow key="Membership Card">
                          <TableCell component="th" scope="row">
                            Membership Card
                          </TableCell>
                          <TableCell align="left">{bookingDetails.membership_card_id}</TableCell>
                        </TableRow>
                      ) : null}
                      {bookingDetails.coupon !== null && bookingDetails.coupon !== '' ? (
                        <TableRow key="Coupon">
                          <TableCell component="th" scope="row">
                            Coupon
                          </TableCell>
                          <TableCell align="left">{bookingDetails.coupon}</TableCell>
                        </TableRow>
                      ) : null}
                      {bookingDetails.LedgerTransactionNo && (
                        <TableRow key="Invoice">
                          <TableCell component="th" scope="row">
                            Invoice
                          </TableCell>
                          <TableCell align="left">
                            <a
                              target="_blank"
                              href={`${process.env.REACT_APP_LIMS_DOWNLOAD_URL}/Finanace/ReceiptBill.aspx?cmd=reprint&LedgerTransactionNo=${bookingDetails.LedgerTransactionNo}&Status=0&TYPE=${invoiceType ? "PACKAGE" : "LAB"}`}
                            >
                              Download Invoice
                            </a>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
            <br></br>
            <Card>
              <CardHeader title="Booking Members" />
              <CardContent>
                <TableContainer component={Paper}>
                  <Table className={'table'} aria-label="simple table">
                    <TableBody>
                      <TableRow key="name">
                        <TableCell component="th" scope="row">
                          Name
                        </TableCell>
                        <TableCell align="left">
                          {bookingDetails.user_family_member.fullname}
                        </TableCell>
                      </TableRow>
                      <TableRow key="name">
                        <TableCell component="th" scope="row">
                          Age
                        </TableCell>
                        <TableCell align="left">{bookingDetails.user_family_member.age}</TableCell>
                      </TableRow>
                      <TableRow key="name">
                        <TableCell component="th" scope="row">
                          Gender
                        </TableCell>
                        <TableCell align="left">
                          {bookingDetails.user_family_member.gender}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
            <br></br>
            <Card>
              <CardHeader title="Tests or Packages" />
              <CardContent>
                <div>
                  <table style={{ width: '80%' }} className="table">
                    <tbody>
                      {bookingDetails.booking_member_tests.map((item) => (
                        <tr>
                          <td>{item.test_type}</td>
                          <td>{item.test_id}</td>
                          <td>{item.test_name}</td>
                          <td>{item.test_price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardContent>
            </Card>
            <br></br>
            {reports.length > 0 && (
              <Card>
                <CardHeader title="Test Reports" />
                <CardContent>
                  <div>
                    <table style={{ width: '80%' }} className="table">
                      <tbody>
                        {reports.map((item) => (
                          <tr>
                            <td>{item.Test_ID}</td>
                            <td>{item.Status}</td>
                            <td>
                              {item.Status === 'Approved' && (
                                <a
                                  target="_blank"
                                  href={`${process.env.REACT_APP_LIMS_DOWNLOAD_URL}/Lab/LabReportNew.aspx?PHead=1&TestID=${item.Test_ID}`}
                                >
                                  Download Report
                                </a>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardContent>
              </Card>
            )}
            <br></br>
            <Card>
              <CardHeader title="Payment Details" />
              <CardContent>
                <TableContainer component={Paper}>
                  <Table className={'table'} aria-label="simple table">
                    <TableBody>
                      <TableRow key="Payment Mode">
                        <TableCell component="th" scope="row">
                          Payment Mode
                        </TableCell>
                        <TableCell align="left">{bookingDetails.payment_mode}</TableCell>
                      </TableRow>
                      {bookingDetails.payment_mode === 'Online' && (
                        <TableRow key="Transaction Id">
                          <TableCell component="th" scope="row">
                            Transaction Id
                          </TableCell>
                          <TableCell align="left">{bookingDetails.transactions}</TableCell>
                        </TableRow>
                      )}
                      <TableRow key="Total Amount">
                        <TableCell component="th" scope="row">
                          Total Amount
                        </TableCell>
                        <TableCell align="left">{bookingDetails.total_member_amount}</TableCell>
                      </TableRow>
                      {bookingDetails.coupon && (
                        <TableRow key="Payment Coupon">
                          <TableCell component="th" scope="row">
                            Coupon
                          </TableCell>
                          <TableCell align="left">{bookingDetails.coupon}</TableCell>
                        </TableRow>
                      )}
                      {bookingDetails.total_member_discounted > 0 && (
                        <TableRow key="Discounted Amount">
                          <TableCell component="th" scope="row">
                            Discounted Amount
                          </TableCell>
                          <TableCell align="left">
                            {bookingDetails.total_member_discounted}
                          </TableCell>
                        </TableRow>
                      )}
                      {bookingDetails.total_member_discount_per && (
                        <TableRow key="Discounted Amount in (%)">
                          <TableCell component="th" scope="row">
                            Discounted Amount in (%)
                          </TableCell>
                          <TableCell align="left">
                            {bookingDetails.total_member_discount_per} %
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow key="Total Amount for whole booking">
                        <TableCell component="th" scope="row">
                          Total Amount for whole booking
                        </TableCell>
                        <TableCell align="left">{bookingDetails.total_amount}</TableCell>
                      </TableRow>

                      {bookingDetails.membership_card_id && (
                        <TableRow key="Membership">
                          <TableCell component="th" scope="row">
                            Membership
                          </TableCell>
                          <TableCell align="left">{bookingDetails.membership_card_id}</TableCell>
                        </TableRow>
                      )}
                      {bookingDetails.discounted_amount && (
                        <TableRow key="Total Discounted Amount">
                          <TableCell component="th" scope="row">
                            Total Discounted Amount
                          </TableCell>
                          <TableCell align="left">{bookingDetails.discounted_amount}</TableCell>
                        </TableRow>
                      )}

                      <TableRow key="Pickup charge">
                        <TableCell component="th" scope="row">
                          Pickup charge
                        </TableCell>
                        <TableCell align="left">{bookingDetails.pickup_charge}</TableCell>
                      </TableRow>

                      <TableRow key="Total Paid Amount">
                        <TableCell component="th" scope="row">
                          Total Paid Amount
                        </TableCell>
                        <TableCell align="left">{bookingDetails.final_amount}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </>
        ) : null}
        <br />

        <br />
      </Container>
    </Page>
  );
}
