import React from 'react';
import { useState, useEffect, useRef } from 'react';
import {
  Card,
  FormControl,
  InputLabel,
  Grid,
  Stack,
  Box,
  TextField,
  Select,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';

export default function AddCategory(props) {
  let { addorEdit, recordForEdit } = props;
  let el = useRef();
  const [categoryId, setCategoryId] = useState('');
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [categoryFormError, setCategoryFormError] = useState({});
  const [previewImage, setPreviewImage] = useState('');
  const [loadingDisable, setLoadingDisable] = useState(false);

  const handleSubmit = async (e) => {
    setLoadingDisable(true);
    e.preventDefault();
    let data = new FormData();
    data.append('id', categoryId);
    data.append('name', name);
    data.append('image', image);

    let response = await addorEdit(data, categoryId);
    console.log('response', response);
    if (response !== undefined && response.status === 200) {
      setLoadingDisable(false);

      if (response.data.status === 422) {
        setCategoryFormError(response.data.errors);
      }
    }
  };

  const getFile = (e) => {
    let ext = e.target.value.match(/\.([^\.]+)$/)[1];
    switch (ext) {
      case 'png':
      case 'jpeg':
      case 'jpg':
        const file = e.target.files[0];
        setImage(file);
        break;
      default:
        alert('Please upload supported attachment file format (.png, .jpeg, .jpg).');
        e.target.value = '';
    }
  };

  useEffect(() => {
    if (recordForEdit) {
      setCategoryId(recordForEdit.id);
      setName(recordForEdit.name);
      setImage(recordForEdit.image);
      setPreviewImage(recordForEdit.image);
    }
  }, ['recordForEdit']);

  return (
    <Form onSubmit={handleSubmit} title="Add Category | Dr. B. Lal">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Controls.Input
            name="name"
            fullWidth
            label="Category Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={categoryFormError?.name}
            helperTex
            t={categoryFormError?.name}
          />
          <Controls.Input
            name="image"
            type="file"
            accept="image/*"
            onChange={getFile}
            ref={el}
            error={categoryFormError?.image}
            helperTex
            t={categoryFormError?.image}
          />{' '}
          <Box className="btn-Margin">
            <LoadingButton
              size="medium"
              type="submit"
              variant="contained"
              endIcon
              disabled={loadingDisable}
            >
              {categoryId ? 'Update' : 'Add'}
            </LoadingButton>
          </Box>
          {previewImage && (
            <Box className="btn-Margin">
              <img src={previewImage} alt="Banner_Image" with="80" height="80" />{' '}
            </Box>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}
