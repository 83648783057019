import React from 'react';
import { useState, useEffect } from 'react';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import Page from '../../components/Page';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Popup from 'src/components/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RoleAdd from './Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LockOutlined from '@mui/icons-material/LockOutlined';
import {
  Stack,
  Grid,
  Breadcrumbs,
  Link,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';

import { Backdrop } from '@mui/material';
import useSession from 'src/hooks/useSession';

export default function List() {
  const navigate = useNavigate();
  const [pincodeData, setPincodeData] = useState([]);
  const session = useSession();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [addPincode, setAddPincode] = useState(false);
  const [editPincode, setEditPincode] = useState(false);
  let [loading, setLoading] = useState(false);
  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        return target.value == ''
          ? items
          : items.filter((x) => x.name.toLowerCase().includes(target.value));
      }
    });
  };

  const getRoleData = async () => {
    try {
      const res = await helper.get(`${apiPath.role_list}`, session);
      if (res.status === 200) {
        setPincodeData(res.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const headeCells = [
    { id: 'name', label: 'Name', disableSorting: true },
    { id: 'action', label: 'Action', disableSorting: true }
  ];

  useEffect(() => {
    getRoleData();
  }, []);

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    pincodeData,
    headeCells,
    filterFn
  );

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const addorEdit = async (data, id) => {
    setLoading(true);
    try {
      let URL = apiPath.roleAddOrUpdate;
      if (recordForEdit) {
        URL = `${URL}?role_id=${id}`;
      }
      const res = await helper.post(data, URL, session);
      if (res.status === 200) {
        if (res.data.success === true) {
          getRoleData();
          setOpenPopup(false);
          setLoading(false);
          toast.success(res.data.message);
        } else {
          setOpenPopup(false);
          setLoading(false);
          toast.error(res.data.message);
          // toast(res.data.message);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log('Error', error);
    }
  };

  const handlePincodeDelete = async (item) => {
    try {
      let res = await helper.delete(`${apiPath.delete_pincode}/${item.id}`, session);
      if (res.status === 200) {
        getPincodeData();
        toast(res.data.api_name);
      }
    } catch (e) {
      toast('Error', e);
    }
  };

  return (
    <>
      <Page title={'Role List | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={true}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Role List
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">Roles</Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>
        </Container>

        <Toolbar>
          {/* <Controls.Input
            label="Search Pincode"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            onChange={handleSearch}
          /> */}

          <Controls.Button
            text="Add New Role"
            variant="outlined"
            startIcon={<AddIcon />}
            className={'newButton'}
            onClick={() => {
              setOpenPopup(true);
              setRecordForEdit(null);
              setAddPincode(true);
            }}
          />
        </Toolbar>

        <TblContainer>
          <TblHeader />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  <Controls.ActionButton
                    color="primary"
                    onClick={() => {
                      openInPopup(item);
                      setEditPincode(true);
                    }}
                  >
                    <EditOutlinedIcon fontSize="small" />
                  </Controls.ActionButton>
                  {/* <Link to={`roles/${item.id}/module/permissions`}> */}
                  <Controls.ActionButton
                    color="secondary"
                    title="Permission"
                    onClick={() => {
                      navigate(`/roles/${item.id}/module/permissions`);
                    }}
                  >
                    <LockOutlined fontSize="small" />
                  </Controls.ActionButton>
                  {/* </Link> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Page>

      <Popup
        style={{ width: '46rem!important' }}
        title={'Role'}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <RoleAdd title="Edit Role" recordForEdit={recordForEdit} addorEdit={addorEdit} />
      </Popup>
    </>
  );
}
