import React from 'react';
import { useState, useEffect } from 'react';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import Page from '../../components/Page';
import { Link as RouterLink } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Popup from 'src/components/Popup';
import { ToastContainer, toast } from 'react-toastify';
import PincodeAdd from './Add';
import 'react-toastify/dist/ReactToastify.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';

import {
  Stack,
  Grid,
  Breadcrumbs,
  Link,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import moment from 'moment';
import { Backdrop } from '@mui/material';
import useSession from 'src/hooks/useSession';

export default function List() {
  const [pincodeData, setPincodeData] = useState([]);
  const session = useSession();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [addPincode, setAddPincode] = useState(false);
  const [editPincode, setEditPincode] = useState(false);
  const [citiesData, setCitiesData] = useState([]);
  const [cityId, setCityId] = useState('all');
  const [searchKeyword, setSearchKeyword] = useState('');
  let [loading, setLoading] = useState(false);

  const [permission, setPermission] = useState({
    create: false,
    view: false,
    update: false,
    delete: false
  });
  const [update, setUpdate] = useState(true);

  const modulePermissions = () => {
    const modules = session?.profile?.user?.role?.role_rights || [];
    if (session?.profile?.user?.role?.id === 1) {
      setPermission({
        create: true,
        view: true,
        update: true,
        delete: true
      });
    } else {
      const module = 'pincodes';
      let createObj = modules.find((obj) => obj.module.tbl_name === module && obj[`r_create`]);

      if (createObj) {
        permission.create = true;
      }
      let viewObj = modules.find((obj) => obj.module.tbl_name === module && obj[`r_view`]);
      if (viewObj) {
        permission.view = true;
      }
      let updateObj = modules.find((obj) => obj.module.tbl_name === module && obj[`r_update`]);
      if (updateObj) {
        permission.update = true;
      }
      let deleteObj = modules.find((obj) => obj.module.tbl_name === module && obj[`r_delete`]);
      if (deleteObj) {
        permission.delete = true;
      }
      setUpdate(!update);
    }
  };

  useEffect(() => {
    modulePermissions();
  }, []);

  const handleSearch = (e) => {
    setSearchKeyword(e.target.value);
    // let target = e.target;
    // setFilterFn({
    //   fn: (items) => {
    //     return target.value == ''
    //       ? items
    //       : items.filter((x) => x.name.toLowerCase().includes(target.value));
    //   }
    // });
  };

  const getPincodeData = async () => {
    try {
      const res = await helper.get(
        `${apiPath.get_pincode_list}?cityId=${cityId}&search_keyword=${searchKeyword}`,
        session
      );
      if (res.status === 200) {
        if (res.data.success === true) {
          setPincodeData(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const headeCells = [
    { id: 'city_name', label: 'City Name', disableSorting: true },
    { id: 'name', label: 'Name', disableSorting: true },
    { id: 'pincode', label: 'Pincode', disableSorting: true },
    { id: 'date', label: 'Created At', disableSorting: true },
  ];

  if (permission.update) {
    headeCells.push({ id: 'status', label: 'Status', disableSorting: true });
    headeCells.push({ id: 'action', label: 'Action', disableSorting: true });
  }

  const getCityList = async () => {
    try {
      setLoading(true);
      const res = await helper.get(`${apiPath.get_all_cities}`);

      if (res.data.status === 200) {
        setLoading(false);
        setCitiesData(res.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getPincodeData();
  }, [cityId, searchKeyword]);

  useEffect(() => {
    getCityList();
  }, []);

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    pincodeData,
    headeCells,
    filterFn,
    searchKeyword
  );

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const addorEdit = async (data, id) => {
    setLoading(true);
    try {
      if (recordForEdit == null) {
        const response = await helper.post(data, apiPath.add_pincode, session);
        console.log('response', response);
        if (response.data.success == true) {
          getPincodeData();
          setOpenPopup(false);
          toast.success(response.data.message);
        } else {
          setLoading(false);
          return response;
        }
      } else {
        const res = await helper.put(data, `${apiPath.updtae_pincode}/${id}`, session);
        if (res.data.success == true) {
          getPincodeData();
          setOpenPopup(false);
          setLoading(false);
          toast.success(res.data.message);
        } else {
          setLoading(false);
          return res;
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('Error', error);
    }
  };

  const handlePincodeDelete = async (item) => {
    try {
      let res = await helper.delete(`${apiPath.delete_pincode}/${item.id}`, session);
      if (res.status === 200) {
        getPincodeData();
        toast.success(res.data.api_name);
      }
    } catch (e) {
      toast.error('Error', e);
    }
  };

  const handleStatusChange = async (item) => {
    try {
      setLoading(true);
      var data = {
        table: 'pincodes',
        status: !item.status
      };
      const response = await helper.post(data, `${apiPath.update_status}/${item.id}`, session);
      if (response.status === 200) {
        setLoading(false);
        if (response.data.success === true) {
          getPincodeData();
          toast.success(response.data.message);
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      <Page title={'Pincodes List | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={true}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Pincode List
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">Pincodes</Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>
        </Container>

        <Toolbar>
          <Stack direction="row" style={{ justifyContent: 'space-between' }}>
            <Controls.Input
              style={{ width: '70%' }}
              label="Search Area Name"
              className={'searchInput'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
              onChange={handleSearch}
            />

            <FormControl sx={{ m: 1, width: 200, marginTop: '0%' }}>
              <InputLabel id="demo-simple-select-label">Select City</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select City"
                value={cityId}
                onChange={(e) => {
                  setCityId(e.target.value);
                }}
              >
                <MenuItem value="all">All</MenuItem>;
                {citiesData.length > 0 &&
                  citiesData.map((item) => {
                    return <MenuItem value={item.CityId}>{item.CityName}</MenuItem>;
                  })}
              </Select>
            </FormControl>
            {permission.create ?
                <Controls.Button
                text="Add New Pincode"
                variant="outlined"
                startIcon={<AddIcon />}
                className={'newButton'}
                onClick={() => {
                  setOpenPopup(true);
                  setRecordForEdit(null);
                  setAddPincode(true);
                }}
              />
              : ""
            }
          </Stack>
        </Toolbar>

        <TblContainer>
          <TblHeader />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.id}>
                {console.log(item, 'item -->>')}
                <TableCell>{item.city ? item.city.CityName : ""}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.pincode}</TableCell>
                {permission.update ?
                    <TableCell>
                        <Switch
                        checked={item.status}
                        onChange={() => {
                            handleStatusChange(item);
                        }}
                        />
                    </TableCell>
                : ""}
                <TableCell>{moment(item.created_at).format('DD/MM/YYYY')}</TableCell>
                {permission.update ?
                    <TableCell>
                    <Controls.ActionButton
                      color="primary"
                      onClick={() => {
                        openInPopup(item);
                        setEditPincode(true);
                      }}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </Controls.ActionButton>
  
                    {/* <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        handlePincodeDelete(item);
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton> */}
                  </TableCell>
                : ""}
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Page>

      <Popup
        style={{ width: '46rem!important' }}
        title={'Pincode'}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <PincodeAdd title="Edit Blog" recordForEdit={recordForEdit} addorEdit={addorEdit} />
      </Popup>
    </>
  );
}