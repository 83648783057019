import React from 'react';
import PropTypes from 'prop-types';
import Page from '../../components/Page';
import {
  Stack,
  Box,
  Paper,
  Grid,
  Breadcrumbs,
  Link,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import Popup from 'src/components/Popup';
import { Link as RouterLink } from 'react-router-dom';
import Controls from '../../components/control/Controls';
// import { makeStyles } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { Search } from '@mui/icons-material';
import useTable from 'src/components/useTable';
// import useSession from 'react-session-hook';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import { Card, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Backdrop } from '@mui/material';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import Switch from '@mui/material/Switch';
import moment from 'moment';
import useSession from 'src/hooks/useSession';

List.propTypes = {
  sx: PropTypes.object
};

export default function List({ sx }) {
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const session = useSession();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [couponData, setCouponData] = useState([]);
const [coupon, setCoupon] = useState("all");
const getRecords = async() => {
  try {
    setLoading(true)
    const res = await helper.get(
      `${apiPath.coupon_revenue_list}?coupon=${coupon}`,
      session
    );
    if (res.status === 200) {
      setLoading(false)
      if(res.data.success === true){
        setTotalSales(res.data.data.total_sales.total)
        setTotalRevenue(res.data.data.revenue.total?res.data.data.revenue.total:0)
        setRecords(res.data.data.docs);
      }
    }
  } catch (error) {
    setLoading(false)
    console.log(error);
  }
}


const getcouponData = async () => {
  try {
    const res = await helper.get(
      `${apiPath.coupons_list}`,
      session
    );
    if (res.status === 200) {
   
      if (res.data.status === 200) {
        setCouponData(res.data.data.docs);
      }
    }
  } catch (e) {
    console.log(e);
  }
};


  useEffect(() => {
    getRecords()
  }, [coupon]);

  useEffect(() => {
    getcouponData()
  }, []);



  const headeCells = [
    { id: 'Booking Id', label: 'Booking Id',disableSorting: true },
    { id: 'code', label: 'Coupon Code',disableSorting: true },
    { id: 'tests', label: 'Tests',disableSorting: true },
    { id: 'User', label: 'User',disableSorting: true },
    { id: 'date', label: 'Booking Date', disableSorting: true },
    { id: 'phone_number', label: 'Phone Number',disableSorting: true },
    { id: 'user_redeem', label: 'User Redeem',disableSorting: true },
    { id: 'total_revenue', label: 'Total Revenue',disableSorting: true },
    { id: 'total_discount', label: 'Total Discount',disableSorting: true },
  ];

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    records,
    headeCells,
    filterFn
  );

  // const useStyles = makeStyles((theme) => ({
  //   pageContent: {
  //     margin: theme.spacing(5),
  //     padding: theme.spacing(3)
  //   },
  //   searchInput: {
  //     width: '15%'
  //   },
  //   newButton: {
  //     position: 'absolute',
  //     right: '10px'
  //   }
  // }));

  // const classes = useStyles();

  const style = {
    margin: 10
  };


  return (
    <>
    <Page title={'Coupon Revenue | Dr. B Lal'}>
    <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      <Backdrop
        sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography variant="h4" gutterBottom>
            Coupon Revenue
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/">
                Dashboard
              </Link>
              <Typography color="text.primary">All Coupon Bookings </Typography>
            </Breadcrumbs>
          </Grid>
          
          <FormControl sx={{ width: 280 }}>
                  <InputLabel id="demo-simple-select-label">
                    <strong>Total Sale</strong>
                  </InputLabel>
                  
                    <Controls.Button
                      text={totalSales}
                      variant="contained"
                      className={'newButton'}
                    />
                
                </FormControl>
          <FormControl sx={{ width: 280 }}>
                  <InputLabel id="demo-simple-select-label">
                    <strong>Total Revenue</strong>
                  </InputLabel>
                 
                    <Controls.Button
                      text={totalRevenue}
                      variant="contained"
                      className={'newButton'}
                    />
                </FormControl>
        </Stack>

        <Toolbar>
          
          {/* <Controls.Input
            label="Search Packages"
            value={SearchKeyword}
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            onChange={(e) => {
              setSearchKeyword(e.target.value);
            }}
          /> */}

        <FormControl sx={{ m: 1, width: 150, marginTop: '0%' }}>
            <InputLabel id="demo-simple-select-label">Filter Coupon</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Filter Coupon"
              value={coupon}
              onChange={(e) => {
                setCoupon(e.target.value);
              }}
            >
              <MenuItem value="all">All</MenuItem>
              {couponData.length > 0 &&
                couponData.map((e, key) => {
                  return (
                    <MenuItem
                      value={e.code}
                    >
                      {e.code}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
               
        </Toolbar>
        <TblContainer>
          <TblHeader />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.LisBookId}</TableCell>
                <TableCell>{item.coupon}</TableCell>
                <TableCell>{item.booking_member_tests.map((item) => (
                  <p>{item.test_name}</p>
                ))}</TableCell>
                <TableCell>{item?.user_family_member?.fullname}</TableCell>
                <TableCell>{item?.current_date_time ? moment(item.current_date_time).format("DD/MM/YYYY hh:mm A") : moment(item.updated_at).format('DD/MM/YYYY hh:mm A')}</TableCell>
                <TableCell>{item?.user?.phone_number}</TableCell>
                <TableCell>{item.fleet_accept}</TableCell>
                <TableCell>{item.final_amount}</TableCell>
                <TableCell>{item.discounted_amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Container>
    </Page>
       
        </>
  );
}
