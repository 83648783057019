module.exports = global.config = [
  {
    id: 2,
    module_id: 2,
    role_id: 10,
    r_screen: true,
    r_create: true,
    r_update: true,
    r_edit: false,
    r_view: true,
    r_delete: true,
    module: {
      id: 2,
      name: 'Blogs',
      tbl_name: 'blogs'
    }
  },
  {
    id: 3,
    module_id: 1,
    role_id: 10,
    r_screen: true,
    r_create: true,
    r_update: false,
    r_edit: false,
    r_view: true,
    r_delete: false,
    module: {
      id: 1,
      name: 'User Management',
      tbl_name: 'users'
    }
  },
  {
    id: 4,
    module_id: 3,
    role_id: 10,
    r_screen: true,
    r_create: false,
    r_update: false,
    r_edit: false,
    r_view: false,
    r_delete: false,
    module: {
      id: 3,
      name: 'Lab Test and Packages Management',
      tbl_name: 'tests_packages'
    }
  }
];
