import { useState, useEffect } from 'react';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import Page from '../../components/Page';
import { Link as RouterLink } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Popup from 'src/components/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import UserAdd from './Add';
import { useNavigate } from 'react-router-dom';
import Switch from '@mui/material/Switch';

import {
  Stack,
  Grid,
  Breadcrumbs,
  Link,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';

import { Backdrop } from '@mui/material';
import useSession from 'src/hooks/useSession';

export default function List() {
  let navigate = useNavigate();
  // const [filterFn, setFilterFn] = useState({ fn: (items) => {return items; } });
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const session = useSession();
  const [usersData, setUsersData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [addUser, setAddUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [userDetailsView, setUserDetailsView] = useState(false);

  let [loading, setLoading] = useState(false);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        return target.value == ''
          ? items
          : items.filter((x) => x.fullname.toLowerCase().includes(target.value));
      }
    });
  };

  const getUsersData = async () => {
    try {
      const res = await helper.get(`${apiPath.users_list}?role_id=2`, session);
      if (res.status === 200) {
        setUsersData(res.data.data.docs);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const headeCells = [
    { id: 'id', label: 'Id', disableSorting: true },
    { id: 'fullname', label: 'Full Name', disableSorting: true },
    { id: 'email', label: 'Email', disableSorting: true },
    { id: 'phone_number', label: 'Phone Number', disableSorting: true },
    { id: 'user_roles[0].role.name', label: 'Role Name', disableSorting: true },
    { id: 'status', label: 'Status', disableSorting: true },
    { id: 'action', label: 'Action', disableSorting: true }
  ];

  useEffect(() => {
    getUsersData();
  }, []);

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    usersData,
    headeCells,
    filterFn
  );

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const addorEdit = async (data, id) => {
    setLoading(true);
    try {
      if (recordForEdit == null) {
        const response = await helper.post(data, apiPath.add_subadmin, session);
        if (response.data.success === true) {
          getUsersData();
          setOpenPopup(false);
          toast.success(response.data.message);
        } else {
          setLoading(false);
          return response;
        }
      } else {
        console.log('in else part');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('Error', error);
    }
  };

  const handleUserDetailsView = async (item) => {
    setUserDetailsView(item);
    setRecordForEdit(false);
    setAddUser(false);
    navigate('/users/details', { state: { id: item.id } });
  };

  const handleDelete = async (item) => {
    try {
      setLoading(true);
      const response = await helper.post({ user_id: item.id }, apiPath.delete_subadmin, session);
      if (response.status === 200) {
        setLoading(false);
        if (response.data.success === true) {
          getUsersData();
          toast.success(response.data.message);
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleStatusChange = async (item) => {
    try {
      setLoading(true);
      var data = {
        table: 'user_roles',
        status: !item.status
      };
      const response = await helper.post(data, `${apiPath.update_status}/${item.id}`, session);
      if (response.status === 200) {
        setLoading(false);
        if (response.data.success === true) {
          getUsersData();
          toast.success(response.data.message);
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      <Page title={'Users List | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Sub Admin
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">List </Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <Toolbar>
            <Controls.Input
              label="Search sub admins"
              className={'searchInput'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
              onChange={handleSearch}
            />
            <Controls.Button
              text="Add New User"
              variant="outlined"
              startIcon={<AddIcon />}
              className={'newButton'}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
                setAddUser(true);
              }}
            />
          </Toolbar>

          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.fullname}</TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{item.phone_number}</TableCell>
                  <TableCell>{item.user_roles[0].role.name}</TableCell>
                  <TableCell>
                    <Switch
                      checked={item.user_roles[0].status}
                      onChange={() => {
                        handleStatusChange(item.user_roles[0]);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        handleDelete(item);
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>

                  {/* <TableCell>
                    <Controls.ActionButton
                      color="default"
                      onClick={() => {
                        handleUserDetailsView(item);
                      }}
                    >
                      <VisibilityIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>
      <Popup title={'Add Sub Admin'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <UserAdd
          title="Edit Sub Admin"
          addorEdit={addorEdit}
          recordForEdit={recordForEdit}
          addUser={addUser}
          userDetailsView={userDetailsView}
        />
      </Popup>
    </>
  );
}
