import React from 'react';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import Page from '../../components/Page';
import AutocompleteTest from '../../components/Autocomplete';
import AutocompleteSelect from '../../components/AutocompleteSelect';
import {
  Card,
  Radio,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Grid,
  Breadcrumbs,
  Link,
  Stack,
  Box,
  TextField,
  Select,
  MenuItem,
  Container,
  Typography,
  CircularProgress,
  Autocomplete
} from '@mui/material';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import 'react-datepicker/dist/react-datepicker.css';
import { LoadingButton } from '@mui/lab';

import Controls from '../../components/control/Controls';
import { Backdrop } from '@mui/material';
import { Form } from '../../components/useForm';
import moment from 'moment';
import Popup from 'src/components/Popup';
import Coupon from './Coupon';
import useSession from 'src/hooks/useSession';
import dayjs from 'dayjs';

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const cdate = new Date();

export default function Add() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, user_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingDisable, setLoadingDisable] = useState(false);
  const [prescriptionDetail, setPrescriptionDetail] = useState(null);
  const [citiesData, setCitiesData] = useState([]);
  const [members, setMembers] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [memberId, setMemberId] = useState(null);
  const [addressId, setAddressId] = useState("");
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [collectionType, setCollectionType] = useState('Home');
  const [cityId, setCityId] = useState(null);
  const [panelId, setPanelId] = useState(null);
  const [centerId, setCenterId] = useState(null);
  const [testData, setTestData] = useState([]);
  const [collectionTest, setCollectionTest] = useState(null);
  const [centerData, setCenterData] = useState([]);
  const [testItems, setTestItems] = useState([]);
  const [formError, setFormError] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const advanceDate = cdate.addDays(3);
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [pickupCharge, setPickUpCharge] = useState(0);
  const [paymentMode, setPaymentMode] = useState('Cash');
  const [stateId, setStateId] = useState('');
  const [offerType, setOfferType] = useState('Coupon');
  const [code, setCode] = useState('');
  const [offerId, setOfferId] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [userDeatils, setUserDetails] = useState({});
  const [adminBookingSource, setAdminBookingSource] = useState([]);
  const [adminSourceType, setAdminSourceType] = useState("");
  const session = useSession();

  const setTestItemsData = async (items) => {
    let total_amount = 0;
    let grand_total = 0;
    let itemArr = [];

    for await (let item of items) {
      total_amount += parseFloat(item.Rate);
      itemArr.push({
        test_type: item.TestType,
        test_id: item.ItemID,
        id: item.Id,
        test_name: item.NAME,
        test_price: item.Rate
      });
    }
    const isPackageExist = itemArr.find((item) => item.test_type === 'Package');
    let testId = itemArr.find((o) => o.id === '2200');
    if ((collectionType === 'Home' && !isPackageExist) || testId) {
      getCollectionTest(itemArr);
    }
    if (isPackageExist) {
      setPickUpCharge(0);
    }
    grand_total = parseFloat(isPackageExist ? 0 : pickupCharge) + parseFloat(total_amount);
    setTotalAmount(total_amount);
    setGrandTotal(grand_total);
    setTestItems(itemArr);
  };

  const getCollectionTest = async (testArr) => {
    try {
      let homeCollectionTestId = '91';
      if (collectionType === 'Home') {
        let testId = testArr.find((o) => o.id === '2200');

        if (testId !== undefined) {
          homeCollectionTestId = '2119';
        }

        const response = await helper.postb(
          `/GetTestPackageDetails?CityId=${cityId}&PanelId=${panelId}&Id=${homeCollectionTestId}&Type=Test`
        );

        if (response.status === 200) {
          if (response.data.status_Code === 200) {
            const data = response.data.data;
            setCollectionTest({
              Test_Id: data.ItemID,
              Type: 'Test'
            });
            setPickUpCharge(data.Rate);
          }
        }
      } else {
      }
    } catch (error) {
      console.log(`error in finaly catch is ${error.message}`);
    }
  };

  useEffect(() => {
    if (totalAmount > 0) {
      setGrandTotal(parseFloat(totalAmount) + parseFloat(pickupCharge));
    } else {
      setGrandTotal(0);
    }
  }, [pickupCharge]);

  const getPrescriptionDetails = async () => {
    try {
      setLoading(true);
      const res = await helper.get(`${apiPath.user_prescription_details}/${id}`, session);
      if (res.status === 200) {
        setLoading(false);
        if (res.data.status === 200) {
          const addressDefault = res.data.data.user_address;
          setLatitude(addressDefault.latitude);
          setLongitude(addressDefault.longitude);
          setAddressId(res.data.data.user_address_id);
          setAddress(
            `${addressDefault.number},${addressDefault.area1}.${addressDefault.area2}, ${addressDefault.city} , ${addressDefault.state} , ${addressDefault.pincode}`
          );
          setCollectionType(res.data.data.collection_type);
          setPrescriptionDetail(res.data.data);
          setMemberId(res.data.data.member_id);
        }
      }
    } catch (e) {
      console.log(e);
    } finally{
      setLoading(false);
    }
  };

  const getUserFamilyMembers = async () => {
    try {
      const res = await helper.get(
        `${apiPath.user_family_members}?user_id=${user_id}&phone_number=${userDeatils?.phone_number}`,
        session
      );
      if (res.status === 200) {
        if (res.data.status === 200) {
          setMembers(res.data.data);
        }
      }
    } catch (error) {}
  };

  const getCityList = async () => {
    try {
      setLoading(true);
      const res = await helper.get(`${apiPath.get_all_cities}`);
      if (res.status === 200) {
        setLoading(false);
        if (res.data.status === 200) {
          setCitiesData(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getStateId = async () => {
    try {
      setLoading(true);
      const result = await helper.postb(`/GetStateByCityId?CityId=${cityId}`);
      // console.log(result);
      if (result.status === 200) {
        setStateId(result.data.data.StateId);
      }
    } catch (e) {
      console.log(e);
    } finally{
      setLoading(false);
    }
  };

  const getCentersByCity = async () => {
    try {
      setLoading(true);
      if (cityId && panelId) {
        await helper
          .postb(`/CentrebyGroupId?id=${cityId}&StateId=${stateId}&lat=26.8553&lon=75.8147`)
          .then(async (res) => {
            if (res.status === 200) {
              if (res.data.status_Code === 200) {
                setCenterData(res.data.data);
              }
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log(`error is ${error.message}`);
          });
      }
    } catch (error) {
      setLoading(false);
      console.log(`error in finaly catch is ${error.message}`);
    } finally{
      setLoading(false);
    }
  };

  const getTestPackagesList = async (value) => {
    try {
      setTestData([]);
      if (cityId && panelId) {
        if (value.length > 2) {
          setLoading(true);
          await helper
            .postb(
              `/GetFilterTestPackage?CityId=${cityId}&PanelId=${panelId}&SearchKeyword=${value}`
            )
            .then(async (res) => {
              setLoading(false);
              if (res.status === 200) {
                if (res.data.status_Code === 200) {
                  setTestData(res.data.data.itemmodel);
                }
              }
            })
            .catch((error) => {
              setLoading(false);
              console.log(`error is ${error.message}`);
            })
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(`error in finaly catch is ${error.message}`);
    } finally{
      setLoading(false);
    }
  };

  const [timeslots, setTimeSlots] = useState([]);
  const [bookingTime, setBookingTime] = useState(null);
  const bookingDateTime = startDate?.toISOString()?.split('T')[0];

  const getBookingTimeSlotByDate = async () => {
    try {
      let bookingDate = startDate.toISOString().split('T')[0];
      //console.log('bookingDate', bookingDate);
      const response = await helper.get(
        `${apiPath.booking_time_slots}?booking_date=${bookingDate}`
      );
      if (response.status === 200) {
        if (response.data.status === 200) {
          //setBookingTime(response.data.data[0]);
          setTimeSlots(response.data.data);
        }
      }
    } catch (error) {}
  };

  const getUserDetails = async () => {
    try {
      const res = await helper.get(`${apiPath.user_details}` + user_id, session);
      if (res.status === 200) {
        setUserDetails(res.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getAllAdminBookingSource = async () => {
    try {
      const res = await helper.get(`${apiPath.admin_booking_source}`, session);
      console.log(res, 'fsdkjfhkj');
      if (res.status === 200) {
        setAdminBookingSource(res.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  console.log(adminBookingSource, 'admin booking source -->>');

  useEffect(() => {
    if (user_id) {
      getUserDetails();
      getAllAdminBookingSource();
    }
  }, [user_id]);

  useEffect(() => {
    if (userDeatils && Object.keys(userDeatils).length) {
      getUserFamilyMembers();
    }
  }, [userDeatils]);

  useEffect(() => {
    getBookingTimeSlotByDate();
  }, [startDate]);

  useEffect(() => {
    if (cityId) {
      getStateId();
    }
  }, [cityId]);

  useEffect(() => {
    getCentersByCity();
  }, [stateId]);

  const getUserAddress = async () => {
    try {
      if (collectionType === 'Home') {
        const res = await helper.get(
          `${
            apiPath.get_address
          }?user_id=${user_id}&collection_type=${collectionType}&quickBooking=${true}&cityId=${cityId}`,
          session
        );
        if (res.status === 200) {
          if (res.data.status === 200) {
            const data = res.data.data;
            setAddressList(data);
          }
        }
      } else {
        setAddressList([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getUserAddress();
  }, [cityId]);

  useEffect(() => {
    getUserAddress();
    if (location && !location.state?.addBooking) {
      getPrescriptionDetails();
    }
    getCityList();
  }, [location]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const booking_date_time =
        collectionType === 'Home' ? `${bookingDateTime} ${bookingTime}` : bookingDateTime;

      const bookingRequest = {
        is_booking: true,
        user_id: user_id,
        prescriptions_id: id,
        booking_members: memberId,
        city_id: cityId,
        panel_id: panelId,
        collection_type: collectionType,
        test_items: testItems,
        collection_test: collectionTest,
        address_id: addressId,
        address: address,
        latitude: latitude,
        longitude: longitude,
        booking_date_time: booking_date_time,
        payment_mode: paymentMode,
        center_id: centerId,
        total_amount: totalAmount,
        final_amount: grandTotal,
        pickup_charge: pickupCharge,
        sourceType: 'web',
        adminBooking: location?.state?.addBooking ? true : false,
        adminBookingSource: adminSourceType
      };
      if (offerType === 'Coupon') {
        bookingRequest.membership_card_id = null;
        bookingRequest.MembershipCardID = null;
        bookingRequest.coupon = code;
        bookingRequest.discountType = offerType;
      } else {
        bookingRequest.coupon = null;
        bookingRequest.membership_card_id = code;
        bookingRequest.MembershipCardID = offerId;
        bookingRequest.discountType = offerType;
      }
      setLoading(true);
      const response = await helper.post(bookingRequest, apiPath.add_booking, session);

      if (response.status === 200) {
        setLoading(false);
        if (response.data.status === 422) {
          setFormError(response.data.errors);
        }
        if (response.data.status === 200) {
          toast('success', response.data.message);
          navigate('/bookings');
        }
      }
    } catch (error) {}
  };

  const checkBookingSlot = async (timeslot) => {
    try {
      setLoading(true);
      const res = await helper.post(
        {
          timeslot: `${bookingDateTime} ${timeslot}`
        },
        apiPath.checkTimeSlotAvailability,
        session
      );

      if (res.status === 200) {
        setLoading(false);
        if (res.data.status === 200) {
          setBookingTime(timeslot);
        } else {
          toast.error(res.data.message);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleCouponConfirm = (type, code, id) => {
    setOfferType(type);
    setOfferId(id);
    setCode(code);
  };

  return (
    <>
      <Page title={'Add Booking | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 999 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Add Bookings
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">Add Bookings</Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <Form onSubmit={handleSubmit} title="Add Booking | Dr. B. Lal">
            <Card className="MuiCardPadding">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Select Member</InputLabel>
                    {(memberId || location?.state?.addBooking) && (
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Member"
                        value={memberId}
                        error={formError.memberId}
                        helperText={formError.memberId}
                        onChange={(e) => {
                          setMemberId(e.target.value);
                        }}
                      >
                        {members.length > 0 &&
                          members.map((e, key) => {
                            return <MenuItem value={e.id}>{e.fullname}</MenuItem>;
                          })}
                      </Select>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Select City</InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Select City"
                      name="cities"
                      value={cityId}
                      error={formError.cityId}
                      helperText={formError.cityId}
                      onChange={(e, index, value) => {
                        setTestItems([]);
                        setCityId(e.target.value);
                        setPanelId(index.props.data_panel_id);
                      }}
                    >
                      {citiesData.length > 0 &&
                        citiesData.map((e, key) => {
                          return (
                            <MenuItem
                              key={key}
                              value={e.CityId}
                              data_city_id={e.CityId}
                              data_panel_id={e.Panel_ID}
                            >
                              {e.CityName}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Collection Type</InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Collection Type"
                      value={collectionType}
                      error={formError.collectionType}
                      onChange={(e) => {
                        if (e.target.value === 'Home') {
                          setCenterId(null);
                        }
                        if (e.target.value === 'Lab') {
                          setBookingTime(null);
                        }

                        setCollectionType(e.target.value);
                      }}
                      helperText={formError.collectionType}
                    >
                      <MenuItem value="Home">Home</MenuItem>
                      <MenuItem value="Lab">Lab</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      // renderInput={(props) => <TextField {...props} />}
                      label="Start Date"
                      minDate={dayjs(new Date())}
                      value={dayjs(startDate)}
                      onChange={(newValue) => {
                        setStartDate(dayjs(newValue));
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                {collectionType == 'Home' && (
                  <Grid item xs={6}>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">Booking Time</InputLabel>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Booking Time"
                        value={bookingTime}
                        onChange={(e) => {
                          checkBookingSlot(e.target.value);
                          //setBookingTime(e.target.value);
                        }}
                        error={formError.bookingTime ? formError.bookingTime : ''}
                      >
                        <MenuItem value={null}>Booking Time</MenuItem>
                        {timeslots.length > 0 &&
                          timeslots.map((e, key) => {
                            return <MenuItem value={e}>{e}</MenuItem>;
                          })}
                      </Select>
                      <FormHelperText error>
                        {formError.bookingTime && formError.bookingTime}
                      </FormHelperText>
                      {/* <span className="formError">{formError.bookingTime}</span> */}
                    </FormControl>
                  </Grid>
                )}
                {collectionType == 'Home' && (
                  <Grid item xs={6}>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label" shrink={true}>
                        Select Address
                      </InputLabel>
                      <Select
                        fullWidth
                        label="Select Address"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="address"
                        value={addressId}
                        error={formError.addressId}
                        helperText={formError.addressId}
                        onChange={(e, index, value) => {
                          setAddressId(e.target.value);
                          setLatitude(index.props.latitude);
                          setLongitude(index.props.longitude);
                          setAddress(index.props.address);
                        }}
                      >
                        {addressList.length > 0 &&
                          addressList.map((address, key) => {
                            return (
                              <MenuItem
                                key={key}
                                value={address.id}
                                latitude={address.latitude}
                                longitude={address.longitude}
                                address={`${address.number},${address.area1}.${address.area2}, ${address.city} , ${address.state} , ${address.pincode}`}
                              >{`${address.type} - ${address.number},${address.area1}.${address.area2}, ${address.city} , ${address.state} - ${address.pincode}`}</MenuItem>
                            );
                          })}
                      </Select>
                      {/* <span className="formError">{formError.addressId}</span> */}
                    </FormControl>
                  </Grid>
                )}
                {collectionType == 'Lab' && (
                  <Grid item xs={6}>
                    <AutocompleteSelect
                      setCenterId={(value) => {
                        setAddress(`${value.Address}, ${value.Address2}`);
                        setAddressId(value ? value.CentreId : null);
                        setCenterId(value ? value.CentreId : null);
                      }}
                      centerData={centerData}
                      error={formError.center_id ? formError.center_id : ''}
                      helperText={formError.center_id && formError.center_id}
                    />
                    {/* <span className="formError">{formError.center_id}</span> */}
                  </Grid>
                )}

                <Grid item xs={6}>
                  {/* <AutocompleteTest
                    setTestSetData={(value) => setTestItemsData(value)}
                    testData={testData}
                    error={formError.test_items ? formError.test_items : ''}
                    helperText={formError.test_items && formError.test_items}
                  /> */}

                  <Autocomplete
                    multiple
                    onChange={(event, value) => {
                      setTestItemsData(value);
                      setTestData([]);
                    }}
                    id="tags-outlined"
                    options={testData}
                    getOptionLabel={(option) => option.NAME + ' ( Rs.' + option.Rate + ' )'}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search test or package"
                        onChange={(e) => {
                          getTestPackagesList(e.target.value);
                        }}
                        error={formError.test_items ? formError.test_items : ''}
                        helperText={formError.test_items && formError.test_items}
                        placeholder="Search test or package"
                      />
                    )}
                  />
                  {/* <span className="formError">{formError.test_items}</span> */}
                </Grid>

                <Grid item xs={6}>
                  <Controls.Input
                    type="number"
                    name="total_amount"
                    readOnly
                    fullWidth
                    value={totalAmount}
                    label="Total Amount"
                    error={formError.total_amount ? formError.total_amount : ''}
                    helperText={formError.total_amount && formError.total_amount}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controls.Input
                    type="number"
                    name="pickup_amount"
                    fullWidth
                    value={pickupCharge}
                    onChange={(event) => {
                      if (event.target.value < 0 || !event.target.value) {
                        event.target.value = 0;
                        setPickUpCharge(event.target.value);
                      } else {
                        setPickUpCharge(event.target.value);
                      }
                    }}
                    label="Pickup Charge"
                    error={formError.pickup_charge ? formError.pickup_charge : ''}
                    helperText={formError.pickup_charge && formError.pickup_charge}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controls.Input
                    type="number"
                    name="grand_total"
                    readOnly
                    fullWidth
                    value={grandTotal}
                    label="Grand Total"
                    error={formError.final_amount ? formError.final_amount : ''}
                    helperText={formError.final_amount && formError.final_amount}
                  />
                  {/* <span className="formError">{formError.final_amount}</span> */}
                </Grid>
                <Grid item xs={6} className={'couponContainerAdd'}>
                  <Controls.Input
                    type="text"
                    name="code"
                    fullWidth
                    value={code}
                    label="Coupon code"
                    className={'couponField'}
                    disabled={false}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <Typography className={'viewCouponText'} onClick={() => setOpenPopup(true)}>
                    View Offers
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={paymentMode}
                    >
                      <FormControlLabel
                        value="Cash"
                        control={<Radio onChange={(e) => setPaymentMode('Cash')} />}
                        label="Cash"
                      />
                      <FormControlLabel
                        value="Online"
                        control={<Radio onChange={(e) => setPaymentMode('Online')} />}
                        label="Online"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label" shrink={true}>
                      Select Source Type
                    </InputLabel>
                    <Select
                      fullWidth
                      label="Select Source Type"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="sourceType"
                      value={adminSourceType}
                      error={formError.adminSourceType}
                      helperText={formError.adminSourceType}
                      onChange={(e) => {
                        setAdminSourceType(e.target.value);
                      }}
                    >
                      {adminBookingSource.length > 0 &&
                        adminBookingSource.map((source) => {
                          return <MenuItem value={source.id}>{source?.source}</MenuItem>;
                        })}
                    </Select>
                    {/* <span className="formError">{formError.addressId}</span> */}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <LoadingButton size="medium" type="submit" variant="contained">
                      Add Booking
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Form>
          <Popup title={'Offers'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
            <Coupon
              title="Offers"
              userId={user_id}
              handleCouponConfirm={handleCouponConfirm}
              setOpenPopup={setOpenPopup}
              collectionType={collectionType}
              locationId={cityId}
              memberId={memberId}
            />
          </Popup>
        </Container>
      </Page>
    </>
  );
}
