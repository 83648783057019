import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function ComboBox(props) {
    const {centerData, error, helperText} = props
   
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      onChange={(event, value) => props.setCenterId(value)} 
      options={centerData}
      getOptionLabel={(option) => option.Centre}
      sx={{ width: "100%" }}
      renderInput={(params) => <TextField {...params}  
      error={error}
      helperText={helperText} label="Select Center" />}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = []
  
