import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import Page from '../../components/Page';
import { Link as RouterLink } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Popup from 'src/components/Popup';
import CategoryAdd from './AddCategory';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';

import {
  Box,
  Stack,
  Grid,
  Breadcrumbs,
  Link,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';

import { Backdrop } from '@mui/material';
import useSession from 'src/hooks/useSession';

List.propTypes = {
  sx: PropTypes.object
};

export default function List({ sx }) {
  const [coreValues, setCoreValues] = useState([]);
  const session = useSession();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [addBlog, setAddBlog] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  let [loading, setLoading] = useState(false);

  const getCoreValue = async () => {
    try {
      const response = await helper.get(apiPath.get_core_values, session);
      if (response.status === 200) {
        if (response.data.status === 200) {
          setCoreValues(response.data.data);
        }
      }
    } catch (error) {}
  };

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        return target.value == ''
          ? items
          : items.filter((x) => x.name.toLowerCase().includes(target.value));
      }
    });
  };

  const headeCells = [
    { id: 'image', label: 'Image', disableSorting: true },
    { id: 'title', label: 'Title', disableSorting: true },
    { id: 'subtitle', label: 'Sub Title', disableSorting: true },
    { id: 'action', label: 'Action', disableSorting: true }
  ];

  useEffect(() => {
    getCoreValue();
  }, []);

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    coreValues,
    headeCells,
    filterFn
  );

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const addorEdit = async (data, id) => {
    setLoading(true);
    try {
      const response = await helper.post(data, apiPath.update_core_values, session);
      if (response.status === 200) {
        setLoading(false);
        if (response.data.success == true) {
          getCoreValue();
          setOpenPopup(false);
          toast.success(response.data.message);
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const handleCategoryDelete = async (item) => {
    try {
      let res = await helper.delete(`${apiPath.delete_core_value}/${item.id}`, session);
      if (res.status === 200) {
        getCoreValue();
        toast.success(res.data.api_name);
      }
    } catch (e) {
      toast.error('Error', e);
    }
  };

  return (
    <>
      <Page title={'Why Choose BLal | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 999 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Why Choose BLal
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">Why Choose BLal</Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <Toolbar>
            {/* <Controls.Input
              label="Search Blogs"
              className={classes.searchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
              onChange={handleSearch}
            /> */}

            <Controls.Button
              text="Add New Award"
              variant="outlined"
              startIcon={<AddIcon />}
              className='newButton'
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
                setAddBlog(true);
              }}
            />
          </Toolbar>

          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <Box
                      component="img"
                      src={`${item.image}`}
                      sx={{ width: 100, height: 50, ...sx }}
                    />
                  </TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.subtitle}</TableCell>
                  <TableCell>
                    <Controls.ActionButton
                      color="primary"
                      onClick={() => {
                        openInPopup(item);
                        setEditCategory(true);
                      }}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </Controls.ActionButton>

                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        handleCategoryDelete(item);
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>

      <Popup
        title={`${recordForEdit ? 'Edit Award Banner' : 'Add Award Banner'}`}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <CategoryAdd
          title="Edit Award Banner"
          recordForEdit={recordForEdit}
          addorEdit={addorEdit}
          loadState={loading}
        />
      </Popup>
    </>
  );
}
