import { useState, useEffect, Fragment } from 'react';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import Page from '../../components/Page';
import { Link as RouterLink } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Popup from 'src/components/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import UserAdd from './Add';
import { useNavigate } from 'react-router-dom';

import {
  Stack,
  Grid,
  TextField,
  Box,
  Breadcrumbs,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';

import { Backdrop } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import DateRangePicker from '@mui/lab/DateRangePicker';
import useSession from 'src/hooks/useSession';
import dayjs from 'dayjs'

export default function List() {
  let navigate = useNavigate();
  // const [filterFn, setFilterFn] = useState({ fn: (items) => {return items; } });

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const session = useSession();
  const [usersData, setUsersData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [addUser, setAddUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [userDetailsView, setUserDetailsView] = useState(false);
  const [citiesData, setCitiesData] = useState([]);
  const [cityId, setCityId] = useState('all');
  const [selectedDate, handleDateChange] = useState([null, null]);
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  let [loading, setLoading] = useState(false);

  const [searchKeyword, setSearchKeyword] = useState('');
  const handleSearch = async (e) => {
    let target = e.target;
    setSearchKeyword(target.value);
    if (target.value && target.value.length > 3) {
      await getUsersData(target.value);
    } else if (!target.value) {
      await getUsersData(target.value);
    }
  };

  const getUsersData = async (searchText) => {
    try {
      setLoading(true);
      const res = await helper.get(
        `${apiPath.users_list}?size=20&city_id=${cityId}&dates=${[startDate,endDate]}&search_keyword=${searchText ? searchText : ''
        }`,
        session
      );
      if (res.status === 200) {
        setUsersData(res.data.data.docs);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getCityList = async () => {
    try {
      const res = await helper.get(`${apiPath.get_all_cities}`);
      if (res.data.status === 200) {
        setCitiesData(res.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCityList();
    modulePermissions();
  }, []);

  useEffect(() => {
    getUsersData();
  }, [cityId, startDate, endDate]);

  const [permission, setPermission] = useState({
    create: false,
    view: false,
    update: false,
    delete: false
  });

  const modulePermissions = () => {
    const modules = session?.profile?.user?.role?.role_rights || [];

    if (session?.profile?.user?.role?.id === 1) {
      setPermission({
        create: true,
        view: true,
        update: true,
        delete: true
      });
    } else {
      let createObj = modules.find((obj) => obj.module.tbl_name === 'users' && obj[`r_create`]);

      if (createObj) {
        permission.create = true;
      }
      let viewObj = modules.find((obj) => obj.module.tbl_name === 'users' && obj[`r_view`]);
      if (viewObj) {
        permission.view = true;
      }
      let updateObj = modules.find((obj) => obj.module.tbl_name === 'users' && obj[`r_update`]);
      if (updateObj) {
        permission.update = true;
      }
      let deleteObj = modules.find((obj) => obj.module.tbl_name === 'users' && obj[`r_delete`]);
      if (deleteObj) {
        permission.delete = true;
      }
    }
  };

  const headeCells = [
    { id: 'fullname', label: 'User', disableSorting: true },
    { id: 'phone_number', label: 'Contact No', disableSorting: true },
    // { id: 'age', label: 'Age', disableSorting: true },
    { id: 'email', label: 'Email', disableSorting: true },
    { id: 'dob', label: 'Dob', disableSorting: true },
    { id: 'gender', label: 'Gender', disableSorting: true },
    { id: 'user_addresses', label: 'Location', disableSorting: true },
    { id: 'created_at', label: 'Created At', disableSorting: true },
  ];

  if (permission.view) {
    headeCells.push({ id: 'action', label: 'Action', disableSorting: true });
  }

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    usersData,
    headeCells,
    filterFn,
    searchKeyword
  );

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const addorEdit = async (data, id, checkUser) => {
    setLoading(true);
    try {
      if (recordForEdit == null) {
        const response = await helper.post(
          data,
          !checkUser ? `${apiPath.create_user}?check_phone=${true}` : apiPath.create_user,
          session
        );
        if (response.data.success == true) {
          if (response.data.message !== 'add user') {
            getUsersData();
            setOpenPopup(false);
            toast.success(response.data.message);
            return response;
          } else {
            setLoading(false);
            return response;
          }
        } else {
          setLoading(false);
          toast.error(response.data.message);
          return response;
        }
      } else {
        console.log('in else part');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('Error', error);
    }
  };

  const handleUserDetailsView = async (item) => {
    setUserDetailsView(item);
    setRecordForEdit(false);
    setAddUser(false);
    navigate(`/users/${item.id}`);
  };

  return (
    <>
      <Page title={'Users List | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Users List
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">All Users </Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <Toolbar>
            <Stack direction="row" style={{ justifyContent: 'space-between' }}>
              <Controls.Input
                style={{ width: '30%' }}
                label="Search Users"
                className={'searchInput'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
                onChange={handleSearch}
              />

              <FormControl sx={{ m: 1, width: 150, marginTop: '0%' }}>
                <InputLabel id="demo-simple-select-label">Select Location</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select City"
                  value={cityId}
                  onChange={(e) => {
                    setCityId(e.target.value);
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  {citiesData.length > 0 &&
                    citiesData.map((e, key) => {
                      return <MenuItem value={e.CityId}>{e.CityName}</MenuItem>;
                    })}
                </Select>
              </FormControl>
              <div style={{ display: 'flex', alignItems:"center", gap: '15px' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // renderInput={(props) => <TextField {...props} />}
                    format="DD/MM/YYYY"
                    label="From Date"
                    maxDate={dayjs(new Date())}
                    value={dayjs(startDate)}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                  />
                  <span>to</span>
                  <DatePicker
                    // renderInput={(props) => <TextField {...props} />}
                    format="DD/MM/YYYY"
                    label="To Date"
                    maxDate={dayjs(new Date())}
                    value={dayjs(endDate)}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                    }}
                  />
                </LocalizationProvider>
              </div>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  startText="FROM DATE"
                  endText="TO DATE"
                  value={selectedDate}
                  inputFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  onChange={(newValue) => {
                    handleDateChange(newValue);
                  }}
                  renderInput={(startProps, endProps) => (
                    <Fragment>
                      <TextField {...startProps} />
                      <Box sx={{ mx: 2 }}> to </Box>
                      <TextField {...endProps} />
                    </Fragment>
                  )}
                />
              </LocalizationProvider> */}

              {permission.create && (
                <Controls.Button
                  text="Add New User"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  className={'newButton'}
                  onClick={() => {
                    setOpenPopup(true);
                    setRecordForEdit(null);
                    setAddUser(true);
                  }}
                />
              )}
            </Stack>
          </Toolbar>

          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.fullname}</TableCell>
                  <TableCell>{item.phone_number}</TableCell>
                  {/* <TableCell>{item.age}</TableCell> */}
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{moment(item.dob).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{item.gender}</TableCell>
                  <TableCell>
                    {item.user_addresses.length > 0
                      ? `${item.user_addresses[0].type} - ${item.user_addresses[0].number}, ${item.user_addresses[0].area1}, ${item.user_addresses[0].area2}, ${item.user_addresses[0].city} (${item.user_addresses[0].state}) ,${item.user_addresses[0].pincode}`
                      : '-'}
                  </TableCell>
                  <TableCell>{moment(item.created_at).format('DD/MM/YYYY')}</TableCell>
                  {permission.view && (
                    <TableCell>
                      <Controls.ActionButton
                        color="default"
                        onClick={() => {
                          handleUserDetailsView(item);
                        }}
                      >
                        <VisibilityIcon fontSize="small" />
                      </Controls.ActionButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>
      <Popup title={'Users Management'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <UserAdd
          title="Edit Blog"
          addorEdit={addorEdit}
          recordForEdit={recordForEdit}
          addUser={addUser}
          userDetailsView={userDetailsView}
        />
      </Popup>
    </>
  );
}
