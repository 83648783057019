import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import Page from '../../components/Page';
import { Link as RouterLink } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Popup from 'src/components/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TableContainer from '@mui/material/TableContainer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  Card,
  Box,
  Table,
  Stack,
  Paper,
  Grid,
  Breadcrumbs,
  Link,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import { Backdrop } from '@mui/material';
import moment from 'moment';
import HappyCustomersAdd from './Add';
import useSession from 'src/hooks/useSession';

HappyCustomers.propTypes = {
  sx: PropTypes.object
};

export default function HappyCustomers({ sx }) {
  const [happyCustomersData, setHappyCustomersData] = useState([]);
  const [addHappyCustomers, setAddHappyCustomers] = useState(false);
  const [editHappyCustomers, setEditHappyCustomers] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const session = useSession();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [viewEntry, setViewEntry] = useState({});
  const [loading, setLoading] = useState(false);

  const getHappyCustomersData = async () => {
    try {
      const res = await helper.get(`${apiPath.get_happy_customers_list}`, session);
      if (res.status === 200) {
        if (res.data.success === true) {
          setHappyCustomersData(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [searchKeyword, setSearchKeyword] = useState('');
  const handleSearch = (e) => {
    let target = e.target;
    setSearchKeyword(target.value);
    setFilterFn({
      fn: (items) => {
        return target.value == ''
          ? items
          : items.filter((x) => x.name.toLowerCase().includes(target.value));
      }
    });
  };

  const headeCellsHappyCustomers = [
    { id: 'name', label: 'Name', disableSorting: true },
    { id: 'description', label: 'Description', disableSorting: true },
    { id: 'file/image', label: 'Video/Image', disableSorting: true },
    { id: 'date', label: 'Created At', disableSorting: true },
    { label: 'View/Delete', disableSorting: true }
  ];
  useEffect(() => {
    getHappyCustomersData();
  }, []);

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    happyCustomersData,
    headeCellsHappyCustomers,
    filterFn,
    searchKeyword
  );

  const handleHappyCustomersDelete = async (item) => {
    try {
      let res = await helper.delete(`${apiPath.delete_happy_customers}/${item.id}`, session);
      if (res.status === 200) {
        getHappyCustomersData();
        toast.success(res.data.message);
      }
    } catch (e) {
      toast.error('Error', e);
      console.log(e);
    }
  };

  const handleClick = async (item) => {
    setOpenPopup(true);
    try {
      let res = await helper.get(`${apiPath.get_happy_customers_list_byid}/${item.id}`, session);
      if (res.status === 200) {
        setViewEntry(res.data.data);
      }
    } catch (e) {
      toast.error('Error', e);
    }
  };

  const addPopup = async (item) => {
    setOpenAddPopup(true);
    setRecordForEdit(item);
  };

  const addorEdit = async (data, id) => {
    setLoading(true);
    try {
      if (recordForEdit == null) {
        const res = await helper.post(data, apiPath.create_happy_customers, session);
        if (res.data.success == true) {
          getHappyCustomersData();
          setOpenAddPopup(false);
          toast.success(res.data.message);
        } else {
          setLoading(false);
          return res;
        }
      } else {
        const res = await helper.put(data, `${apiPath.update_happy_customers}/${id}`, session);
        if (res.status === 200) {
          setLoading(false);
          if (res.data.success == true) {
            getHappyCustomersData();
            setOpenAddPopup(false);
            toast.success(res.data.message);
          } else {
            return res;
          }
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  };
  return (
    <>
      <Page title={'Happy Customers | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Happy Customers
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">All Happy Customers</Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <Toolbar>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Controls.Input
                style={{ width: '70%' }}
                label="Search Happy Customers"
                className={'searchInput'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
                onChange={handleSearch}
              />
              <Controls.Button
                text="Add New Happy Customer"
                variant="outlined"
                startIcon={<AddIcon />}
                className={'newButton'}
                onClick={() => {
                  addPopup();
                  setRecordForEdit(null);
                  setAddHappyCustomers(true);
                }}
              />
            </Stack>
          </Toolbar>
          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  {item.fileTag === 'image' ? (
                    <TableCell>
                      <Box
                        component="img"
                        src={item.fileUrl}
                        sx={{ width: 100, height: 50, ...sx }}
                      />
                    </TableCell>
                  ) : (
                    <TableCell>{item.fileUrl}</TableCell>
                  )}
                  <TableCell>{moment(item.created_at).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>
                    <Toolbar>
                      <Controls.ActionButton
                        color="primary"
                        onClick={() => {
                          addPopup(item);
                          setEditHappyCustomers(true);
                        }}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </Controls.ActionButton>
                      <Controls.ActionButton color="primary" onClick={() => handleClick(item)}>
                        <VisibilityIcon fontSize="small" />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          handleHappyCustomersDelete(item);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </Controls.ActionButton>
                    </Toolbar>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>
      <Popup title={'Happy Customers'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <Card>
          <CardHeader title="Details" />
          <CardContent>
            <TableContainer component={Paper}>
              <Table className={'table'} aria-label="simple table">
                <TableBody>
                  <TableRow key="Name">
                    <TableCell component="th" scope="row">
                      Name
                    </TableCell>
                    <TableCell align="left">{viewEntry.name}</TableCell>
                  </TableRow>
                  <TableRow key="Description">
                    <TableCell component="th" scope="row">
                      Description
                    </TableCell>
                    <TableCell align="left">{viewEntry.description}</TableCell>
                  </TableRow>
                  {viewEntry.fileTag === 'image' ? (
                    <TableRow key="Image">
                      <TableCell component="th" scope="row">
                        Image
                      </TableCell>
                      <TableCell align="left">
                        <img src={viewEntry.fileUrl} alt="" />
                      </TableCell>
                    </TableRow>
                  ) : viewEntry.fileTag === 'video' ? (
                    <TableRow key="Video">
                      <TableCell component="th" scope="row">
                        Video
                      </TableCell>
                      <TableCell align="left">{viewEntry.fileUrl}</TableCell>
                    </TableRow>
                  ) : (
                    <p>1</p>
                  )}
                  {
                    viewEntry.other_images ? Object.entries(viewEntry.other_images).map((i) => {
                      return  <TableRow key={`${i[0]}`}>
                      <TableCell component="th" scope="row">
                        {i[0]}
                      </TableCell>
                      <TableCell align="left">
                        <img src={i[1]} alt="" />
                      </TableCell>
                    </TableRow>
                    }) : null
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Popup>
      <Popup title={'Happy Customers'} openPopup={openAddPopup} setOpenPopup={setOpenAddPopup}>
        {addHappyCustomers || editHappyCustomers ? (
          <HappyCustomersAdd
            title="Edit Happy Customers"
            recordForEdit={recordForEdit}
            addorEdit={addorEdit}
          />
        ) : (
          <p>1</p>
        )}
      </Popup>
    </>
  );
}
