import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import Page from '../../components/Page';
import { Link as RouterLink } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Popup from 'src/components/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BannerAdd from './Add';
import {
  Stack,
  Paper,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Breadcrumbs,
  Link,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import { Backdrop } from '@mui/material';
import moment from 'moment';
import useSession from 'src/hooks/useSession';

List.propTypes = {
  sx: PropTypes.object
};

export default function List({ sx }) {
  const [bannerData, setBannerData] = useState([]);
  const session = useSession();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [addBanner, setAddBanner] = useState(false);
  const [editBanner, setEditBanner] = useState(false);
  const [bannerImage, setBannerImage] = useState(null);
  const [viewBanner, setViewBanner] = useState(false);
  const [bannerType, setBannerType] = useState('all');
  const [loading, setLoading] = useState(false);
  const [appType, setAppType] = useState('doctorApp');

  const getBannersData = async () => {
    try {
      const res = await helper.get(`${apiPath.banner_list}?banner_type=${bannerType}&app_check=${appType}`, session);

      if (res.status === 200) {
        if (res.data.success === true) {
          setBannerData(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const [searchKeyword, setSearchKeyword] = useState('');
  const handleSearch = (e) => {
    let target = e.target;
    setSearchKeyword(target.value);
    setFilterFn({
      fn: (items) => {
        return target.value == ''
          ? items
          : items.filter((x) => x.title.toLowerCase().includes(target.value));
      }
    });
  };

  const headeCellsCustomerApp = [
    { id: 'image', label: 'Mobile Banner', disableSorting: true },
    { id: 'image_web', label: 'Website Banner', disableSorting: true },
    { id: 'title', label: 'Title', disableSorting: true },
    { id: 'type', label: 'Banner Type', disableSorting: true },
    { id: 'link_type', label: 'Link Type', disableSorting: true },
    { id: 'link_value', label: 'Link Value', disableSorting: true },
    { id: 'priority', label: 'Priority', disableSorting: true },
    { id: 'date', label: 'Created At', disableSorting: true },
    { id: 'action', label: 'Action', disableSorting: true }
  ];

  const headeCellsDoctorApp = [
    { id: 'image', label: 'Mobile Banner', disableSorting: true },
    { id: 'title', label: 'Title', disableSorting: true },
    { id: 'type', label: 'Banner Type', disableSorting: true },
    { id: 'link_type', label: 'Link Type', disableSorting: true },
    { id: 'link_value', label: 'Link Value', disableSorting: true },
    { id: 'date', label: 'Created At', disableSorting: true },
    { id: 'action', label: 'Action', disableSorting: true }
  ]

  useEffect(() => {
    getBannersData();
  }, [bannerType]);

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    bannerData,
    appType === 'doctorApp' ? headeCellsDoctorApp : headeCellsCustomerApp,
    filterFn,
    searchKeyword
  );

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const addorEdit = async (data, id) => {
    setLoading(true);
    try {
      if (recordForEdit == null) {
        const res = await helper.post(data, apiPath.create_banner, session);
        if (res.data.success == true) {
          getBannersData();
          setOpenPopup(false);
          toast.success(res.data.api_name);
        } else {
          setLoading(false);
          toast.error(res.data.api_name);
          return res;
        }
      } else {
        const res = await helper.put(data, `${apiPath.updtae_banner_details}/${id}`, session);
        if (res.status === 200) {
          setLoading(false);
          if (res.data.success == true) {
            getBannersData();
            setOpenPopup(false);
            toast.success(res.data.api_name);
          } else {
            toast.error(res.data.api_name);
            return res;
          }
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  };

  const handleBlogView = async (item) => {
    setBannerImage(item.image);
    setEditBanner(false);
    setAddBanner(false);
    setViewBanner(true);
    setOpenPopup(true);
  };

  const handleBannerDelete = async (item) => {
    try {
      let res = await helper.delete(`${apiPath.delete_banner}/${item.id}`, session);
      if (res.status === 200) {
        getBannersData();
        toast.success(res.data.api_name);
      }
    } catch (e) {
      toast.error('Error', e);
    }
  };

  useEffect(() => {
    getBannersData();
  }, [appType])

  return (
    <>
      <Page title={'Banner List | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Banners
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">All banner </Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <Toolbar>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Controls.Input
                style={{ width: '70%' }}
                label="Search Banner"
                className={'searchInput'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
                onChange={handleSearch}
              />

              <FormControl sx={{ m: 1, width: 150, marginTop: '01%' }}>
                <InputLabel id="demo-simple-select-label">Banner Type</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Banner Type"
                  value={bannerType}
                  onChange={(e) => {
                    setBannerType(e.target.value);
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="Home">Home</MenuItem>
                  <MenuItem value="Blog">Blog</MenuItem>
                  <MenuItem value="Partner">Partner With Us</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, width: 150, marginTop: '01%' }}>
                <InputLabel id="demo-simple-select-label">App Type</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="App Type"
                  value={appType}
                  onChange={(e) => {
                    setAppType(e.target.value);
                  }}
                >
                  <MenuItem value="doctorApp">Doctor App</MenuItem>
                  <MenuItem value="customerApp">Customer App</MenuItem>
                </Select>
              </FormControl>

              <Controls.Button
                text="Add New Banner"
                variant="outlined"
                startIcon={<AddIcon />}
                className={'newButton'}
                onClick={() => {
                  setOpenPopup(true);
                  setRecordForEdit(null);
                  setAddBanner(true);
                }}
              />
            </Stack>
          </Toolbar>

          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <Box
                      component="img"
                      onClick={() => {
                        handleBlogView(item);
                      }}
                      src={appType === 'doctorApp' ? `${item.image_doctor_app}` : `${item.image}`}
                      sx={{ width: 100, height: 50, ...sx }}
                    />
                  </TableCell>
                  {appType !== 'doctorApp' ?
                    <TableCell>
                      <Box
                        component="img"
                        onClick={() => {
                          handleBlogView(item);
                        }}
                        src={`${item.image_web}`}
                        sx={{ width: 100, height: 50, ...sx }}
                      />
                    </TableCell>
                    : ""}
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>{item.link_type}</TableCell>
                  <TableCell>{item.link_value}</TableCell>
                  {appType !== 'doctorApp' ?
                    <TableCell>{item.priority}</TableCell> : ""
                  }
                  <TableCell>{moment(item.created_at).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>
                    <Controls.ActionButton
                      color="primary"
                      onClick={() => {
                        openInPopup(item);
                        setEditBanner(true);
                      }}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </Controls.ActionButton>

                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        handleBannerDelete(item);
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>
      <Popup title={'Banner Management'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
        {addBanner || editBanner ? (
          <BannerAdd title="Edit Blog" recordForEdit={recordForEdit} addorEdit={addorEdit} />
        ) : (
          <Paper variant="outlined">
            <img src={bannerImage} alt="login" />
          </Paper>
        )}
      </Popup>
    </>
  );
}
