import React from 'react';
import { useState, useEffect } from 'react';
import {
    Grid,
    Box
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';
import useSession from 'src/hooks/useSession';

export default function Add(props) {
    let { addorEdit, recordForEdit } = props;
    const [id, setId] = useState('');
    const [patientName, setPatientName] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [email, setEmail] = useState('');
    const [comments, setComments] = useState('');
    const [referByDoctor, setReferByDoctor] = useState('');
    const [referPatientFormError, setReferPatientFormError] = useState({});
    const [loadingDisable, setLoadingDisable] = useState(false);
    const session = useSession();

    const handleSubmit = async (e) => {
        setLoadingDisable(true);
        e.preventDefault();
        let obj = {
            patient_name: patientName,
            mobileno: mobileNo,
            email: email,
            comments: comments,
            referred_by_doctor: referByDoctor
        }
        if (id) {
            obj.id = id;
        }
        let response = await addorEdit(obj, id);

        if (response && response.data && response.data.errors) {
            setReferPatientFormError(response.data.errors);
            setLoadingDisable(false);
        }
    };

    useEffect(() => {
        if (recordForEdit) {
            setId(recordForEdit.id);
            setPatientName(recordForEdit.patient_name);
            setMobileNo(recordForEdit.mobileno);
            setEmail(recordForEdit.email);
            setComments(recordForEdit.comments);
            setReferByDoctor(recordForEdit.referred_by_doctor);
        }
    }, [recordForEdit])

    return (
        <Form onSubmit={handleSubmit} title="Add Refer Patient | Dr. B. Lal">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Controls.Input
                        name="patientName"
                        fullWidth
                        label="Patient Name"
                        value={patientName}
                        onChange={(e) => setPatientName(e.target.value)}
                        error={referPatientFormError?.patient_name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controls.Input
                        name="mobileno"
                        fullWidth
                        label="Mobile Number"
                        value={mobileNo}
                        onChange={(e) => setMobileNo(e.target.value)}
                        error={referPatientFormError?.mobileno}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controls.Input
                        name="email"
                        fullWidth
                        type="email"
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={referPatientFormError?.email}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controls.Input
                        name="comments"
                        fullWidth
                        label="Comments"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                        error={referPatientFormError?.comments}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controls.Input
                        name="referByDoctor"
                        fullWidth
                        label="Refer By Doctor"
                        value={referByDoctor}
                        onChange={(e) => setReferByDoctor(e.target.value)}
                        error={referPatientFormError?.referred_by_doctor}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box>
                    <LoadingButton
                        size="medium"
                        type="submit"
                        variant="contained"
                        endIcon
                        disabled={loadingDisable}
                    >
                        {id ? 'Update' : 'Add'}
                    </LoadingButton>
                </Box>
            </Grid>
        </Form>
    );
}
