import React from 'react';
import { useState, useEffect } from 'react';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import Page from '../../components/Page';
import { Link as RouterLink } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import { Search } from '@mui/icons-material';
import Popup from 'src/components/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import TableContainer from '@mui/material/TableContainer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Card,
  Table,
  Stack,
  Paper,
  Grid,
  Breadcrumbs,
  Link,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import { Backdrop } from '@mui/material';
import moment from 'moment';
import useSession from 'src/hooks/useSession';

export default function LabAcquisitionComponent() {
  const [labAcquisitionData, setLabAcquisitionData] = useState([]);
  const session = useSession();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [viewEntry, setViewEntry] = useState({});
  const [loading, setLoading] = useState(false);
  const [formType, setFormType] = useState('franchise');

  const getFranchisingOpportunityData = async () => {
    try {
      const res = await helper.get(`${apiPath.get_franchise_opportunity_list}`, session);

      if (res.status === 200) {
        if (res.data.success === true) {
          setFranchisingOpportunityData(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getLabAcquisitionData = async () => {
    try {
      const res = await helper.get(`${apiPath.get_lab_acquisition_list}`, session);

      if (res.status === 200) {
        if (res.data.success === true) {
          setLabAcquisitionData(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getHospitalLabManagementData = async () => {
    try {
      const res = await helper.get(
        `${apiPath.get_hospital_lab_management_list}?type=${
          formType === 'hospitallabmanagement' ? 'hospital lab management' : 'healthy dafter'
        }`,
        session
      );

      if (res.status === 200) {
        if (res.data.success === true) {
          setHospitalLabManagementData(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getRegionalPartnerData = async () => {
    try {
      const res = await helper.get(`${apiPath.get_regional_partner_list}`, session);

      if (res.status === 200) {
        if (res.data.success === true) {
          setRegionalPartnerData(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [searchKeyword, setSearchKeyword] = useState('');
  const handleSearch = (e) => {
    let target = e.target;
    setSearchKeyword(target.value);
    setFilterFn({
      fn: (items) => {
        return target.value == ''
          ? items
          : items.filter((x) => x.lab_name.toLowerCase().includes(target.value));
      }
    });
  };

  const headeCellsLabAcquisition = [
    { id: 'lab_name', label: 'Lab Name', disableSorting: true },
    { id: 'owners_name', label: 'Owner Name', disableSorting: true },
    { id: 'age', label: 'Contact Number', disableSorting: true },
    { id: 'contact_number', label: 'Contact Number', disableSorting: true },
    { id: 'alternate_contact_number', label: 'Alternate Contact Number', disableSorting: true },
    { id: 'email', label: 'Email', disableSorting: true },
    { id: 'city', label: 'City', disableSorting: true },
    { id: 'state', label: 'State', disableSorting: true },
    { id: 'area', label: 'Area', disableSorting: true },
    { id: 'pan_no', label: 'Pan No', disableSorting: true },
    { id: 'space', label: 'Space', disableSorting: true },
    { id: 'investment', label: 'Investment', disableSorting: true },
    { id: 'experience', label: 'Experience', disableSorting: true },
    { id: 'date', label: 'Created At', disableSorting: true },
    { label: 'View/Delete', disableSorting: true }
  ];

  useEffect(() => {
    getLabAcquisitionData();
  }, []);

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    labAcquisitionData,
    headeCellsLabAcquisition,
    filterFn,
    searchKeyword
  );

  const openInPopup = (item) => {
    setOpenPopup(true);
  };

  const handleBlogView = async (item) => {
    setOpenPopup(true);
  };

  const handleLabAcquisitonDelete = async (item) => {
    try {
      let res = await helper.delete(`${apiPath.delete_lab_acquisition}/${item.id}`, session);
      if (res.status === 200) {
        getLabAcquisitionData();
        toast.success(res.data.message);
      }
    } catch (e) {
      toast.error('Error', e);
    }
  };

  const handleClick = async (item) => {
    setOpenPopup(true);
    try {
      let res = await helper.get(`${apiPath.get_lab_acquisition_list_byid}/${item.id}`, session);
      if (res.status === 200) {
        setViewEntry(res.data.data);
      }
    } catch (e) {
      toast.error('Error', e);
    }
  };
  return (
    <>
      <Page title={'Lab Acquisition List | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Lab Acquisition
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">All Lab Acquisition</Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <Toolbar>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Controls.Input
                style={{ width: '70%' }}
                label="Search Lab Acquisition"
                className={'searchInput'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
                onChange={handleSearch}
              />
            </Stack>
          </Toolbar>
          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.lab_name}</TableCell>
                  <TableCell>{item.owners_name}</TableCell>
                  <TableCell>{item.age}</TableCell>
                  <TableCell>{item.contact_number}</TableCell>
                  <TableCell>{item.alternate_contact_number}</TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{item.city}</TableCell>
                  <TableCell>{item.state}</TableCell>
                  <TableCell>{item.area}</TableCell>
                  <TableCell>{item.pan_no}</TableCell>
                  <TableCell>{item.space}</TableCell>
                  <TableCell>{item.investment}</TableCell>
                  <TableCell>{item.experience}</TableCell>

                  <TableCell>{moment(item.created_at).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>
                    <Toolbar>
                      <Controls.ActionButton color="primary" onClick={() => handleClick(item)}>
                        <VisibilityIcon fontSize="small" />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          handleLabAcquisitonDelete(item);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </Controls.ActionButton>
                    </Toolbar>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>
      <Popup title={'Lab Acquistion'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <Card>
          <CardHeader title="Details" />
          <CardContent>
            <TableContainer component={Paper}>
              <Table className={'table'} aria-label="simple table">
                <TableBody>
                  <TableRow key="Lab Name">
                    <TableCell component="th" scope="row">
                      Lab Name
                    </TableCell>
                    <TableCell align="left">{viewEntry.lab_name}</TableCell>
                  </TableRow>
                  <TableRow key="Owner's Name">
                    <TableCell component="th" scope="row">
                      Owner's Name
                    </TableCell>
                    <TableCell align="left">{viewEntry.owners_name}</TableCell>
                  </TableRow>
                  <TableRow key="Age">
                    <TableCell component="th" scope="row">
                      Age
                    </TableCell>
                    <TableCell align="left">{viewEntry.age}</TableCell>
                  </TableRow>
                  <TableRow key="Contact Number">
                    <TableCell component="th" scope="row">
                      Contact Number
                    </TableCell>
                    <TableCell align="left">{viewEntry.contact_number}</TableCell>
                  </TableRow>
                  <TableRow key="Alternate Contact Number">
                    <TableCell component="th" scope="row">
                      Alternate Contact Number
                    </TableCell>
                    <TableCell align="left">{viewEntry.alternate_contact_number}</TableCell>
                  </TableRow>
                  <TableRow key="Email">
                    <TableCell component="th" scope="row">
                      Email
                    </TableCell>
                    <TableCell align="left">{viewEntry.email}</TableCell>
                  </TableRow>
                  <TableRow key="City">
                    <TableCell component="th" scope="row">
                      City
                    </TableCell>
                    <TableCell align="left">{viewEntry.city}</TableCell>
                  </TableRow>
                  <TableRow key="State">
                    <TableCell component="th" scope="row">
                      State
                    </TableCell>
                    <TableCell align="left">{viewEntry.state}</TableCell>
                  </TableRow>
                  <TableRow key="area">
                    <TableCell component="th" scope="row">
                      Area
                    </TableCell>
                    <TableCell align="left">{viewEntry.area}</TableCell>
                  </TableRow>
                  <TableRow key="pan_no">
                    <TableCell component="th" scope="row">
                      Pan No
                    </TableCell>
                    <TableCell align="left">{viewEntry.pan_no}</TableCell>
                  </TableRow>
                  <TableRow key="Space">
                    <TableCell component="th" scope="row">
                      Space
                    </TableCell>
                    <TableCell align="left">{viewEntry.space}</TableCell>
                  </TableRow>
                  <TableRow key="Investment">
                    <TableCell component="th" scope="row">
                      Investment
                    </TableCell>
                    <TableCell align="left">{viewEntry.investment}</TableCell>
                  </TableRow>
                  <TableRow key="Experience">
                    <TableCell component="th" scope="row">
                      Experience
                    </TableCell>
                    <TableCell align="left">{viewEntry.experience}</TableCell>
                  </TableRow>
                  <TableRow key="Comment">
                    <TableCell component="th" scope="row">
                      Comment
                    </TableCell>
                    <TableCell align="left">{viewEntry.comment}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Popup>
    </>
  );
}
