import { useEffect, useState } from 'react'
import Page from '../../components/Page';
import { TextField, MenuItem, Box, Stack, Container, FormControl, Select, InputLabel } from '@mui/material';
import { Form } from '../../components/useForm';
import { LoadingButton } from '@mui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import helper from 'src/constants/helper';
import apiPath from 'src/constants/apiPath';
import { toast } from 'react-toastify';
import dayjs from 'dayjs'

const timeSlots = ["06:30 AM", "07:00 AM", "07:30 AM", "08:00 AM", "08:30 AM", "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM", "12:30 PM", "13:00 PM", "13:30 PM", "14:00 PM", "14:30 PM", "15:00 PM", "15:30 PM", "16:00 PM", "16:30 PM", "17:00 PM", "17:30 PM", "18:00 PM", "18:30 PM", "19:00 PM", "19:30 PM"]

const getFormatedTime = (timeStr, date) => {
    const timeObject = new Date(`${date} ` + timeStr);

    const hours = timeObject.getHours();
    const minutes = ('0' + timeObject.getMinutes()).slice(-2);
    const formattedTime = `${('0' + hours).slice(-2)}:${minutes} ${hours >= 12 ? 'PM' : 'AM'}`;
    return formattedTime
}

export default function BookingSlots({
    setLoading,
    session,
    setOpenPopup,
    loading
}) {
    const [offType, setOffType] = useState("")
    const [date, setDate] = useState(new Date())
    const [startTime, setStartTime] = useState("")
    const [endTime, setEndTime] = useState("")

    const fetchExistedData = async () => {
        try {
            const url = `${apiPath.fetchOffDayData}?date=${date.toISOString()}`
            const response = await helper.get(url, session);
            if (response.status === 200) {
                if (response.data.data) {
                    const res = response.data.data
                    if (res.type === "custom") {
                        setOffType(res.type)
                        let start = getFormatedTime(res.start_time, res.date)
                        setStartTime(start)
                        let end = getFormatedTime(res.end_time, res.date)
                        setEndTime(end)
                    } else {
                        setOffType("full")
                        setStartTime("")
                        setEndTime("")
                    }

                } else {
                    setOffType("")
                    setStartTime("")
                    setEndTime("")
                }
            } else {
                toast.error("Something went wrong!")
            }
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        fetchExistedData()
    }, [date])

    const isValid = () => {
        if (offType === "") {
            return false
        }
        if (offType === "custom") {
            if (startTime === "" || endTime === "")
                return false
        }

        return true
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (isValid()) {
                setLoading(true)
                const payload = {
                    date: date,
                    start_time: startTime ? `${startTime.split(" ")[0]}:00` : null,
                    end_time: endTime ? `${endTime.split(" ")[0]}:00` : null,
                    type: offType
                }
                const response = await helper.post(payload, apiPath.change_booking_slot, session);
                if (response.status === 200) {
                    setLoading(false);
                    if (response.data.status === 422) {
                        toast.error(response.data.message);
                    }
                    if (response.data.status === 202) {
                        setOpenPopup(false)
                        toast.success(response.data.message);
                    }
                }
            }
        } catch (err) {
            console.error(err)
        } finally {
            setLoading(false)
        }
    }
    return (
        <Page title="Change booking slots | Dr. B. Lal">
            <Container maxWidth={true}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Stack width={300} direction="row" spacing={2} alignItems="center">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                // renderInput={(props) => <TextField {...props} />}
                                label="Day off"
                                minDate={dayjs(new Date())}
                                value={dayjs(date)}
                                onChange={(newValue) => {
                                    setDate(newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </Stack>
                    <FormControl style={{ width: '49%' }}>
                        <InputLabel id="type">Off type</InputLabel>
                        <Select
                            sx={{ width: 300 }}
                            id="type"
                            label="type"
                            onChange={(e) => {
                                setOffType(e.target.value);
                            }}
                            value={offType}
                            defaultValue={offType}
                        >
                            <MenuItem value="full">Full Day</MenuItem>
                            <MenuItem value="custom">Custom</MenuItem>
                        </Select>
                    </FormControl>
                    {offType === "custom" &&
                        <Stack mb={2} direction="row" spacing={2} alignItems="center">
                            <FormControl>
                                <InputLabel id="start_time">start time</InputLabel>
                                <Select
                                    sx={{ width: 200 }}
                                    id="start_time"
                                    label="start_time"
                                    onChange={(e) => {
                                        setStartTime(e.target.value);
                                    }}
                                    value={startTime}
                                    defaultValue={startTime}
                                >
                                    {timeSlots.map((_val, idx) => {
                                        return <MenuItem key={`${_val}-${idx}`} value={_val}>{_val}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl>
                                <InputLabel id="end_time">end time</InputLabel>
                                <Select
                                    sx={{ width: 200 }}
                                    id="end_time"
                                    label="end_time"
                                    onChange={(e) => {
                                        setEndTime(e.target.value);
                                    }}
                                    value={endTime}
                                    defaultValue={endTime}
                                >
                                    {timeSlots.map((_val, idx) => {
                                        if (idx > timeSlots.indexOf(startTime)) {
                                            return <MenuItem key={`${idx}-${_val}`} value={_val}>{_val}</MenuItem>
                                        }
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>}
                    <Box>
                        <LoadingButton disabled={loading} size="medium" type="submit" variant="contained">
                            Save
                        </LoadingButton>
                    </Box>
                </Form>
            </Container>
        </Page>
    )
}