import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect, useRef, useState } from 'react';

import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';

const AddItem = ({ addorEdit, recordForEdit }) => {
    let el = useRef();
    const [itemId, setItemId] = useState('');
    const [fullname, setFullName] = useState('');
    const [designation, setDesignation] = useState('');
    const [qualification, setQualification] = useState('')
    const [link, setLink] = useState('')
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [lifeAtBlalFormError, setLifeAtBlalFormError] = useState({});
    const [previewImage, setPreviewImage] = useState('');
    const [loadingDisable, setLoadingDisable] = useState(false);
    const [memberType, setMemberType] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingDisable(true);
        let data = new FormData();
        data.append('id', itemId);
        data.append('fullname', fullname);
        data.append('designation', designation);
        data.append('qualification', qualification);
        data.append('description', description);
        data.append('link', link ?? "")
        data.append('image', image);
        data.append('member_type', memberType)
        // data.append('status', recordForEdit.status)
        try {
            const response = await addorEdit(data, itemId);
            if (response.status === 200) {
                setLoadingDisable(false);

                if (response.data.status === 422) {
                    setLifeAtBlalFormError(response.data.errors);
                }
            }
        } catch (e) {
            console.error(e);
        }
        finally {
            setLoadingDisable(false)
        }
    };

    const getFile = (e) => {
        let ext = e.target.value.match(/\.([^\.]+)$/)[1];
        switch (ext) {
            case 'png':
            case 'jpeg':
            case 'jpg':
                const file = e.target.files[0];
                setImage(file);
                break;
            default:
                alert('Please upload supported attachment file format (.png, .jpeg, .jpg).');
                e.target.value = '';
        }
    };

    useEffect(() => {
        if (recordForEdit) {
            setItemId(recordForEdit?.id);
            setFullName(recordForEdit?.fullname)
            setDesignation(recordForEdit?.designation);
            setQualification(recordForEdit?.qualification)
            setDescription(recordForEdit?.description);
            setLink(recordForEdit?.link ?? "")
            setImage(recordForEdit?.image);
            setPreviewImage(recordForEdit?.image);
            setMemberType(recordForEdit?.member_type)
        }
    }, [recordForEdit]);
    return (
        <Form onSubmit={handleSubmit} title="Add Item | Dr. B. Lal">
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Controls.Input
                        name="fullname"
                        fullWidth
                        label="Fullname"
                        value={fullname}
                        onChange={(e) => setFullName(e.target.value)}
                        error={lifeAtBlalFormError?.fullname}
                        helperText={lifeAtBlalFormError?.fullname}
                    />
                    <Controls.Input
                        name="designation"
                        fullWidth
                        label="Designation"
                        value={designation}
                        onChange={(e) => setDesignation(e.target.value)}
                        error={lifeAtBlalFormError?.designation}
                        helperText={lifeAtBlalFormError?.designation}
                    />
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Member Type</InputLabel>
                        <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Member Type"
                            value={memberType}
                            onChange={(e) => {
                                setMemberType(e.target.value);
                            }}
                            error={lifeAtBlalFormError?.member_type}
                            helperText={lifeAtBlalFormError?.member_type}
                        >
                            <MenuItem value="Founder Member">Founder Member</MenuItem>
                            <MenuItem value="Leadership Team">Leadership Team</MenuItem>
                            <MenuItem value="Scientific Team">Scientific Team</MenuItem>
                        </Select>
                    </FormControl>
                    <Controls.Input
                        name="qualification"
                        fullWidth
                        label="Qualification"
                        value={qualification}
                        onChange={(e) => setQualification(e.target.value)}
                        error={lifeAtBlalFormError?.qualification}
                        helperText={lifeAtBlalFormError?.qualification}
                    />
                    <Controls.Input
                        name="link"
                        fullWidth
                        label="LinkedIn link"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        error={lifeAtBlalFormError?.link}
                        helperText={lifeAtBlalFormError?.link}
                    />
                    <Controls.Input
                        name="description"
                        fullWidth
                        multiline
                        rows={4}
                        label="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        error={lifeAtBlalFormError?.description}
                        helperText={lifeAtBlalFormError?.description}
                    />
                    <Controls.Input
                        name="image"
                        type="file"
                        accept="image/*"
                        onChange={getFile}
                        ref={el}
                        error={lifeAtBlalFormError?.image}
                        helperText={lifeAtBlalFormError?.image}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        {previewImage && (
                            <Box className="btn-Margin">
                                <img src={`${process.env.REACT_APP_API_BASE_URL}${previewImage}`} alt="preview" width="80" height="80" />{' '}
                            </Box>
                        )}
                        <Box className="btn-Margin">
                            <LoadingButton
                                size="medium"
                                type="submit"
                                variant="contained"
                                endIcon
                                sx={{ marginTop: 4 }}
                                disabled={loadingDisable}
                            >
                                {itemId ? 'Update' : 'Add'}
                            </LoadingButton>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Form>
    );
};

export default AddItem;
