import React from 'react';
import { useState } from 'react';
import {
  Card,
  Grid,
  Stack,
  Container,
  Typography,
  Breadcrumbs,
  Link
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import Page from '../../components/Page';
import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';
import 'date-fns';
import moment from 'moment';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { ToastContainer, toast } from 'react-toastify';
import useSession from 'src/hooks/useSession';


export default function Details(props) {
  const session = useSession();

  const [loadingDisable, setLoadingDisable] = useState(false);
  const [fullname, setFullname] = useState(session?.profile?.user?.fullname || "");
  const [email, setEmail] = useState(session?.profile?.user?.email || "");
  const [phone_number, setPhoneNumber] = useState(session?.profile?.user?.phone_number || "");
  const [formError, setFormError] = useState({});

  const handleOnSubmit = async (event) => {
    try {
      setFormError({});
      event.preventDefault();
      setLoadingDisable(true);
      const response = await helper.post(
        { fullname: fullname, email: email, phone_number: phone_number },
        apiPath.update_profile,
        session
      );

      if (response.status === 200) {
        setLoadingDisable(false);
        if (response.data.status === 200) {
          toast.success(response.data.message);
        }
        if (response.data.status === 422) {
          setFormError(response.data.errors);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9)
  ];

  return (
    <Page title="Settings | Dr. B. Lal">
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Container maxWidth={true}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography variant="h4" gutterBottom>
              Profile
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/">
                Dashboard
              </Link>
              <Typography color="text.primary">Profile</Typography>
            </Breadcrumbs>
          </Grid>
        </Stack>

        <Card>
          <CardHeader title="Profile" />
          <CardContent>
            <Form onSubmit={handleOnSubmit} title="Edit Settings | Dr. B. Lal">
              <Grid container spacing={3}>
                <Grid item xs={6} md={6}>
                  <Controls.Input
                    name="fullname"
                    fullWidth
                    label="Fullname"
                    value={fullname}
                    error={formError?.fullname}
                    onChange={(e) => {
                      setFullname(e.target.value);
                    }}
                  />
                  <Controls.Input
                    name="email"
                    fullWidth
                    label="Email"
                    value={email}
                    error={formError?.email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <Controls.Input
                    name="phone_number"
                    fullWidth
                    label="Phone Number"
                    value={phone_number}
                    error={formError?.phone_number}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                  />

                  <LoadingButton
                    size="medium"
                    type="submit"
                    variant="contained"
                    endIcon
                    disabled={loadingDisable}
                  >
                    SAVE
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
