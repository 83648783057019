import React, { useState } from 'react';
import { Container, Grid, Typography, TextField, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Button } from '@mui/material';
import Page from "../../components/Page";
import SearchLocationInput from "./SearchPlacement";
import GoogleMapComp from "./GoogleComp";
import Geocode from "react-geocode";
import { toast } from "react-toastify";
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import useSession from 'src/hooks/useSession';

const AddressModel = (props) => {
    const session = useSession();
    // Geocode.setApiKey("");
    // set response language. Defaults to english.
    // Geocode.setLanguage("en");
    // set response region. Its optional.
    // A Geocoding request with region=es (Spain) will return the Spanish city.
    // Geocode.setRegion("es");
    // set location_type filter . Its optional.
    // google geocoder returns more that one address for given lat/lng.
    // In some case we need one address as response for which google itself provides a location_type filter.
    // So we can easily parse the result for fetching address components
    // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
    // And according to the below google docs in description, ROOFTOP param returns the most accurate result.
    // Geocode.setLocationType("ROOFTOP");
    // Enable or disable logs. Its optional.
    // Geocode.enableDebug();
    const [address, setAddress] = useState("");
    const [latitude, setLatitude] = useState(26.8634705);
    const [longitude, setLongitude] = useState(75.81852);
    const [pincode, setPincode] = useState(
        Number(localStorage.getItem("pincode"))
    );
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    //Add Address module
    const [formError, setFormError] = useState({});
    const [type, setType] = useState("Home");
    const [number, setFlatNumber] = useState("");
    const [area1, setArea1] = useState("");
    const [area2, setArea2] = useState("");
    const [phone_number, setPhoneNumber] = useState("");
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    function getPincode(lat, log) {
        Geocode.fromLatLng(lat, log).then(
            (response) => {
                setAddress(response.results[0].formatted_address);
                setLatitude(lat);
                setLongitude(log);
                let sublocality,
                    route,
                    sublocality_level_1,
                    sublocality_level_2,
                    political,
                    landmark,
                    street_number,
                    city,
                    state,
                    country,
                    postal_code;
                for (
                    let i = 0;
                    i < response.results[0].address_components.length;
                    i++
                ) {
                    for (
                        let j = 0;
                        j < response.results[0].address_components[i].types.length;
                        j++
                    ) {
                        switch (response.results[0].address_components[i].types[j]) {
                            case "sublocality":
                                sublocality =
                                    response.results[0].address_components[i].long_name;
                                break;
                            case "route":
                                route = response.results[0].address_components[i].long_name;
                                break;
                            case "sublocality_level_1":
                                sublocality_level_1 =
                                    response.results[0].address_components[i].long_name;
                                break;
                            case "sublocality_level_2":
                                sublocality_level_2 =
                                    response.results[0].address_components[i].long_name;
                                if (sublocality_level_2 === undefined) {
                                    sublocality_level_2 = "";
                                }
                                break;
                            case "neighborhood":
                                political = response.results[0].address_components[i].long_name;
                                if (political === undefined) {
                                    political = "";
                                }
                                break;
                            case "premise":
                                street_number =
                                    response.results[0].address_components[i].long_name;
                                break;
                            case "landmark":
                                landmark = response.results[0].address_components[i].long_name;
                                break;
                            case "locality":
                                city = response.results[0].address_components[i].long_name;
                                break;
                            case "administrative_area_level_1":
                                state = response.results[0].address_components[i].long_name;
                                break;
                            case "country":
                                country = response.results[0].address_components[i].long_name;
                                break;
                            case "postal_code":
                                postal_code =
                                    response.results[0].address_components[i].long_name;
                                break;
                        }
                    }
                }

                setPincode(postal_code);
                setState(state);
                setCity(city);

                if (street_number !== undefined) {
                    setFlatNumber(street_number);
                } else {
                    setFlatNumber("");
                }

                if (
                    sublocality_level_1 !== undefined &&
                    sublocality_level_2 !== undefined
                ) {
                    setArea1(`${sublocality_level_1}, ${sublocality_level_2}`);
                } else if (sublocality_level_1 === undefined) {
                    setArea1(`${sublocality_level_2}`);
                } else if (sublocality_level_2 === undefined) {
                    setArea1(`${sublocality_level_1}`);
                }

                setArea2(landmark);
            },
            (error) => {
                console.error(error);
            }
        );
    }

    const getLatLongByAddress = async (address) => {
        Geocode.fromAddress(address.formatted_address).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                setAddress(address.formatted_address);
                setLatitude(lat);
                setLongitude(lng);

                let sublocality,
                    sublocality_level_1,
                    sublocality_level_2,
                    route,
                    political,
                    street_number,
                    landmark,
                    city,
                    state,
                    country,
                    postal_code;

                for (let i = 0; i < address.address_components.length; i++) {
                    for (let j = 0; j < address.address_components[i].types.length; j++) {
                        switch (address.address_components[i].types[j]) {
                            case "sublocality":
                                sublocality = address.address_components[i].long_name;
                                break;
                            case "route":
                                route = address.address_components[i].long_name;
                                if (route === undefined) {
                                    route = "";
                                }
                                break;
                            case "sublocality_level_1":
                                sublocality_level_1 = address.address_components[i].long_name;
                                if (sublocality_level_1 === undefined) {
                                    sublocality_level_1 = "";
                                }
                                break;
                            case "sublocality_level_2":
                                sublocality_level_2 = address.address_components[i].long_name;
                                if (sublocality_level_2 === undefined) {
                                    sublocality_level_2 = "";
                                }
                                break;
                            case "political":
                                political = address.address_components[i].long_name;
                                if (political === undefined) {
                                    political = "";
                                }
                                break;
                            case "premise":
                                street_number = address.address_components[i].long_name;
                                if (street_number === undefined) {
                                    political = "";
                                }
                                break;
                            case "landmark":
                                landmark = address.address_components[i].long_name;
                                if (landmark === undefined) {
                                    political = "";
                                }
                                break;
                            case "locality":
                                city = address.address_components[i].long_name;
                                break;
                            case "administrative_area_level_1":
                                state = address.address_components[i].long_name;
                                break;
                            case "country":
                                country = address.address_components[i].long_name;
                                break;
                            case "postal_code":
                                postal_code = address.address_components[i].long_name;
                                break;
                        }
                    }
                }

                setPincode(postal_code);
                setState(state);
                setCity(city);

                if (street_number !== undefined) {
                    setFlatNumber(street_number);
                } else {
                    setFlatNumber("");
                }

                if (
                    sublocality_level_1 !== undefined &&
                    sublocality_level_2 !== undefined
                ) {
                    setArea1(`${sublocality_level_1}, ${sublocality_level_2}`);
                } else if (sublocality_level_1 === undefined) {
                    setArea1(`${sublocality_level_2}`);
                } else if (sublocality_level_2 === undefined) {
                    setArea1(`${sublocality_level_1}`);
                }

                setArea2(landmark);
            },
            (error) => {
                console.error(error);
            }
        );
    };
    const handleDetect = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                getPincode(position.coords.latitude, position.coords.longitude);
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
            });
        } else {
            toast.error("Geolocation not available!");
        }
    };

    const handleAddressSubmit = async (e) => {
        e.preventDefault();
        let data = {
            type: type,
            number: number,
            area1: area1,
            area2: area2,
            phone_number: phone_number,
            city: city,
            state: state,
            pincode: pincode.toString(),
            latitude: latitude,
            longitude: longitude,
        };

        const result = await helper.post(data, `${apiPath.addAddress}?userId=${props?.userId}`, session);

        if (result.status === 200) {
            if (result.data.status === 422) {
                setFormError(result.data.errors);
            } else if (result.data.status === 400) {
                //setFormError({ error: result.data.message });
                toast.error(result.data.message);
            } else if (result.data.success === true && result.data.status === 200) {
                if (result.data.data.isHomeCollection) {
                    toast.success(result.data.message);
                } else {
                    toast.warn(result.data.message);
                }
                props?.setAddressPopup(false)
                props?.getAllUserAddress();
                // window.location.reload(true);
            }
        }

        //}
    };

    const handleMobileNumber = (e) => {
        let value = e.target.value;
        var pattern = new RegExp(/^[0-9\b]+$/);
        if (value.length && !pattern.test(value)) {
            setFormError({ phone_number: "Please enter only number." });
        } else if (value.length && value.length != 10) {
            setFormError({ phone_number: "Please enter valid phone number." });
            setPhoneNumber(value);
        } else {
            setFormError({});
            setPhoneNumber(value);
        }
    };

    return (
        <Page title="Add Address | Dr. B. Lal">
            <Container maxWidth={true}>
                <Grid container className={"heading"}>
                    <Typography variant='h5'>Enter the address details</Typography>
                </Grid>
                {/* <Grid container>
                    <SearchLocationInput selectlocation={((address) => getLatLongByAddress(address))} />
                </Grid>
                <Grid container className={classes.heading}>
                    <Typography>OR</Typography>
                </Grid>
                <Grid container className={classes.use_gps}>
                    <Grid className="form-control" onClick={handleDetect}>
                        <img src="/static/gps.svg" className="me-2" alt="" />
                        <h6 className="m-0">
                            <b>Use Current Location</b>Using GPS
                        </h6>
                    </Grid>
                </Grid>
                <Grid container className={classes.googleMap}>
                    <GoogleMapComp
                        latitude={latitude}
                        longitude={longitude}
                        getPincode={(lat, lng) => getPincode(lat, lng)}
                        zoom={15}
                    />
                </Grid> */}
                <form name="addressForm" onSubmit={handleAddressSubmit}>
                    <Grid container>
                        <Grid item xs={12} className={"textField"}>
                            <TextField
                                type="text"
                                placeholder='Home Number/Flat Number'
                                id="number"
                                name="number"
                                value={number}
                                onChange={((e) => {
                                    setFlatNumber(e.target.value)
                                })}
                                fullWidth
                                error={formError.number}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12} className={'textField'}>
                            <TextField
                                type="text"
                                placeholder='Street Name/Area Name/Apartments'
                                id="area1"
                                name="area1"
                                value={area1}
                                onChange={((e) => {
                                    setArea1(e.target.value)
                                })}
                                fullWidth
                                error={formError.area1}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12} className={'textField'}>
                            <TextField
                                type="text"
                                placeholder='Landmark'
                                id="area2"
                                name="area2"
                                value={area2}
                                onChange={((e) => {
                                    setArea2(e.target.value)
                                })}
                                fullWidth
                                error={formError.area2}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12} className={"textField"}>
                            <TextField
                                type="text"
                                placeholder='Alternate Mobile Number'
                                id="phone_number"
                                name="phone_number"
                                value={phone_number}
                                onChange={((e) => {
                                    handleMobileNumber(e)
                                })}
                                fullWidth
                                error={formError.phone_number}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12} className={'textField'}>
                            <TextField
                                type="text"
                                placeholder='Enter State'
                                id="state"
                                name="state"
                                value={state}
                                onChange={((e) => {
                                    setState(e.target.value);
                                })}
                                fullWidth
                                error={formError.state}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12} className={'textField'}>
                            <TextField
                                type="text"
                                placeholder='Enter City'
                                id="city"
                                name="city"
                                value={city}
                                onChange={((e) => {
                                    setCity(e.target.value);
                                })}
                                fullWidth
                                error={formError.state}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12} className={'textField'}>
                            <TextField
                                type="text"
                                placeholder='Enter City'
                                id="city"
                                name="city"
                                value={city}
                                onChange={((e) => {
                                    setCity(e.target.value);
                                })}
                                fullWidth
                                error={formError.state}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12} className={'textField'}>
                            <TextField
                                type="text"
                                placeholder='Enter Pincode'
                                id="pincode"
                                name="pincode"
                                value={pincode}
                                fullWidth
                                onChange={((e) => {
                                    setPincode(e.target.value);
                                })}
                                error={formError.pincode}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12} className={'textField'}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Address Type</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={(e) => {
                                        setType(e.target.value)
                                    }}
                                    value={type}
                                >
                                    <FormControlLabel value="Home" control={<Radio />} label="Home" />
                                    <FormControlLabel value="Office" control={<Radio />} label="Office" />
                                    <FormControlLabel value="Others" control={<Radio />} label="Other" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className={'textField'}>
                            <Button
                                variant="outlined"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </Page>
    );
}

// const useStyles = makeStyles({
//     heading: {
//         justifyContent: 'center'
//     },
//     use_gps: {
//         border: "1px solid",
//         paddingLeft: "20px",
//         cursor: "pointer",
//         "& .form-control": {
//             display: "flex",
//             alignItems: "center",
//             textDecoration: "none",
//             "& h6": {
//                 color: "#736b82",
//                 fontSize: "12px"
//             },
//             "& b": {
//                 display: "block",
//                 color: "#286836",
//                 fontSize: "14px"
//             }
//         }
//     },
//     googleMap: {
//         height: "200px",
//         paddingTop: "20px"
//     },
//     textField: {
//         paddingBottom: "10px"
//     }
// });

export default AddressModel;