import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Box, Grid, Link, Container, Typography } from '@mui/material';
// components
import {
  AppItemOrders,
  AppTotalBookings,
  AppTotalEarnings,
  AppTotalUsers,
  AppTotalHomeCollection,
  AppNewsUpdate,
  AppCurrentVisits,
  AppWebsiteVisits
} from '../components/_dashboard/app';
import Page from '../components/Page';
import helper from '../constants/helper';
import apiPath from '../constants/apiPath';
import moment from 'moment';
import useSession from 'src/hooks/useSession';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const navigate = useNavigate();
  const [countData, setCountData] = useState(null);
  const [totalSales, setTotalSales] = useState(null);
  const [selectedDate, handleDateChange] = useState(null);
  const session = useSession();

  const setDate = (data) => {
    let newArr = [];
    if (data !== null && data.length) {
      for (let index = 0; index < data.length; index++) {
        const date = data[index];
        newArr.push(moment(date).format('YYYY-MM-DD'));
      }
      handleDateChange(newArr);
    } else {
      handleDateChange(null);
    }
  };

  const getDashboardData = async () => {
    try {
      const response = await helper.get(apiPath.dashboard, session);
      if (response.status === 200) {
        if (response.data.success === true) {
          setCountData(response.data.data);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getTotalSales = async () => {
    try {
      const response = await helper.get(`${apiPath.getTotalSales}?dates=${selectedDate}`, session);
      if (response.status === 200) {
        if (response.data.success === true) {
          setTotalSales(response.data.data);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getDashboardData();
    getTotalSales();
  }, [selectedDate]);

  return (
    <Page title="Dashboard | Dr. B. Lal">
      <Container maxWidth="xl">
        {/* <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box> */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Link underline="hover" color="inherit" component={RouterLink} to="/bookings">
              <AppTotalBookings totalBooking={countData ? countData.totalBookings : 1000} />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Link underline="hover" color="inherit" component={RouterLink} to="/booking_earnings">
              <AppTotalEarnings totalEarning={countData ? countData.totalEarnings.total : 1000} />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Link underline="hover" color="inherit" component={RouterLink} to="/users">
              <AppTotalUsers totalUsers={countData ? countData.totalUsers : 1000} />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Link underline="hover" color="inherit" component={RouterLink} to="/bookings">
              <AppTotalHomeCollection
                totalHomeRequest={countData ? countData.totalHomeCollectionBookingRequest : 1000}
              />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Link underline="hover" color="inherit" component={RouterLink} to="/bookings">
              <AppItemOrders totalActiveBooking={countData ? countData.totalActiveBooking : 1000} />
            </Link>
          </Grid>

          {totalSales && (
            <Grid item xs={12} md={6} lg={12}>
              <AppWebsiteVisits func={setDate} totalSales={totalSales} />
            </Grid>
          )}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
