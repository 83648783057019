// routes
import Router from './routes';

// theme
import ThemeConfig from './theme';

// components
import ScrollToTop from './components/ScrollToTop';

import './scss/style.scss';
import './style.css'

// ----------------------------------------------------------------------

export default function App() {
  return (
      <ThemeConfig>
        <ScrollToTop />
        <Router />
      </ThemeConfig>
  );
}
