import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// ----------------------------------------------------------------------
import ErrorResponse from '../../ErrorResponse';
import helper from '../../../constants/helper';
import apiPath from '../../../constants/apiPath';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useSession from 'src/hooks/useSession';

export default function ResetPasswordForm(props) {
  const navigate = useNavigate();
  const { user_id, token } = useParams();
  const [errorResponse, setErrorResponse] = useState('');
  const session = useSession();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const LoginSchema = Yup.object().shape({
    password: Yup.string().required('New Password is required'),
    cpassword: Yup.string()
      .required('Confirm New Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      cpassword: '',
      user_id: user_id,
      token: token
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      handleToggle();
      try {
        const res = await helper.post(values, apiPath.reset_password);

        if (res.status === 200) {
          if (!res.data.success) {
            toast.error(res.data.message);
            // setErrorResponse(res.data.message);
            setTimeout(() => setErrorResponse(''), 5000);
          } else {
            toast.success(res.data.message);
            setTimeout(() => navigate('/login'), 5000);
          }
        }
      } catch (e) {
        setErrorResponse('');
        setTimeout(() => setErrorResponse(''), 5000);
        console.log(e);
      }
      handleClose();
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  if (session.isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <FormikProvider value={formik}>
        {errorResponse && <ErrorResponse msg={errorResponse} />}
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              required
              type="password"
              label="Enter new password"
              {...getFieldProps('password')}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
            <TextField
              fullWidth
              required
              type="cpassword"
              label="Confirm new password"
              {...getFieldProps('cpassword')}
              error={Boolean(touched.cpassword && errors.cpassword)}
              helperText={touched.cpassword && errors.cpassword}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}

            {/* <Link component={RouterLink} variant="subtitle2" to="/login">
              Sign In?
            </Link> */}
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Reset Password
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
}
