import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import pinFill from '@iconify/icons-eva/pin-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';

//Maan
import creditCardOutline from '@iconify/icons-eva/credit-card-outline';
import thermometerFill from '@iconify/icons-eva/thermometer-fill';
import imageFill from '@iconify/icons-eva/image-fill';
import citiFill from '@iconify/icons-eva/home-fill';
import messageSquareFill from '@iconify/icons-eva/message-square-outline';
import attachFill from '@iconify/icons-eva/attach-fill';
import uploadFill from '@iconify/icons-eva/upload-fill';
import giftFill from '@iconify/icons-eva/gift-fill';
import textFill from '@iconify/icons-eva/text-fill';
import pricetagsFill from '@iconify/icons-eva/pricetags-fill';
import awardFill from '@iconify/icons-eva/award-fill';
import activityFill from '@iconify/icons-eva/activity-fill';
import logsIcon from '@iconify/icons-ic/round-file-upload';
import questionMarkCircleFill from '@iconify/icons-eva/question-mark-circle-fill';
import mobileOff from '@iconify/icons-ic/round-mobile-off';
import {
  EmojiEmotions,
  HealthAndSafety,
  MonitorHeart,
  Wc,
  ListAlt,
  AlternateEmail,
  Drafts,
  EmojiPeople,
  EmojiEvents,
  DescriptionOutlined
} from '@mui/icons-material';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    checkAccess: false,
    permissionModule: 'dashboard',
    permissionType: 'r_screen',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Blogs Category',
    path: '/blog_category',
    checkAccess: true,
    permissionModule: 'blog_categories',
    permissionType: 'r_screen',
    icon: getIcon(pricetagsFill)
  },
  {
    title: 'Blogs Sub Category',
    path: '/blog_sub_category',
    checkAccess: true,
    permissionModule: 'blog_sub_categories',
    permissionType: 'r_screen',
    icon: getIcon(pricetagsFill)
  },
  {
    title: 'Blogs',
    path: '/blogs',
    checkAccess: true,
    permissionModule: 'blogs',
    permissionType: 'r_screen',
    icon: getIcon(fileTextFill)
  },
  {
    title: 'Banner Management',
    path: '/banner',
    checkAccess: true,
    permissionModule: 'banner',
    permissionType: 'r_screen',
    icon: getIcon(imageFill)
  },
  {
    title: 'Cities',
    path: '/cities',
    checkAccess: true,
    permissionModule: 'banner',
    permissionType: 'r_screen',
    icon: getIcon(citiFill)
  },
  {
    title: 'Health Bulletin',
    path: '/health_bulletin',
    checkAccess: true,
    permissionModule: 'health_bulletin',
    permissionType: 'r_screen',
    icon: <HealthAndSafety />
  },
  {
    title: 'Happy Customers',
    path: '/happy_customers',
    checkAccess: true,
    permissionModule: 'happy_customers',
    permissionType: 'r_screen',
    icon: <EmojiEmotions />
  },
  {
    title: 'Our Clients',
    path: '/our_clients',
    checkAccess: true,
    permissionModule: 'our_clients',
    permissionType: 'r_screen',
    icon: <EmojiPeople />
  },
  {
    title: 'Partner With Us',
    path: '/partner_with_us_forms',
    checkAccess: true,
    permissionModule: 'partner_with_us',
    permissionType: 'r_screen',
    icon: <ListAlt />
  },
  {
    title: 'NewsLetter',
    path: '/news_letter',
    checkAccess: true,
    permissionModule: 'news_letter',
    permissionType: 'r_screen',
    icon: <AlternateEmail />
  },
  {
    title: 'Awards/Recognitions',
    path: '/awards',
    checkAccess: true,
    permissionModule: 'awards',
    permissionType: 'r_screen',
    icon: <EmojiEvents />
  },
  {
    title: 'Messages',
    path: '/messages',
    checkAccess: true,
    permissionModule: 'messages',
    permissionType: 'r_screen',
    icon: <Drafts />
  },
  {
    title: 'Feedbacks',
    path: '/feedback',
    checkAccess: true,
    permissionModule: 'feedback',
    permissionType: 'r_screen',
    icon: <DescriptionOutlined />
  },
  {
    title: 'Latest Diagnostic',
    path: '/latest_diagnostic',
    checkAccess: true,
    permissionModule: 'latest_diagnostic',
    permissionType: 'r_screen',
    icon: <MonitorHeart />
  },
  {
    title: 'Refer Patient',
    path: '/refer_patient',
    checkAccess: true,
    permissionModule: 'refer_patient',
    permissionType: 'r_screen',
    icon: <Wc />
  },
  {
    title: 'Pin Code Management',
    path: '/pincodes',
    checkAccess: true,
    permissionModule: 'pincodes',
    permissionType: 'r_screen',
    icon: getIcon(pinFill)
  },
  {
    title: 'Users',
    path: '/users',
    checkAccess: true,
    permissionModule: 'users',
    permissionType: 'r_screen',
    icon: getIcon(peopleFill)
  },
  {
    title: 'UninstallAppUserList',
    path: '/users_app_status',
    checkAccess: true,
    permissionModule: 'users_app_status',
    permissionType: 'r_screen',
    icon: getIcon(mobileOff)
  },
  {
    title: 'Uploaded Prescriptions',
    path: '/user_prescriptions',
    checkAccess: true,
    permissionModule: 'user_prescriptions',
    permissionType: 'r_screen',
    icon: getIcon(uploadFill)
  },
  {
    title: 'Booking Management',
    path: '/bookings',
    checkAccess: true,
    permissionModule: 'bookings',
    permissionType: 'r_screen',
    icon: getIcon(shoppingBagFill)
  },
  {
    title: 'Coupon Management',
    path: '/coupons',
    checkAccess: true,
    permissionModule: 'coupons',
    permissionType: 'r_screen',
    icon: getIcon(giftFill)
  },

  {
    title: 'Booking Earnings',
    path: '/booking_earnings',
    checkAccess: true,
    permissionModule: 'booking_earnings',
    permissionType: 'r_screen',
    icon: getIcon(fileTextFill)
  },
  {
    title: 'User Ratings',
    path: '/user_ratings',
    checkAccess: true,
    permissionModule: 'user_ratings',
    permissionType: 'r_screen',
    icon: getIcon(fileTextFill)
  },

  //Maan
  {
    title: 'Tests and Packages',
    path: '/tests_packages',
    checkAccess: true,
    permissionModule: 'tests_packages',
    permissionType: 'r_screen',
    icon: getIcon(thermometerFill)
  },
  {
    title: 'Membership Card',
    path: '/membership_cards',
    checkAccess: true,
    permissionModule: 'membership_cards',
    permissionType: 'r_screen',
    icon: getIcon(creditCardOutline)
  },

  {
    title: 'Role Manager',
    path: '/roles',
    checkAccess: true,
    permissionModule: 'roles',
    permissionType: 'r_screen',
    icon: getIcon(lockFill)
  },
  {
    title: 'Sub Admin Manager',
    path: '/subadmin',
    checkAccess: true,
    permissionModule: 'subadmin',
    permissionType: 'r_screen',
    icon: getIcon(peopleFill)
  },
  {
    title: 'Notification Manager',
    path: '/notifications',
    checkAccess: true,
    permissionModule: 'notifications',
    permissionType: 'r_screen',
    icon: getIcon(messageSquareFill)
  },
  {
    title: 'Why Choose BLal',
    path: '/whychooseblal',
    checkAccess: true,
    permissionModule: 'whychooseblal',
    permissionType: 'r_screen',
    icon: getIcon(awardFill)
  },
  {
    title: 'News & Events',
    path: '/newsevents',
    checkAccess: true,
    permissionModule: 'newsevents',
    permissionType: 'r_screen',
    icon: getIcon(activityFill)
  },

  {
    title: `FAQ's Category`,
    path: '/faq_categories',
    checkAccess: true,
    permissionModule: 'faq_category',
    permissionType: 'r_screen',
    icon: getIcon(attachFill)
  },

  {
    title: `FAQ's`,
    path: '/faqs',
    checkAccess: true,
    permissionModule: 'faqs',
    permissionType: 'r_screen',
    icon: getIcon(textFill)
  },

  {
    title: `Need Helps`,
    path: '/need_helps',
    checkAccess: true,
    permissionModule: 'need_helps',
    permissionType: 'r_screen',
    icon: getIcon(questionMarkCircleFill)
  },

  {
    title: 'Content Manager',
    path: '/contents',
    checkAccess: true,
    permissionModule: 'contents',
    permissionType: 'r_screen',
    icon: getIcon(textFill)
  },
  {
    title: 'Logs',
    path: '/logs',
    checkAccess: true,
    permissionModule: 'contents',
    permissionType: 'r_screen',
    icon: getIcon(logsIcon)
  }
];

export default sidebarConfig;
