import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import FranchiseOpportunityComponent from './franchiseOpportunityList';
import LabAcquisitionComponent from './labAcquisitonList';
import HospitalLabManagementComponent from './hospitalLabManagementList';
import HealthyDaftarComponent from './healthyDaftarList';
import RegionalPartnerComponent from './regionalPartnerList';
import useSession from 'src/hooks/useSession';

PartnerWithUs.propTypes = {
  sx: PropTypes.object
};

export default function PartnerWithUs({ sx }) {
  const session = useSession();
  const [formType, setFormType] = useState('franchise');

  return (
    <>
      <FormControl sx={{ m: 1, width: 150, marginTop: '0%', marginLeft: '3%' }}>
        <InputLabel id="demo-simple-select-label">Form Type</InputLabel>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Banner Type"
          value={formType}
          onChange={(e) => {
            setFormType(e.target.value);
          }}
        >
          <MenuItem value="franchise">Franchising Opportunity</MenuItem>
          <MenuItem value="labacquisition">Lab Acquisition</MenuItem>
          <MenuItem value="hospitallabmanagement">Hospital Lab Management</MenuItem>
          <MenuItem value="healthydaftar">Healthy Daftar</MenuItem>
          <MenuItem value="regionalpartner">Regional Partners</MenuItem>
        </Select>
      </FormControl>
      {formType === 'franchise' ? (
        <FranchiseOpportunityComponent />
      ) : formType === 'labacquisition' ? (
        <LabAcquisitionComponent />
      ) : formType === 'hospitallabmanagement' ? (
        <HospitalLabManagementComponent />
      ) : formType === 'healthydaftar' ? (
        <HealthyDaftarComponent />
      ) : formType === 'regionalpartner' ? (
        <RegionalPartnerComponent />
      ) : (
        <FranchiseOpportunityComponent />
      )}
    </>
  );
}
