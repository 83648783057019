export const capitalize = (str) => {
  if (str) return str[0].toUpperCase() + str.slice(1);
  return '';
};

export function debounce(func, delay) {
  let timeoutId;

  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

function formatTimestampToTime(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

export const localDate = (inputDate, type) => {
  let formattedDate = '';
  const convertedDate = new Date(inputDate)
  if (inputDate) {
    if (type === 'date') {
      const localYear = convertedDate.getFullYear();
      const localMonth = convertedDate.getMonth() + 1;
      const localDay = convertedDate.getDate();

      formattedDate = `${localYear}-${String(localMonth).padStart(2, '0')}-${String(
        localDay
      ).padStart(2, '0')}`;
      return formattedDate;
    } else if (type === 'time') {
      const date = new Date(convertedDate);
      const time = formatTimestampToTime(date.getTime());
      return time;
    }
  }
  return null;
};

export const getFileFromImageUrl = async (imageUrl) => {
  try {
    // Fetch the image data
    const fileName = imageUrl.split('/').at(-1)
    const extension = fileName.split('.').pop().toLowerCase();
    let type = null;
  switch (extension) {
    case 'jpg':
    case 'jpeg':
      type = 'image/jpeg';
      break;
    case 'png':
      type = 'image/png';
      break;
    default:
      type = null; // Unknown file type
  }
    const response = await fetch(imageUrl);
    const imageData = await response.blob();

    const file = new File([imageData], fileName, { type }); // Adjust type as needed

    return file;
  } catch (error) {
    throw error;
  }
}
