import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
import Page from '../../components/Page';
import { Link as RouterLink } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import AddIcon from '@mui/icons-material/Add';
import Popup from 'src/components/Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import AddItem from './Add';
import moment from 'moment';
import Switch from '@mui/material/Switch';

import {
  Box,
  Stack,
  Grid,
  Breadcrumbs,
  Link,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  CircularProgress
} from '@mui/material';

import { Backdrop } from '@mui/material';
import useSession from 'src/hooks/useSession';

LifeAtBlal.propTypes = {
  sx: PropTypes.object
};

function LifeAtBlal({ sx }) {
  const [lifeAtBlalData, setLifeAtBlalData] = useState([]);
  const session = useSession();
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [addBlog, setAddBlog] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });

  const headeCells = [
    { id: 'image', label: 'Image', disableSorting: true },
    { id: 'title', label: 'Title', disableSorting: true },
    { id: 'description', label: 'Description', disableSorting: true },
    { id: 'status', label: 'Status', disableSorting: true },
    { id: 'createAt', label: 'Created At', disableSorting: true },
    { id: 'action', label: 'Action', disableSorting: true }
  ];

  const getLifeAtBlalData = async () => {
    try {
      const res = await helper.get(`${apiPath.life_at_blal}/get`, session);
      if (res.status === 200) {
        if (res.data.success === true) {
          setLifeAtBlalData(res.data.data);
        }
      }
    } catch (e) {
      toast.error(e.message);
      console.error(e);
    }
  };

  useEffect(() => {
    getLifeAtBlalData();
  }, []);

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    lifeAtBlalData,
    headeCells,
    filterFn
  );

  // const useStyles = makeStyles((theme) => ({
  //   pageContent: {
  //     margin: theme.spacing(5),
  //     padding: theme.spacing(3)
  //   },
  //   searchInput: {
  //     width: '15%'
  //   },
  //   newButton: {
  //     position: 'absolute',
  //     right: '10px'
  //   }
  // }));

  // const classes = useStyles();

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const addorEdit = async (data, id) => {
    setLoading(true);
    try {
      if (!recordForEdit) {
        const response = await helper.post(data, `${apiPath.life_at_blal}/create`, session);
        if (response.data.success == true) {
          getLifeAtBlalData();
          setOpenPopup(false);
          toast.success(response.data.message);
        } else {
          return response;
        }
      } else {
        const res = await helper.put(data, `${apiPath.life_at_blal}/update/${id}`, session);
        if (res.data.success == true) {
          getLifeAtBlalData();
          setOpenPopup(false);
          toast.success(res?.data?.message);
        } else {
          return res;
        }
      }
    } catch (error) {
      toast.error(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (item) => {
    try {
      setLoading(true);
      const data = {
        ...item,
        status: item.status ? false : true
      };

      const response = await helper.put(
        data,
        `${apiPath.life_at_blal}/update/${item?.id}`,
        session
      );
      if (response.status === 200) {
        setLoading(false);
        if (response.data.success === true) {
          getLifeAtBlalData();
          toast.success(response.data.message);
        }
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const handleDelete = async (item) => {
    try {
      setLoading(true);
      const res = await helper.delete(`${apiPath.life_at_blal}/delete/${item.id}`, session);
      if (res.data.status === 200) {
        if (res.data.success) {
          getLifeAtBlalData();
          toast.success(`deletion ${res.data.message}`);
          setConfirmAlertStatus(false);
        }
      }
    } catch (e) {
      toast.error('Error', e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Page title={'Life At Blal | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 999 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Life At Blal
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">life-at-blal</Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <Toolbar>
            <Controls.Button
              text="Add New Item"
              variant="outlined"
              startIcon={<AddIcon />}
              className={'newButton'}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
                setAddBlog(true);
              }}
            />
          </Toolbar>

          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting()?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <Box>
                      <img
                        src={`${item?.image}`}
                        style={{ height: 100, minWidth: 150 }}
                        alt="life-at-blal"
                      />
                    </Box>
                  </TableCell>
                  <TableCell sx={{ width: 150 }}>{item?.title}</TableCell>
                  <TableCell>{item?.description}</TableCell>
                  <TableCell>
                    <Switch checked={item?.status} onChange={() => handleStatusChange(item)} />
                  </TableCell>
                  <TableCell>{moment(item?.created_at).format('DD/MM/YYYY')}</TableCell>

                  <TableCell>
                    <Controls.ActionButton
                      color="primary"
                      onClick={() => {
                        openInPopup(item);
                        setEditCategory(true);
                      }}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </Controls.ActionButton>
                    {/*TODO: Commented as not required  */}
                    {/* <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        handleDelete(item);
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>

      <Popup
        title={`${recordForEdit ? 'Edit' : 'Add'} Life At Blal`}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <AddItem recordForEdit={recordForEdit} addorEdit={addorEdit} />
      </Popup>
    </>
  );
}
export default LifeAtBlal;
