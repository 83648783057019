import React from 'react';
import { useState, useEffect } from 'react';
import {
  InputLabel,
  Grid,
  Box,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Controls from '../../components/control/Controls';
import { Form } from '../../components/useForm';
import useSession from 'src/hooks/useSession';

export default function Add(props) {
  let { addorEdit, recordForEdit } = props;
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [ourClientsFormError, setOurClientsFormError] = useState({});
  const [loadingDisable, setLoadingDisable] = useState(false);
  const session = useSession();

  const getFile = (e) => {
    let ext = e.target.value.match(/\.([^\.]+)$/)[1];
    switch (ext) {
      case 'png':
      case 'jpeg':
      case 'jpg':
        const file = e.target.files[0];
        setImage(file);
        break;
      default:
        alert('Please upload supported attachment file format (.png, .jpeg, .jpg).');
        e.target.value = '';
    }
  };

  const handleSubmit = async (e) => {
    setLoadingDisable(true);
    e.preventDefault();
    let data = new FormData();
    data.append('image', image);
    data.append('clientName', name);
    if (id) {
      data.append('id', id);
    }
    let response = await addorEdit(data, id);

    if (response && response.data && response.data.errors) {
      setOurClientsFormError(response.data.errors);
      setLoadingDisable(false);
    }
  };

  useEffect(() => {
    if (recordForEdit) {
      setId(recordForEdit.id);
      setName(recordForEdit.name);
      setImage(recordForEdit.image);
    }
  }, [recordForEdit]);
  return (
    <Form onSubmit={handleSubmit} title="Add Our Clients| Dr. B. Lal">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controls.Input
            name="name"
            fullWidth
            label="Our Client Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={ourClientsFormError?.name}
          />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <InputLabel id="demo-simple-select-label" shrink={true}>
            Image
          </InputLabel>
          <TextField
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            name="image"
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={getFile}
            error={ourClientsFormError?.image}
            helperText={ourClientsFormError?.image}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <LoadingButton
            size="medium"
            type="submit"
            variant="contained"
            endIcon
            disabled={loadingDisable}
          >
            {id ? 'Update' : 'Add'}
          </LoadingButton>
        </Box>
      </Grid>
    </Form>
  );
}
