import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Stack,
  Grid,
  Breadcrumbs,
  Link,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography
} from '@mui/material';
import Page from '../../components/Page';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import useSession from 'src/hooks/useSession';

export default function List() {
  const session = useSession();
  const navigate = useNavigate();
  const [contents, setContents] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const headeCells = [
    { id: 'title', label: 'Title', disableSorting: true },
    { id: 'actions', label: 'Actions', disableSorting: true }
  ];

  const getContents = async () => {
    try {
      const res = await helper.get(`${apiPath.content_list}`, session);
      if (res.status === 200) {
        if (res.data.status === 200) {
          setContents(res.data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getContents();
  }, []);

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    contents,
    headeCells,
    filterFn
  );

  const handleNavigate = (slug) => {
    if (['life_at_blal', 'core_members'].includes(slug)) {
      navigate(`/${slug}`);
    } else navigate(`/content/${slug}`);
  };

  return (
    <>
      <Page title={'Contents | Dr. B Lal'}>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Contents
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">Content List</Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <TblContainer>
            <TblHeader />
            <TableBody>
              {' '}
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>
                    <Controls.ActionButton
                      color="primary"
                      onClick={() => handleNavigate(item?.slug)}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </Controls.ActionButton >
                  </TableCell >
                </TableRow >
              ))
              }
            </TableBody >
          </TblContainer >
          <TblPagination />
        </Container >
      </Page >
    </>
  );
}
