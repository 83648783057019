import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import apiPath from '../../constants/apiPath';
import helper from '../../constants/helper';
// import useSession from 'react-session-hook';
import Page from '../../components/Page';
import AlertDialog from '../../components/AlertDialog';
import { Link as RouterLink } from 'react-router-dom';
import useTable from 'src/components/useTable';
import Controls from '../../components/control/Controls';
import { Search } from '@mui/icons-material';
// import { makeStyles } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import Popup from 'src/components/Popup';
import CategoryAdd from './AddCategory';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import Switch from '@mui/material/Switch';

import {
  Box,
  Stack,
  Grid,
  Breadcrumbs,
  Link,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';

import { Backdrop } from '@mui/material';
import useSession from 'src/hooks/useSession';

List.propTypes = {
  sx: PropTypes.object
};

export default function List({ sx }) {
  const [blogCategoryData, setBlogCategory] = useState([]);
  const session = useSession();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [ConfrimAlertOpen, setConfrimAlertOpen] = useState(false);
  const [ConfirmAlertMessage, setConfirmAlertMessage] = useState(null);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [addBlog, setAddBlog] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  let [loading, setLoading] = useState(false);

  const headeCells = [
    { id: 'name', label: 'Name', disableSorting: true },
    { id: 'status', label: 'Status', disableSorting: true },
    { id: 'date', label: 'Created At', disableSorting: true },
    { id: 'action', label: 'Action', disableSorting: true }
  ];

  const handleConfrimAlertClose = (status) => {
    setConfrimAlertOpen(false);
    setConfirmAlertMessage(null);
  };

  const getBlogsCategoryData = async () => {
    try {
      setLoading(true);
      const res = await helper.get(`${apiPath.faqCatList}`, session);
      if (res.status === 200) {
        setLoading(false);
        if (res.data.success === true) {
          setBlogCategory(res.data.data);
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleSearch = (e) => {
    setSearchKeyword(e.target.value);
    // let target = e.target;
    // setFilterFn({
    //   fn: (items) => {
    //     return target.value == ''
    //       ? items
    //       : items.filter((x) => x.name.toLowerCase().includes(target.value));
    //   }
    // });
  };

  useEffect(() => {
    getBlogsCategoryData();
  }, []);

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    blogCategoryData,
    headeCells,
    filterFn
  );

  // const useStyles = makeStyles((theme) => ({
  //   pageContent: {
  //     margin: theme.spacing(5),
  //     padding: theme.spacing(3)
  //   },
  //   searchInput: {
  //     width: '15%'
  //   },
  //   newButton: {
  //     position: 'absolute',
  //     right: '10px'
  //   }
  // }));

  // const classes = useStyles();

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const addorEdit = async (data, id) => {
    setLoading(true);
    try {
      if (recordForEdit == null) {
        const response = await helper.post(data, apiPath.FaqCatAddOrUpdate, session);
        if (response.data.success == true) {
          getBlogsCategoryData();
          setOpenPopup(false);
          toast.success(response.data.message);
        } else {
          setLoading(false);
          return response;
        }
      } else {
        const res = await helper.post(
          data,
          `${apiPath.FaqCatAddOrUpdate}?faq_category_id=${id}`,
          session
        );
        console.log('res', res);
        if (res.data.success == true) {
          getBlogsCategoryData();
          setOpenPopup(false);
          toast.success(res.data.message);
        } else {
          setLoading(false);
          return res;
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCategoryDelete = async (item) => {
    try {
      //if (ConfirmAlertStatus) {
      let res = await helper.get(`${apiPath.deleteFaqCat}/${item.id}`, session);
      if (res.status === 200) {
        if (res.data.success) {
          getBlogsCategoryData();
          toast.success(res.data.message);
        }
      }
      //}
    } catch (e) {
      toast.error('Error', e);
    }
  };

  const handleStatusChange = async (item) => {
    try {
      setLoading(true);
      var data = {
        table: 'faq_categories',
        status: !item.status
      };
      const response = await helper.post(data, `${apiPath.update_status}/${item.id}`, session);
      if (response.status === 200) {
        setLoading(false);
        if (response.data.success === true) {
          getBlogsCategoryData();
          toast.success(response.data.message);
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <>
      <Page title={'FAQ Category List | Dr. B Lal'}>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Backdrop
          sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 999 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={false}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h4" gutterBottom>
                Faq's Category
              </Typography>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="text.primary">List</Typography>
              </Breadcrumbs>
            </Grid>
          </Stack>

          <Toolbar>
            {/* <Controls.Input
              label="Search Blogs"
              className={classes.searchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
              onChange={handleSearch}
            /> */}

            <Controls.Button
              text="Add New"
              variant="outlined"
              startIcon={<AddIcon />}
              className={'newButton'}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
                setAddBlog(true);
              }}
            />
          </Toolbar>

          <TblContainer>
            <TblHeader />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    {' '}
                    <Switch
                      checked={item.status}
                      onChange={() => {
                        handleStatusChange(item);
                      }}
                    />
                  </TableCell>
                  <TableCell>{moment(item.created_at).format('DD/MM/YYYY')}</TableCell>

                  <TableCell>
                    <Controls.ActionButton
                      color="primary"
                      onClick={() => {
                        openInPopup(item);
                        setEditCategory(true);
                      }}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </Controls.ActionButton>

                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        handleCategoryDelete(item);
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Page>

      <AlertDialog
        open={ConfrimAlertOpen}
        body={ConfirmAlertMessage}
        ConfrimAlertClose={handleConfrimAlertClose}
      />

      <Popup
        title={`${recordForEdit ? 'Edit' : 'Add'} Faq's Category`}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <CategoryAdd
          title="Edit Category"
          recordForEdit={recordForEdit}
          addorEdit={addorEdit}
          loadState={loading}
        />
      </Popup>
    </>
  );
}
