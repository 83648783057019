import React from 'react';
import Page from '../../components/Page';
import {
  Stack,
  Paper,
  Grid,
  Breadcrumbs,
  Link,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Toolbar,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Controls from '../../components/control/Controls';
// import { makeStyles } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { Search } from '@mui/icons-material';
import useTable from 'src/components/useTable';

import axios from 'axios';
import { Card, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Backdrop } from '@mui/material';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';

export default function List() {
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });
  const [citiesData, setCitiesData] = useState([]);
  const [panelId, setPanelId] = useState(78);
  const [cityId, setCityId] = useState(1);
  const [cityName, setCityName] = useState("Jaipur");
  const [testType, setTestType] = useState('both');
  const [SearchKeyword, setSearchKeyword] = useState('');
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCityList = async () => {
    try {
      setLoading(true);
      const res = await helper.get(`${apiPath.get_all_cities}`);

      if (res.status === 200) {
        setLoading(false);
        if(res.data.success ===true ){
          setCitiesData(res.data.data);
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getPackagesList = async () => {
    try {
     
      setLoading(true);
      let URL = `/GetFilterTestPackage?CityId=${cityId}&PanelId=${panelId}&Limit=20`;
      if (testType != 'both') {
        URL = `${URL}&Type=${testType}`;
      }
      if (SearchKeyword) {
        URL = `${URL}&SearchKeyword=${SearchKeyword}`;
      }
      const res = await helper.postb(URL);
      if (res.status === 200) {
        setLoading(false);
        if (res.data.status_Code === 200) {
          setPackages(res.data.data.itemmodel);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(`error in finaly catch is ${error.message}`);
    }
  };

  useEffect(() => {
    getCityList();
  }, []);

  useEffect(() => {
    getPackagesList();
  }, [testType, cityId, SearchKeyword]);

  const resetFilter = () => {
    setCityName("Jaipur")
    setSearchKeyword("")
    setTestType("both")
    setCityId(1)
  };

  const headeCells = [
    { id: 'Id', label: 'ID', disableSorting: true },
    { id: 'NAME', label: 'NAME' , disableSorting: true},
    { id: 'cityname', label: 'City', disableSorting: true },
    { id: 'Rate', label: 'Rate', disableSorting: true },
    { id: 'TotalMRP', label: 'Total MRP' , disableSorting: true},
    { id: 'DiscountPercentage', label: 'Discount Percentage' , disableSorting: true},
    { id: 'TestType', label: 'Test Type' , disableSorting: true}
  ];

  const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
    packages,
    headeCells,
    filterFn,
    SearchKeyword
  );

  // const useStyles = makeStyles((theme) => ({
  //   pageContent: {
  //     margin: theme.spacing(5),
  //     padding: theme.spacing(3)
  //   },
  //   searchInput: {
  //     width: '15%'
  //   },
  //   newButton: {
  //     position: 'absolute',
  //     right: '10px'
  //   }
  // }));

  // const classes = useStyles();

  const style = {
    margin: 10
  };

  return (
    <Page title={'Packages List | Dr. B Lal'}>
      <Backdrop
        sx={{ color: '#07bc0c', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Typography variant="h4" gutterBottom>
              Test and Packages List
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/">
                Dashboard
              </Link>
              <Typography color="text.primary">All Packages </Typography>
            </Breadcrumbs>
          </Grid>
        </Stack>

        <Toolbar>
          
          <Controls.Input
            label="Search Packages"
            value={SearchKeyword}
            className={'searchInput'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            onChange={(e) => {
              setSearchKeyword(e.target.value);
            }}
          />

          <FormControl>
            <InputLabel id="demo-simple-select-label">Select City</InputLabel>
            <Select
              style={style}
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select City"
              value={cityId}
              error={''}
              required
              helperText={''}
              onClick={(e) => {
               if(e.target.dataset.city_id!=undefined){
                setCityId(e.target.dataset.city_id);
                setPanelId(e.target.dataset.panel_id);
                setCityName(e.target.dataset.city_name)
               }
              }}
            >
              {citiesData.length > 0 &&
                citiesData.map((e, key) => {
                  return (
                    <MenuItem
                      key={key}
                      value={e.CityId}
                      data-city_id={e.CityId}
                      data-city_name={e.CityName}
                      data-panel_id={e.Panel_ID}
                    >
                      {e.CityName}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        
          <FormControl style={{ marginLeft: '10px' }}>
            <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
            <Select
              style={style}
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select Type"
              value={testType}
              error={''}
              required
              helperText={''}
              onChange={(e) => {
                setTestType(e.target.value);
              }}
            >
              <MenuItem value="both">Tests & Packages</MenuItem>
              <MenuItem value="Test">Tests</MenuItem>
              <MenuItem value="Package">Packages</MenuItem>
            </Select>
          </FormControl>
          <Controls.Button
              text="Reset"
              variant="contained"
              color="error"
              onClick={resetFilter}
              className={'newButton'}
            />
               
        </Toolbar>
        <TblContainer>
          <TblHeader />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.Id}>
                <TableCell>{item.Id}</TableCell>
                <TableCell>{item.NAME}</TableCell>
                <TableCell>{cityName}</TableCell>
                <TableCell>{item.Rate}</TableCell>
                <TableCell>{item.TotalMRP}</TableCell>
                <TableCell>{item.DiscountPercentage}</TableCell>
                <TableCell>{item.TestType}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Container>
    </Page>
  );
}
